import React from 'react';
import config from 'react-global-configuration';


export default class OptionFive extends React.Component {


    getBackgroundColor = (levels, value) => {
      if (value === "N/A") {return config.get('statusInactive')}
      if ((value > (levels.n - levels.nl)) && (value < (levels.n + levels.nh))){return config.get('statusGreen')}
      else if ((value > (levels.n - levels.nl - levels.wl)) && (value < (levels.n + levels.nh + levels.wh))){return config.get('statusYellow')}
      else {return config.get('statusRed')}
      
    }

  render () { 

    return (
<svg width="960" height="600" viewBox="0 0 960 600">
  <defs>
    <clipPath id="clip-option-5">
      <rect width="960" height="600"/>
    </clipPath>
  </defs>
  <g id="option-5" clip-path="url(#clip-option-5)">
    <rect width="960" height="600" fill="none"/>
    <g id="Tank" transform="matrix(1, -0.017, 0.017, 1, 860.64, 226.052)">
      <g id="Rectangle_4" data-name="Rectangle 4" fill="#fff" stroke="#242323" stroke-width="1">
        <rect width="78" height="156.498" rx="20" stroke="none"/>
        <rect x="0.5" y="0.5" width="77" height="155.498" rx="19.5" fill="none"/>
      </g>
    </g>
    <g id="Group_9" data-name="Group 9" transform="translate(573.048 225.857)">
      <g id="sensor" transform="translate(308 27)">
        <g id="Ellipse_1" data-name="Ellipse 1" transform="translate(1.5 2)" fill="#fff" stroke="#242323" stroke-width="1">
          <circle cx="18.5" cy="18.5" r="18.5" stroke="none"/>
          <circle cx="18.5" cy="18.5" r="18" fill="none"/>
        </g>
        <line id="Line_1" data-name="Line 1" x2="39" transform="translate(0.5 20)" fill="none" stroke="#242323" stroke-width="1"/>
        <g id="Rectangle_1" data-name="Rectangle 1" fill="none" stroke="#242323" stroke-width="1">
          <rect width="40" height="40" stroke="none"/>
          <rect x="0.5" y="0.5" width="39" height="39" fill="none"/>
        </g>
      </g>
      <text id="LS" transform="translate(321 44)" fill="#242323" font-size="12" font-family="Roboto-Bold, Roboto" font-weight="700" letter-spacing="0.01em"><tspan x="0" y="0">LS</tspan></text>
      <text id="_3" data-name="3" transform="translate(325 60)" fill="#242323" font-size="12" font-family="Roboto-Bold, Roboto" font-weight="700" letter-spacing="0.01em"><tspan x="0" y="0">3</tspan></text>
    </g>
    <g id="Group_10" data-name="Group 10" transform="translate(573.048 240.857)">
      <g id="sensor-2" data-name="sensor" transform="translate(308 76)">
        <g id="Ellipse_1-2" data-name="Ellipse 1" transform="translate(1.5 2)" fill="#fff" stroke="#242323" stroke-width="1">
          <circle cx="18.5" cy="18.5" r="18.5" stroke="none"/>
          <circle cx="18.5" cy="18.5" r="18" fill="none"/>
        </g>
        <line id="Line_1-2" data-name="Line 1" x2="39" transform="translate(0.5 20)" fill="none" stroke="#242323" stroke-width="1"/>
        <g id="Rectangle_1-2" data-name="Rectangle 1" fill="none" stroke="#242323" stroke-width="1">
          <rect width="40" height="40" stroke="none"/>
          <rect x="0.5" y="0.5" width="39" height="39" fill="none"/>
        </g>
      </g>
      <text id="LS-2" data-name="LS" transform="translate(321 92)" fill="#242323" font-size="12" font-family="Roboto-Bold, Roboto" font-weight="700" letter-spacing="0.01em"><tspan x="0" y="0">LS</tspan></text>
      <text id="_4" data-name="4" transform="translate(325 108)" fill="#242323" font-size="12" font-family="Roboto-Bold, Roboto" font-weight="700" letter-spacing="0.01em"><tspan x="0" y="0">4</tspan></text>
    </g>
    <text id="WATER_TANK" data-name="WATER
TANK" transform="translate(901.048 366.857)" fill="#242323" font-size="10" font-family="Roboto-Bold, Roboto" font-weight="700" letter-spacing="0.01em"><tspan x="-16.636" y="0">WATER</tspan><tspan x="-13.019" y="11">TANK</tspan></text>
   <g id="Reading-label-21" data-name="Reading-label" text-anchor="middle" transform="translate(877 232)">
      <g id="LS3BG" fill={this.props.input.ls03.status}>
        <path d="M 43 17.5 L 5 17.5 C 3.765170097351074 17.5 2.610640048980713 17.17151069641113 1.749070048332214 16.57504081726074 C 0.9435999989509583 16.01741027832031 0.5 15.29413032531738 0.5 14.53845977783203 L 0.5 3.46153998374939 C 0.5 2.705869913101196 0.9435999989509583 1.982589960098267 1.749070048332214 1.424960017204285 C 2.610640048980713 0.8284900188446045 3.765170097351074 0.5 5 0.5 L 43 0.5 C 44.23482894897461 0.5 45.38936996459961 0.8284900188446045 46.25093078613281 1.424960017204285 C 47.05641174316406 1.982589960098267 47.5 2.705869913101196 47.5 3.46153998374939 L 47.5 14.53845977783203 C 47.5 15.29413032531738 47.05641174316406 16.01741027832031 46.25093078613281 16.57504081726074 C 45.38936996459961 17.17151069641113 44.23482894897461 17.5 43 17.5 Z" stroke="none"/>
        <path d="M 5 1 C 3.882320404052734 1 2.801139831542969 1.304729461669922 2.033668518066406 1.836050033569336 C 1.647628784179688 2.103309631347656 1 2.669879913330078 1 3.461540222167969 L 1 14.53845977783203 C 1 15.33012008666992 1.647628784179688 15.89669036865234 2.033668518066406 16.16394996643066 C 2.801139831542969 16.69527053833008 3.882320404052734 17 5 17 L 43 17 C 44.11766815185547 17 45.19886016845703 16.69527053833008 45.9663200378418 16.16394996643066 C 46.35237121582031 15.89669036865234 47 15.33012008666992 47 14.53845977783203 L 47 3.461540222167969 C 47 2.669879913330078 46.35237121582031 2.103309631347656 45.9663200378418 1.836050033569336 C 45.19886016845703 1.304729461669922 44.11766815185547 1 43 1 L 5 1 M 5 0 L 43 0 C 45.76142120361328 0 48 1.549779891967773 48 3.461540222167969 L 48 14.53845977783203 C 48 16.45022010803223 45.76142120361328 18 43 18 L 5 18 C 2.238571166992188 18 0 16.45022010803223 0 14.53845977783203 L 0 3.461540222167969 C 0 1.549779891967773 2.238571166992188 0 5 0 Z" stroke="none" fill="#d8d2d2"/>
      </g>
      <text id="LS3" transform="translate(22 14)" fill="#0a0909" font-size="14" font-family="Roboto-Bold, Roboto" font-weight="700" letter-spacing="0.01em"><tspan x="0" y="0">{this.props.input.ls03.value}</tspan></text>
    </g>
    <g id="Reading-label-22" data-name="Reading-label" text-anchor="middle" transform="translate(877 296)">
      <g id="LS4BG" fill={this.props.input.ls04.status}>
        <path d="M 43 17.5 L 5 17.5 C 3.765170097351074 17.5 2.610640048980713 17.17151069641113 1.749070048332214 16.57504081726074 C 0.9435999989509583 16.01741027832031 0.5 15.29413032531738 0.5 14.53845977783203 L 0.5 3.46153998374939 C 0.5 2.705869913101196 0.9435999989509583 1.982589960098267 1.749070048332214 1.424960017204285 C 2.610640048980713 0.8284900188446045 3.765170097351074 0.5 5 0.5 L 43 0.5 C 44.23482894897461 0.5 45.38936996459961 0.8284900188446045 46.25093078613281 1.424960017204285 C 47.05641174316406 1.982589960098267 47.5 2.705869913101196 47.5 3.46153998374939 L 47.5 14.53845977783203 C 47.5 15.29413032531738 47.05641174316406 16.01741027832031 46.25093078613281 16.57504081726074 C 45.38936996459961 17.17151069641113 44.23482894897461 17.5 43 17.5 Z" stroke="none"/>
        <path d="M 5 1 C 3.882320404052734 1 2.801139831542969 1.304729461669922 2.033668518066406 1.836050033569336 C 1.647628784179688 2.103309631347656 1 2.669879913330078 1 3.461540222167969 L 1 14.53845977783203 C 1 15.33012008666992 1.647628784179688 15.89669036865234 2.033668518066406 16.16394996643066 C 2.801139831542969 16.69527053833008 3.882320404052734 17 5 17 L 43 17 C 44.11766815185547 17 45.19886016845703 16.69527053833008 45.9663200378418 16.16394996643066 C 46.35237121582031 15.89669036865234 47 15.33012008666992 47 14.53845977783203 L 47 3.461540222167969 C 47 2.669879913330078 46.35237121582031 2.103309631347656 45.9663200378418 1.836050033569336 C 45.19886016845703 1.304729461669922 44.11766815185547 1 43 1 L 5 1 M 5 0 L 43 0 C 45.76142120361328 0 48 1.549779891967773 48 3.461540222167969 L 48 14.53845977783203 C 48 16.45022010803223 45.76142120361328 18 43 18 L 5 18 C 2.238571166992188 18 0 16.45022010803223 0 14.53845977783203 L 0 3.461540222167969 C 0 1.549779891967773 2.238571166992188 0 5 0 Z" stroke="none" fill="#d8d2d2"/>
      </g>
      <text id="LS4" transform="translate(22 14)" fill="#0a0909" font-size="14" font-family="Roboto-Bold, Roboto" font-weight="700" letter-spacing="0.01em"><tspan x="0" y="0">{this.props.input.ls04.value}</tspan></text>
    </g>
  </g>
</svg>


);
}
}