import React, { Component } from "react";
import { Switch, BrowserRouter as Router } from "react-router-dom";
import { connect } from "react-redux";
import { initFirebaseBackend } from "./helpers/authUtils";
import config from 'react-global-configuration';
import  store  from './store/index';
import { sensors } from './configuration/sensors'
import { actuators } from './configuration/actuators'
import { configuration } from './configuration/configuration'
import { commandIn } from './configuration/commandIn'

// Import Routes
import { authProtectedRoutes, publicRoutes } from "./routes/";
import AppRoute from "./routes/route";

// layouts
import VerticalLayout from "./components/VerticalLayout/";
import NonAuthLayout from "./components/NonAuthLayout";

// Import scss
import "./assets/scss/theme.scss";

config.set({
  //apiUrl: 'http://localhost:8084/api/v1/',
  apiUrl: 'http://nf.etnobird.com/api/v1/',
  //wsUrl: 'ws://localhost:3000',
  wsUrl: 'ws://nf.etnobird.com:3000',
  statusGreen: '#32cd32',
  statusYellow: '#faf110',
  statusRed: 'red',
  statusActive: '#707070',
  statusInactive: '#CCC8C8',
  textInactive: '#0a0909',
  textActive:'#fff'
})

// init firebase backend
initFirebaseBackend();


class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ws:null,
      timeout: 250
    };
    this.getLayout = this.getLayout.bind(this);
    this.initializeSensors()
    //this.initializeCommunication()
  }


  initializeSensors(){
    store.dispatch({type:"SENSORS",payload: sensors})
    store.dispatch({type:"ACTUATORS",payload: actuators})
    store.dispatch({type:"STORE_CONFIG",payload: configuration})
    store.dispatch({type: "COMMANDIN", payload: commandIn})
  }


  /**
   * Returns the layout
   */
  getLayout = () => {
    let layoutCls = VerticalLayout;

    return layoutCls;
  };


  render() {
    const Layout = this.getLayout();

    return (
      <React.Fragment>
        <Router>
          <Switch>
            {publicRoutes.map((route, idx) => (
              <AppRoute
                path={route.path}
                layout={NonAuthLayout}
                component={route.component}
                key={idx}
                isAuthProtected={false}
              />
            ))}

            {authProtectedRoutes.map((route, idx) => (
              <AppRoute
                path={route.path}
                layout={Layout}
                component={route.component}
                key={idx}
                isAuthProtected={true}
              />
            ))}
          </Switch>
        </Router>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    layout: state.Layout, 
    sensor: state.sensor
  };
};

export default connect(mapStateToProps, null)(App);
