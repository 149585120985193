import React from "react";
import { Redirect } from "react-router-dom";

// Pages Component

// Authentication related pages
import Login from "../pages/Authentication/Login";
import Logout from "../pages/Authentication/Logout";
import ForgetPwd from "../pages/Authentication/FogetPassword";

import Dashboard from "../pages/Dashboard";

import Device from "../pages/Device";
import GeneralSettings from "../pages/Device/settings/general";
import Pressure from "../pages/Device/settings/pressure";
import Conductivity from "../pages/Device/settings/conductivity";
import Valve from "../pages/Device/settings/valve";
import Needlevalve from "../pages/Device/settings/needlevalve";
import Flow from "../pages/Device/settings/flow";
import Level from "../pages/Device/settings/level";
import Online from "../pages/Device/settings/online";
import Devices from "../pages/Devices";
import Readings from "../pages/Device/readings/index"
import Maintenance from "../pages/Device/maintenance";
import MaintenanceJob from "../pages/Device/maintenance/MaintenanceJob";
import Errors from "../pages/Device/errors";
import Settings from "../pages/Device/settings/Settings";
import Users from "../pages/Users";
import User from "../pages/Users/User";
import HWDevice from "../pages/Devices/Device";

const authProtectedRoutes = [

  { path: "/controlpanel", component: Device },
  { path: "/settings/:id", component: Settings},
  { path: "/pressuresettings", component: Pressure},
  { path: "/conductivitysettings", component: Conductivity},
  { path: "/valvesettings", component: Valve},
  { path: "/needlevalvesettings", component: Needlevalve},
  { path: "/flowsettings", component: Flow},
  { path: "/levelsettings", component: Level},
  { path: "/onlinesettings", component: Online},
  { path: "/readings/:id", component: Readings },
  { path: "/maintenance/:id", component: MaintenanceJob},
  { path: "/maintenance", component: Maintenance},
  { path: "/newmaintenance", component: MaintenanceJob},
  { path: "/errors", component: Errors},

  { path: "/devices", component: Devices },
  { path: "/device/:id", component: HWDevice },
  { path: "/newdevice", component: HWDevice },
  { path: "/users", component: Users },
  { path: "/user/:id", component: User },
  { path: "/newuser", component: User },



  { path: "/dashboard", component: Dashboard },

  { path: "/", exact: true, component: () => <Redirect to="/dashboard" /> }
];

const publicRoutes = [
  { path: "/logout", component: Logout },
  { path: "/login", component: Login },
  { path: "/forget-password", component: ForgetPwd },

];

export { authProtectedRoutes, publicRoutes };
