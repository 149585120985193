import React, {useState, useEffect, memo} from 'react';
import { useForm, Controller } from 'react-hook-form'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'

export const Connection = memo(props => {
    const { handleSubmit, errors, control, setValue, getValues, watch } = useForm({mode:"onSubmit",reValidateMode:"onSubmit"})
  
    const onlineParameters = ['l-e','w-e','m-e']

    const updateParameter = (parameter, value) => {

        var configuration = JSON.parse(JSON.stringify(props.config))
        onlineParameters.forEach((myParameter) => {
            var myValue = 0
            if (myParameter === parameter){ 
                myValue = value
            }
            
            setValue(myParameter, myValue)
            
            var fieldToUpdate = myParameter.split('-')
                if (fieldToUpdate[1]) {
                    configuration[fieldToUpdate[0]][fieldToUpdate[1]] = myValue
                }
        })

        props.updateConfiguration("o", configuration)
        
    }

    useEffect(() => {
        [ 
            { name: 'l-e', value: props.config.l.e },
            { name: 'w-e', value: props.config.w.e },
            { name: 'm-e', value: props.config.m.e }
          ].forEach(({ name, value }) => setValue(name, value))
        
      }, [props.config]);
  
  
    return (
      <form>
          <Container>
              <Row>
                <Col md="9" className="parameter-text">{props.t('online.connection.lan')}</Col>
                <Col md="3">
                    <Controller 
                          render={({ onChange }) => (
                              <Form.Check 
                                  id="l-e"
                                  type="switch"
                                  checked={getValues("l-e") ? true: false}
                                  onChange={(value) => {
                                      updateParameter("l-e", value.target.checked ? 1 : 0)
                                  }}/>
                          )} 
                          name="l-e"
                          control={control}
                      />
                  </Col>
              </Row>
              <Row>
                <Col md="9" className="parameter-text">{props.t('online.connection.wifi')}</Col>
                <Col md="3">
                    <Controller 
                          render={({ onChange }) => (
                              <Form.Check 
                                  id="w-e"
                                  type="switch"
                                  checked={getValues("w-e") ? true: false}
                                  onChange={(value) => {
                                      updateParameter("w-e", value.target.checked ? 1 : 0)
                                  }}/>
                          )} 
                          name="w-e"
                          control={control}
                      />
                  </Col>
              </Row>
              <Row>
                <Col md="9" className="parameter-text">{props.t('online.connection.mobile')}</Col>
                <Col md="3">
                    <Controller 
                          render={({ onChange }) => (
                              <Form.Check 
                                  id="m-e"
                                  type="switch"
                                  checked={getValues("m-e") ? true: false}
                                  onChange={(value) => {
                                      updateParameter("m-e", value.target.checked ? 1 : 0)
                                  }}/>
                          )} 
                          name="m-e"
                          control={control}
                      />
                  </Col>
              </Row>
              
              
            </Container>
  
  
      </form>
    )
  })