import React, {useState, memo} from 'react';
import Container from 'react-bootstrap/Container'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Card  from 'react-bootstrap/Card'
import { Link } from 'react-router-dom';
import Tabs from 'react-bootstrap/Tabs'
import Tab from 'react-bootstrap/Tab'
import SettingsFooter from '../../../../components/Footer';
import  store  from '../../../../store/index'
import { connect } from 'react-redux'
import {withTranslation} from "react-i18next";
import { Parameters } from './Parameters';




class Level extends React.Component {

    constructor(props){
        super(props)

        this.state = {
          showKeyboard: false,
          keyboardField: "",
          keyboardLabel: "",
          keyboardValue: "",
          forceUpdate: false
        }

    }

    keyboardCallback = (field, label, value) => {
       this.setState({
         showKeyboard: true,
         keyboardField: field,
         keyboardLabel: label, 
         keyboardValue: value
       })
    }

    updateConfig = (element, configuration) => {
      var newConfig = this.props.editConfig
      newConfig[element] = configuration
      store.dispatch({type:"EDIT_CONFIG",payload: newConfig})
      this.setState({forceUpdate: !this.state.forceUpdate})
    }

    hideKeyboard = () => {
      this.setState({
        showKeyboard: false
      })
    }

    enterKeyboardValue = (field, value) => {
      console.log("entervalue")
      console.log(field)
      console.log(value)
      this.setState({
        keyboardField: field,
        keyboardValue: value,
        showKeyboard: false
      })
    }

    render() {

        const { t } = this.props;

        return <React.Fragment><Container fluid> 
        <Col>
        <Row className="align-items-center">
        <Col sm={6}>
          <div className="page-title-box">
            <h4 className="font-size-18">Level sensor settings</h4>
            <ol className="breadcrumb mb-0">
              <li className="breadcrumb-item">
                <Link to="/#">Dashboard</Link>
              </li>
              <li className="breadcrumb-item active">Level sensor settings</li>
            </ol>
          </div>
        </Col>
      </Row>
      <Row>
       <Col>
        <Card>
          <Card.Body>
            <Tabs defaultActiveKey="level" id="level">
              <Tab eventKey="level" title={t('level.tabs.level')}>
                <Parameters t={t} showKeyboard={this.keyboardCallback} keyboardValue={this.state.keyboardValue} keyboardField={this.state.keyboardField} config={this.props.editConfig.l} generalConfig={this.props.config.g} updateConfiguration={this.updateConfig} forceUpdate={this.state.update}/>
              </Tab>
            </Tabs>
            <SettingsFooter />
            </Card.Body>
            </Card>
            </Col>
            </Row>
            

            </Col>
        </Container>
        </React.Fragment>
        
    }
}

function mapStateToProps(state){
    return {
      sensors: state.Device.sensors,
      actuators: state.Device.actuators,
      errors: state.Device.errors,
      config: state.Device.config,
      editConfig: state.Device.config
    }
  }
  
  export default withTranslation('common')(connect(
    mapStateToProps
  )(Level))
