import React, {useState, useEffect} from 'react';
import { useForm, Controller } from 'react-hook-form'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'


export const Parameters = (props) => {
    const { handleSubmit, errors, control, setValue, getValues } = useForm({mode:"onSubmit",reValidateMode:"onSubmit"})

    var levelSensors = [
      {title: 'ls3 / ls4',
      key: 'l3l4'
      }]

    if (props.generalConfig.o1) {
        levelSensors = [{title: 'ls1 / ls2',
        key: 'l1l2'
        },...levelSensors
        ]
    }


    const onSubmit = () => {

    }

    useEffect(() => {
      [ 
          { name: 'l1l2-nc', value: props.config.l1l2.n === 1 ? 'nc' : '0' },
          { name: 'l1l2-no', value: props.config.l1l2.n === 0 ? 'no' : '0' },
          { name: 'l3l4-nc', value: props.config.l3l4.n === 1 ? 'nc' : '0' },
          { name: 'l3l4-no', value: props.config.l3l4.n === 0 ? 'no' : '0' },
       ].forEach(({ name, value }) => setValue(name, value))
  })

    const handleChange = (e, sensor) => {

        if (e.target.value === 'nc'){
            setValue(`${sensor}-nc`,'nc')
            setValue(`${sensor}-no`,0)
        }
        else {
            setValue(`${sensor}-nc`,0)
            setValue(`${sensor}-no`,'no')
        }
        
        var configuration = JSON.parse(JSON.stringify(props.config))
        configuration[sensor].n = e.target.value === 'nc' ? 1 : 0
        props.updateConfiguration("l", configuration)
        
      };


    return (
      <form onSubmit={handleSubmit(onSubmit)}>
          <Container>
              <Row>
                  <Col md="4"></Col>
                  <Col md="3" className="column-title">{props.t('level.level.nc')}</Col>
                  <Col md="3" className="column-title">{props.t('level.level.no')}</Col>
              </Row>
             
          { levelSensors.map((sensor, index) => 
              <Row key={index}>
                
                <Col md="4" className="parameter-text">{sensor.title.toUpperCase()}</Col>
                <Col md="3" className="digit-small">
                <Controller 
                          render={({ onChange }) => (
                            <Form.Check 
                                value="nc"
                                type="radio"
                                title=""
                                onChange={(e) => handleChange(e, sensor.key)}
                                checked={getValues(`${sensor.key}-nc`) === 'nc'}
                            />
                          )} 
                          name={`${sensor.key}-nc`}
                          control={control}
                      />
                    
                </Col>
                <Col md="3" className="digit-small">
                <Controller 
                          render={({ onChange }) => (
                            <Form.Check 
                                value="no"
                                type="radio"
                                title=""
                                onChange={(e) => handleChange(e, sensor.key)}
                                checked={getValues(`${sensor.key}-no`) === 'no'}
                            />
                          )} 
                          name={`${sensor.key}-no`}
                          control={control}
                      />
                  </Col>
              </Row>
          )}
              
            </Container>
           
      </form>
      
    )
  }