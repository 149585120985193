import React, { Component } from "react";
import { Row, Col, Button, FormGroup, Alert } from "reactstrap";
import { Link } from "react-router-dom";
import config from 'react-global-configuration';
import { AvForm, AvField } from "availity-reactstrap-validation";
import moment from 'moment'
import Back from "../../components/Back";


class User extends Component {
  constructor(props) {
    super(props);
    
    var authUser = JSON.parse(sessionStorage.getItem("authUser"))

    this.state = {
        authUser: authUser,
        user: '',
        userUuid: this.props.match.params.id,
        userCreated: false, 
        savingSuccess: false
    };

    if (this.props.match.params.id){
      this.getUser()
  }

    
  }

  createUser = (event, values) => {
    var userRequest = {
        firstName: values.firstName,
        surname: values.surname,
        email: values.email,
        password: values.password,
        type: values.type
    }
    fetch(config.get('apiUrl')+'admin/user',{
        method: 'POST',
        headers: {
            'Content-Type' : 'application/json',
            Authorization: 'Bearer '+ this.state.authUser.token
        },
        body: JSON.stringify({user:userRequest})
    })
    .then(res=>res.json())
    .then((json)=> {
        this.setState({
            userCreated: true,
            savingSuccess: true,
            user: json.user
        })
    })
  }

  editUser = (event, values) => {
    this.setState({
      savingSuccess: false
    })
    var userRequest = {
      firstName: values.firstName,
      surname: values.surname,
      email: values.email,
      password: values.password,
      type: values.type,
      id: this.state.user.id
  }
  fetch(config.get('apiUrl')+'admin/user/'+this.state.userUuid,{
      method: 'PATCH',
      headers: {
          'Content-Type' : 'application/json',
          Authorization: 'Bearer '+ this.state.authUser.token
      },
      body: JSON.stringify(userRequest)
  })
  .then(res=>res.json())
  .then((json)=> {
      this.setState({
          userCreated: true,
          savingSuccess: true
      })
  })

  }

  getUser = () => {
    fetch(config.get('apiUrl')+'admin/user/'+this.state.userUuid,{
      headers: {
          Authorization: 'Bearer '+ this.state.authUser.token
      }
  })
      .then(response => response.json())
      .then(json => {


          this.setState({
              user: json
            })
      })
  }



  render() {

    var defaultValue = 0

    var validatePassword = {
      required: { value: true }
    }

    var validatePasswordConfirm = {
      required: { value: true },
      match: { value: "password" }
    }

    if (this.props.match.params.id && this.state.user === '') {
      return ''
    }

    if (this.props.match.params.id && this.state.user) {
      defaultValue = this.state.user.type
      var validatePassword = {
        
      }
  
      var validatePasswordConfirm = {
        match: { value: "password" }
      }

    }

    return (
      <React.Fragment>
        <div className="container-fluid">
          <Row className="align-items-center">
            <Col sm={6}>
              <div className="page-title-box">
                <h4 className="font-size-18">User</h4>
                <ol className="breadcrumb mb-0">
                  <li className="breadcrumb-item">
                    <Link to="/#">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item active">User</li>
                </ol>
              </div>
            </Col>

          </Row>

          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-body">
                    <AvForm onValidSubmit={this.state.user.id ? this.editUser : this.createUser}>
                    {this.state.savingSuccess ? 
                    <Alert color="success" >
                      User saved
                    </Alert> : '' }
                    <AvField
                      name="firstName"
                      label="First name "
                      placeholder="Enter first name"
                      type="text"
                      value={this.state.user.firstName}
                      errorMessage="First name is mandatory"
                      validate={{
                        required: { value: true },
                        pattern: {
                          value: "^[a-zA-Z]+$",
                          errorMessage: "First name can only contain characters"
                        }
                      }}
                    />
                    <AvField
                      name="surname"
                      label="Surname"
                      placeholder="Enter surname"
                      type="text"
                      value={this.state.user.surname}
                      errorMessage="Surname is mandatory"
                      validate={{
                        required: { value: true },
                        pattern: {
                          value: "^[a-zA-Z]+$",
                          errorMessage: "Surname can only contain characters"
                        }
                      }}
                    />
                    <AvField
                      name="email"
                      label="Email address"
                      placeholder="Enter email address"
                      type="email"
                      value={this.state.user.email}
                      errorMessage="Invalid email address"
                      validate={{
                        required: { value: true },
                        email: { value: true }
                      }}
                    />
                    <AvField
                      name="password"
                      label="Password"
                      type="text"
                      placeholder="Enter password"
                      errorMessage="Enter password"
                      validate={validatePassword}
                    />
                    <AvField
                      name="password1"
                      label="Confirm password"
                      type="text"
                      placeholder="Confirm password"
                      errorMessage="Passwords do not match"
                      validate={validatePasswordConfirm}
                    />
                    <AvField type="select" name="type" label="Type" defaultValue={defaultValue}>
                        <option value="0">Select type</option>
                        <option value="1">Regular</option>
                        <option value="2">Admin</option>
                    </AvField>
                    <FormGroup className="mb-0">
                      <div>
                        <Button type="submit" color="primary" className="mr-1">
                          {this.state.user.id ? 'Save' : 'Add'}
                        </Button>{" "}
                        <Back/>
                      </div>
                    </FormGroup>
                    </AvForm>
                    
                </div>
              </div>
            </div>
          </div>

        </div>
      </React.Fragment>
    );
  }
}

export default User;

