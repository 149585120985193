import { STORE_CONFIG } from './actions'
import { EDIT_CONFIG } from './actions'
import { SENSORS } from './actions'
import { ACTUATORS } from './actions'
import { COMMANDIN} from './actions'
import { DEVICES } from './actions'
import { SELECTED_DEVICE } from './actions'
import { DEVICE_SENSORS } from './actions'
import { LAST_UPDATE } from './actions'

const initalState = {
    config: '',
    editConfig: '',
    sensors: '',
    actuators: '',
    commandIn: '',
    devices: '',
    selectedDevice: '',
    deviceSensors: '',
    lastUpdate: ''
}

function device(state = initalState, action) {
    if (action.type === STORE_CONFIG){
        state.config = action.payload
    }
    if (action.type === EDIT_CONFIG){
        state.editConfig = action.payload
    }
    if (action.type === SENSORS){
        state.sensors = action.payload
    }
    if (action.type === ACTUATORS){
        state.actuators = action.payload
    }
    if (action.type === COMMANDIN){
        state.commandIn = action.payload
    }
    if (action.type === DEVICES){
        state.devices = action.payload
    }
    if (action.type === SELECTED_DEVICE){
        state.selectedDevice = action.payload
    }
    if (action.type === DEVICE_SENSORS){
        state.deviceSensors = action.payload
    }
    if (action.type === LAST_UPDATE){
        state.lastUpdate = action.payload
    }
    return {...state}
}

export default device