import React from 'react';
import Container from 'react-bootstrap/Container'
import { Row, Col, Button } from 'reactstrap';
import { connect } from 'react-redux'
import config from 'react-global-configuration';
import { Link } from 'react-router-dom';
import Select from 'react-select';
import {withTranslation} from "react-i18next";
import LineChart from '../../../components/LineChart';
//import { Chart, Line } from 'react-chartjs-2'
import Hammer from 'hammerjs';
import zoomPlugin from "chartjs-plugin-zoom";
import 'chartjs-adapter-moment';
import _ from 'lodash'


class Readings extends React.Component {

    chartRef = React.createRef();

    constructor(props){
        super(props)

        let sensorName = '';
        let sensorUnit = '';
        let sensorFilter = 0;
        switch (this.props.match.params.id) {
          case "1":
            sensorName = 'Pressure';
            sensorUnit = 'bar';
            sensorFilter = 2;
            break;
          case "2":
            sensorName = 'Conductivity';
            sensorUnit = 'uS';
            sensorFilter = 2;
            break;
          case "3":
            sensorName = 'Level';
            sensorUnit = 'on/off';
            sensorFilter = 1;
            break;
          case "4":
            sensorName = 'Flow';
            sensorUnit = 'l';
            sensorFilter = 0;
            break;
          default:
            break;
        }

        this.state = {
          forceUpdate: false,
          selectedSensor: '',
          availableSensors : [],
          measurementData: [],
          options: {},
          minDomain: 0,
          maxDomain: 1,
          sensorName: sensorName,
          sensorUnit: sensorUnit,
          sensorFilter: sensorFilter,
          loader: false,
          errorMessage: 'Please select sensor',
          entireDomain : '',
          timeRanges: [
            {
              label: '1 hour',
              value: 1
            },
            {
              label: '1 day',
              value: 24
            },
            {
              label: '1 week',
              value: 168
            }

          ],
          selectedTimeRange:  {
            label: '1 hour',
            value: 1
          }
        }
       

    }

    componentDidMount = () => {
      //Chart.register(zoomPlugin)
      this.fetchSensors();
    }

    handleZoomReset = () => {
      console.log("terea")
      this.lineReference.chartInstance.resetZoom()
  }


    static getDerivedStateFromProps(nextProps, prevState){
      if(nextProps.deviceSensors !== prevState.deviceSensors){
        return { deviceSensors: nextProps.deviceSensors};
     }
   }

   
  orderKeys = (obj, expected) => {

    var keys = Object.keys(obj).sort(function keyOrder(k1, k2) {
        if (k1 < k2) return -1;
        else if (k1 > k2) return +1;
        else return 0;
    });
  
    var i, after = {};
    for (i = 0; i < keys.length; i++) {
      after[keys[i]] = obj[keys[i]];
      delete obj[keys[i]];
    }
  
    for (i = 0; i < keys.length; i++) {
      obj[keys[i]] = after[keys[i]];
    }
    return obj;
  }

  fetchSensors = () => {
    let selectedSensors = [];
    const orderedSensors = this.orderKeys(this.props.deviceSensors);
    Object.keys(orderedSensors).forEach((key) => {
      if (this.props.deviceSensors[key].idperipheraltype === parseInt(this.props.match.params.id)){
        selectedSensors.push({
          value: this.props.deviceSensors[key].id,
          label: key.toUpperCase()
        })
      }
    });
   this.setState({
     availableSensors: selectedSensors
   })
    
  }

   componentDidUpdate(prevProps, prevState) {

      if(prevProps.deviceSensors!==this.props.deviceSensors){
        this.fetchSensors();
      }

      if (prevProps.match.params.id !== this.props.match.params.id){
        let sensorName = '';
        let sensorUnit = '';
        let sensorFilter = 0
        switch (this.props.match.params.id) {
          case "1":
            sensorName = 'Pressure';
            sensorUnit = 'bar';
            sensorFilter = 2;
            break;
          case "2":
            sensorName = 'Conductivity';
            sensorUnit = 'uS';
            sensorFilter = 2;
            break;
          case "3":
            sensorName = 'Level';
            sensorUnit = 'on/off';
            sensorFilter = 1;
            break;
          case "4":
            sensorName = 'Flow';
            sensorUnit = 'l';
            sensorFilter = 0;
            break;
          default:
            break;
        }

        this.setState({
          sensorName: sensorName,
          sensorUnit: sensorUnit,
          sensorFilter: sensorFilter,
          measurementData: {},
          selectedSensor: ''
        });
        this.fetchSensors();
      }
    }

    handleSensorChange = (sensor) => {
      this.setState({
        selectedSensor: sensor
      })
      this.getMeasurements(sensor.value, this.state.selectedTimeRange.value);
  
    }

    handleTimeRangeChange = (timeRange) => {
      this.setState({
        selectedTimeRange: timeRange
      })
      this.getMeasurements(this.state.selectedSensor.value, timeRange.value);
  
    }

    getData = () => {
      return this.state.data.filter(
        // is d "between" the ends of the visible x-domain?
        (d) => (d.x >= this.state.zoomDomain[0] && d.x <= this.state.zoomDomain[1]));
    }

    getEntireDomain = (data) => {
      return {
        y: [_.minBy(data, d => d.b).b*0.95, _.maxBy(data, d => d.b).b*1.05],
        x: [ data[0].a, _.last(data).a ]
      };
    }


    getMeasurements = (sensor, hours) => {
      this.setState({
        loader: true
      })
      fetch(config.get('apiUrl')+'admin/sensor/'+sensor+'/'+hours+'/'+this.state.sensorFilter,{
        headers: {
          Authorization: 'Bearer '+ JSON.parse(sessionStorage.getItem('authUser')).token
        }
      })
        .then(res=>res.json())
        .then((data)=> {
          if (Object.keys(data).length) {
            let theData = [];
            let theLabels = [];
            let lastTimestamp = 0;
            let minimumValue = "";
            let maximumValue = "";
            Object.keys(data).forEach((key) => {
              if (!lastTimestamp) { 
                lastTimestamp = data[key].t; 
                minimumValue = data[key].v1/100;
                maximumValue = data[key].v1/100;
              }

                lastTimestamp = data[key].t
                if (data[key].v1/100 < minimumValue){ minimumValue = data[key].v1/100}
                if (data[key].v1/100 > maximumValue){ maximumValue = data[key].v1/100}
                // if (keyCounter && lastValue === data[key].v1 && keyCounter !== totalCounter-1){
                //   previousValue = data[key].v1 / 100;
                //   previousTimestamp =data[key].t;
                // }
                // else {
                //   if (previousTimestamp !== "") {
                //     theData.push(previousValue)
                //     theLabels.push(previousTimestamp)
                //     previousTimestamp = "";
                //   }
                  theData.push({x: data[key].t, y: data[key].v1 === null ? null : this.props.match.params.id === '3' ? data[key].v1  : data[key].v1 / 100})
                  theLabels.push(data[key].t)
                  //}

            });
            
            minimumValue = minimumValue * 3;
            maximumValue = maximumValue * 3;

            console.log(this.chartRef)
            

            this.setState({
              //entireDomain: theData.length > 0 ? this.getEntireDomain(theData) : '',
              measurementData: theData,
              minDomain: minimumValue,
              maxDomain: maximumValue,
              loader: false
            })
        }
        else {
          this.setState({
            errorMessage: 'No measurement data for selected time period!',
            loader: false,
            measurementData: {}
          })
        }
        });
    } 

    render() {
        const { t } = this.props;
        let yAxes = {};
        let decimation = {
          enabled: true,
            algorithm: 'lttb',
            samples: 5000
        };
        if (this.props.match.params.id === '3'){
          yAxes = {
            type: 'category',
            labels: ['on','off']
          }
        }
        if (this.state.selectedTimeRange.value > 1) {
          decimation = {
            enabled: true,
            algorithm: 'lttb',
            samples: 500
          }
        }

        let mutableData = {};
        mutableData = Object.create(this.state.measurementData)


        const options = {
          animation: false,
          parsing: false,
          scales: {
              x: {
                type: 'time',
                lineTension: 0,
                distribution: 'linear',
                time: {
                  unit: this.state.selectedTimeRange.value === 168 ? 'hour' : 'minute',
                  displayFormats:{
                    'millisecond': this.state.selectedTimeRange.value === 168 ? 'DD-MM HH:mm' : 'HH:mm', 
                    'second': this.state.selectedTimeRange.value === 168 ? 'DD-MM HH:mm' : 'HH:mm',  
                    'minute': this.state.selectedTimeRange.value === 168 ? 'DD-MM HH:mm' : 'HH:mm',  
                    'hour': this.state.selectedTimeRange.value === 168 ? 'DD-MM HH:mm' : 'HH:mm',  
                    'day': this.state.selectedTimeRange.value === 168 ? 'DD-MM HH:mm' : 'HH:mm',  
                    'week': this.state.selectedTimeRange.value === 168 ? 'DD-MM HH:mm' : 'HH:mm', 
                    'month': this.state.selectedTimeRange.value === 168 ? 'DD-MM HH:mm' : 'HH:mm',  
                    'quarter': this.state.selectedTimeRange.value === 168 ? 'DD-MM HH:mm' : 'HH:mm', 
                    'year': this.state.selectedTimeRange.value === 168 ? 'DD-MM HH:mm' : 'HH:mm', 
                  },
                  tooltipFormat: 'DD.MM.YYYY HH:mm:ss'
                },
              },
            y: yAxes

          },
          plugins: {
              // zoom: {
              //     zoom: {
              //           drag: {
              //             enabled: true
              //           },
              //           mode: 'x'
              //         },
                  
              // }, 
              decimation: decimation
          }

        }

        return  <React.Fragment>
        <div className="container-fluid">
          <Row className="align-items-center">
            <Col sm={6}>
              <div className="page-title-box">
                <h4 className="font-size-18">{this.state.sensorName} readings</h4>
                <ol className="breadcrumb mb-0">
                  <li className="breadcrumb-item">
                    <Link to="/#">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item active">{this.state.sensorName} readings</li>
                </ol>
              </div>
            </Col>

          </Row>

          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-body">
                  <div className="row">
                    <div className="col-4">
                    Sensor: 
                    <Select
                        value={this.state.selectedSensor}
                        isClearable={false}
                        placeholder="Select sensor"
                        onChange={this.handleSensorChange}
                        options={this.state.availableSensors}
                      />
                    </div>
                    <div className="col-4">
                    Time range:
                    <Select
                        value={this.state.selectedTimeRange}
                        isClearable={false}
                        onChange={this.handleTimeRangeChange}
                        options={this.state.timeRanges}
                      />
                    </div>
                    <div className="col-4">
                      <br/>
                    <Button onClick={(evt) => {this.handleZoomReset()}} color="primary" type="button" variant="outlined">
                            Reset Zoom
                        </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>


          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-body">
                
                 
                <Container className="page-container"> 
                {this.state.measurementData.length > 0 ? 
                  <Col className="content">
                    {this.state.loader === false ? 
                  <LineChart 
                      sensorName={this.state.sensorName} 
                      sensorUnit={this.state.sensorUnit} 
                      selectedTimeRange={this.state.selectedTimeRange}
                      measurementData={this.state.measurementData}/>
                    : <div className="spinner-border text-primary m-1" role="status">
              <span className="sr-only">Loading...</span>
             </div>
    }
      {/* 
                    <Line 
                      data={this.state.measurementData} 
                      options={options} 
                      ref={(reference) => this.lineReference = reference}/> */}

          
                  </Col> : this.state.errorMessage }
        </Container> 
            
                </div>
              </div>
            </div>
          </div>

        </div>
      </React.Fragment>
        
       
        
    }
}

function mapStateToProps(state){
    return {
      sensors: state.Device.sensors,
      actuators: state.Device.actuators,
      errors: state.Device.errors,
      config: state.Device.config,
      editConfig: state.Device.config,
      deviceSensors: state.Device.deviceSensors
    }
  }
  
  export default withTranslation('common')(connect(
    mapStateToProps
  )(Readings))
