export const configuration = {
    g: {
        o1:0,
        o2:0,
        o3:0,
        o4:0,
        p5s:0,
        l3s:0,
        mtd:0,
        ctd:0,
        dtd:0,
        tl:0,
        pin: "0000",
        m: "2",
        s: "N/A",
        t: 0,
        rp: 0,
        tz: 'Europe/Helsinki'
    },
    p: {
        p1: {
            n:"N/A",
            nl:"N/A",
            nh:"N/A",
            wl:"N/A",
            wh:"N/A",
            i2c:"N/A"
        },
        p2: {
            n:"N/A",
            nl:"N/A",
            nh:"N/A",
            wl:"N/A",
            wh:"N/A",
            i2c:"N/A"
        },
        p3: {
            n:"N/A",
            nl:"N/A",
            nh:"N/A",
            wl:"N/A",
            wh:"N/A",
            i2c:"N/A"
        },
        p4: {
            n:"N/A",
            nl:"N/A",
            nh:"N/A",
            wl:"N/A",
            wh:"N/A",
            i2c:"N/A"
        },
        p5: {
            s:"N/A",
            f:"N/A",
            i2c:"N/A"
        },
        ar:"N/A",
        md:"N/A",
    },
    c: {
        c1: {
            n:"N/A",
            nl:"N/A",
            nh:"N/A",
            wl:"N/A",
            wh:"N/A",
            k:"N/A",
            tc:0
        },
     c2: {
            n:"N/A",
            nl:"N/A",
            nh:"N/A",
            wl:"N/A",
            wh:"N/A",
            k:"N/A",
            tc:0
        },
        c3: {
            n:"N/A",
            nl:"N/A",
            nh:"N/A",
            wl:"N/A",
            wh:"N/A",
            k:"N/A",
            tc:0
        },
        ar:"N/A",
        md:"N/A",
    },
    x: {
        x2r: 0,
        x2rt: "N/A",
        x3m: 0,
        dp: 0,
        vno: "N/A"
    },
    r: {
        r1: {
            d: "N/A",
            m: "N/A",
            c: "N/A"
        },
        r2: {
            d: "N/A",
            m: "N/A",
            c: "N/A"
        },
        r3: {
            d: "N/A",
            m: "N/A",
            c: "N/A"
        },
    },
    f: {
        f1: {
            q: "N/A"
        },
        f2: {
            q: "N/A"
        },
        f3: {
            q: "N/A"
        },
        f4: {
            q: "N/A"
        },
    },
    l: {
        l1l2: {
            n: 0
        },
        l3l4: {
            n: 0
        },
    },
    o: {
        l: {
            e: 0
        },
        w: {
            e: 0,
            wn: "N/A",
            wp: "N/A",
            ws: "N/A"
        },
        m: {
            e: 0
        }
    }
}