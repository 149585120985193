import  store from '../store/index'
import moment from 'moment'

export function updateConfiguration(element1, element2, configuration, editConfig){
    var newConfig = JSON.parse(JSON.stringify(editConfig))
    if (element2 !== null){
        newConfig[element1][element2] = configuration
    }
    else {
        newConfig[element1] = configuration
    }
    
    store.dispatch({type:"STORE_CONFIG",payload: newConfig})
}

export function updateParameter(editConfig, root, parameter, value) {
    var configuration = JSON.parse(JSON.stringify(editConfig[root]))
    var fieldToUpdate = parameter.split('-')
    if (fieldToUpdate[1]){
        configuration[fieldToUpdate[0]][fieldToUpdate[1]] = parseFloat(value)
    }
    else {
        configuration[parameter]= parseFloat(value)
    }
    updateConfiguration(root, null, configuration, editConfig)
}

export function updateParameters(editConfig, root, parameters) {
        
    var configuration = JSON.parse(JSON.stringify(editConfig[root]))
    parameters.forEach((parameter) => {
        Object.keys(parameter).forEach(key => {
            if(key === "t") {
              configuration[key] = moment(parameter[key],"DD.MM.YYYY HH:mm").format("x")
            }
            else {
                configuration[key]= parseFloat(parameter[key])
            }
            
        })
    })
    
    updateConfiguration(root, null, configuration, editConfig)
}

export function updateDeepParameters(editConfig, root1, root2, parameters) {
        
    var configuration = JSON.parse(JSON.stringify(editConfig[root1][root2]))
    parameters.forEach((parameter) => {
        Object.keys(parameter).forEach(key => {
            configuration[key]= parameter[key]
        })
    })    
    updateConfiguration(root1, root2, configuration, editConfig)
}