import React, { useEffect} from 'react';
import { useTranslation } from "react-i18next";
import { useSelector } from 'react-redux';
import { useForm, Controller } from 'react-hook-form'
import { updateConfiguration, updateParameters, updateParameter } from '../../../../helpers/utils'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'


export const DefaultPosition = (props) => {
    const { t } = useTranslation('common')
    const editConfig = useSelector(state => state.Device.config)
    const { handleSubmit, control, setValue, getValues } = useForm({mode:"onSubmit",reValidateMode:"onSubmit"})


    var needleValves = [
      {title: 'rv2',
      key: 'r2'
      },
      {title: 'rv3',
      key: 'r3'
      }]

    if (editConfig.g.o3) {
        needleValves = [{
          title: 'rv1',
          key: 'r1'
          },...needleValves
        ]
    }

    const onSubmit = () => {

    }

     
    useEffect(() => {
        [ 
            { name: 'r1-d', value: editConfig.r.r1.d },
            { name: 'r2-d', value: editConfig.r.r2.d },
            { name: 'r3-d', value: editConfig.r.r3.d }
         ].forEach(({ name, value }) => setValue(name, value))
    }, [editConfig]);


    return (
      <form onSubmit={handleSubmit(onSubmit)}>
          <Container>
              <Row>
                  <Col md="2"></Col>
                  <Col md="3" className="column-title">{t('needle_valve.default_position.default_position')}<br/><span className="unit-text">({t('needle_valve.default_position.degree')})</span></Col>
              </Row>
             
          { needleValves.map((sensor, index) => 
              <Row key={index}>
                <Col md="2" className="parameter-text">{sensor.title.toUpperCase()}</Col>
                <Col md="3" className="text-center"><Controller 
                          render={({ onChange }) => (
                            <Form.Control 
                                as="input"
                                className="input-digit-big"
                                id={`${sensor.key}-d`} 
                                onChange={(e) => updateParameter(editConfig, "r",`${sensor.key}-d`, e.target.value)}
                                value={getValues(`${sensor.key}-d`)}
                                />
                          )} 
                          name={`${sensor.key}-d`}
                          control={control}
                      />
                </Col>
              
              </Row>
          )}
              
            </Container>
           
      </form>
      
    )
  }