import React, { useEffect} from 'react';
import { useTranslation } from "react-i18next";
import { useSelector } from 'react-redux';
import { updateConfiguration, updateParameters, updateParameter } from '../../../../helpers/utils'
import { useForm, Controller } from 'react-hook-form'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'


export const ConductivitySensorRanges = (props) => {
    const { t } = useTranslation('common')
    const editConfig = useSelector(state => state.Device.config)
    const sensors = useSelector(state => state.Device.sensors)
    const { handleSubmit,  control, setValue, getValues } = useForm({mode:"onSubmit",reValidateMode:"onSubmit"})


    var conductivitySensors = [{
        title: 'ect1',
        sensor: 'ect01',
        key: 'c1'
        }
        ]

    if (editConfig.g.o2) {
        conductivitySensors = [...conductivitySensors, {title: 'ect2',
        sensor: 'ect02',
        key: 'c2'
        }]
    }

    if (editConfig.g.o4) {
        conductivitySensors = [...conductivitySensors, {title: 'ect3',
        sensor: 'ect03',
        key: 'c3'
        }]
    }
    const onSubmit = () => {

    }
     
    useEffect(() => {
        [ 
            { name: 'c1-n', value: editConfig.c.c1.n },
            { name: 'c1-nl', value: editConfig.c.c1.nl },
            { name: 'c1-nh', value: editConfig.c.c1.nh },
            { name: 'c1-wl', value: editConfig.c.c1.wl },
            { name: 'c1-wh', value: editConfig.c.c1.wh },
            { name: 'c2-n', value: editConfig.c.c2.n },
            { name: 'c2-nl', value: editConfig.c.c2.nl },
            { name: 'c2-nh', value: editConfig.c.c2.nh },
            { name: 'c2-wl', value: editConfig.c.c2.wl },
            { name: 'c2-wh', value: editConfig.c.c2.wh },
            { name: 'c3-n', value: editConfig.c.c3.n },
            { name: 'c3-nl', value: editConfig.c.c3.nl },
            { name: 'c3-nh', value: editConfig.c.c3.nh },
            { name: 'c3-wl', value: editConfig.c.c3.wl },
            { name: 'c3-wh', value: editConfig.c.c3.wh },
         ].forEach(({ name, value }) => setValue(name, value))
        
      }, [editConfig]);
     
  
    return (
      <form onSubmit={handleSubmit(onSubmit)}>
          <Container className="sensor-table">
              <Row>
                  <Col md="3"></Col>
                  <Col md="4" className="column-title">{t('conductivity.conductivity_ranges.conductivity')}<br/><span className="unit-text">({t('conductivity.conductivity_ranges.us')})</span></Col>
                  <Col md="4" className="column-title">{t('conductivity.conductivity_ranges.tolerances')}<br/><span className="unit-text">({t('conductivity.conductivity_ranges.us')})</span></Col>
              </Row>
              <Row>
                  <Col md="7"></Col>
                  <Col md="2" className="column-sub-title digit-small">{t('conductivity.conductivity_ranges.normal')}</Col>
                  <Col md="2" className="column-sub-title digit-small">{t('conductivity.conductivity_ranges.warning')}</Col>
              </Row>
              <Row>
                  <Col md="7"></Col>
                  <Col className="unit-text digit-small" md="1">-</Col>
                  <Col className="unit-text remove-padding" md="1">+</Col>
                  <Col className="unit-text digit-small" md="1">-</Col>
                  <Col className="unit-text remove-padding" md="1">+</Col>
              </Row>
          { conductivitySensors.map((sensor, index) => 
              <Row key={index}>
                <Col md="3" className="parameter-text"><span><div className="sensor-title">{sensor.title.toUpperCase()}</div>  <div className="current-reading">{sensors[sensor.sensor].c.value}</div></span></Col>
                <Col md="4" className="text-center"><Controller 
                          render={({ onChange }) => (
                            <Form.Control 
                                as="input"
                                className="input-digit-big"
                                id={`${sensor.key}-n`} 
                                onChange={(e) => updateParameter(editConfig, "c", `${sensor.key}-n`, e.target.value)}
                                value={getValues(`${sensor.key}-n`)}
                                />
                          )} 
                          name={`${sensor.key}-n`}
                          control={control}
                      />
                </Col>
                <Col md="2" className="digit-small"><Controller 
                          render={({ onChange }) => (
                            <Form.Control 
                                as="input"
                                className="input-digit-small margin-right-4px"
                                id={`${sensor.key}-nl`} 
                                onChange={(e) => updateParameter(editConfig, "c", `${sensor.key}-nl`, e.target.value)}
                                value={getValues(`${sensor.key}-nl`)}/>
                          )} 
                          name={`${sensor.key}-nl`}
                          control={control}
                      />
                      <Controller 
                          render={({ onChange }) => (
                            <Form.Control 
                                as="input" 
                                className="input-digit-small"
                                id={`${sensor.key}-nh`}
                                onChange={(e) => updateParameter(editConfig, "c", `${sensor.key}-nh`, e.target.value)}
                                value={getValues(`${sensor.key}-nh`)}/>
                          )} 
                          name={`${sensor.key}-nh`}
                          control={control}
                      />
                </Col>
                <Col md="2" className="digit-small"><Controller 
                          render={({ onChange }) => (
                            <Form.Control 
                                as="input"
                                className="input-digit-small margin-right-4px"
                                id={`${sensor.key}-wl`}
                                onChange={(e) => updateParameter(editConfig, "c", `${sensor.key}-wl`, e.target.value)}
                                value={getValues(`${sensor.key}-wl`)}/>
                          )} 
                          name={`${sensor.key}-wl`}
                          control={control}
                      />
                      <Controller 
                          render={({ onChange }) => (
                            <Form.Control 
                                as="input" 
                                className="input-digit-small"
                                id={`${sensor.key}-wh`}
                                onChange={(e) => updateParameter(editConfig, "c", `${sensor.key}-wh`, e.target.value)}
                                value={getValues(`${sensor.key}-wh`)}/>
                          )} 
                          name={`${sensor.key}-wh`}
                          control={control}
                      />
                </Col>

              </Row>
          )}
              
            </Container>
           
      </form>
      
    )
  }