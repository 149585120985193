import React, { Component } from "react";
import { Row, Col, Button, Alert, Label } from "reactstrap";
import { Link } from "react-router-dom";
import config from 'react-global-configuration';
import { AvForm, AvField, AvGroup, AvFeedback, AvInput } from "availity-reactstrap-validation";
import moment from 'moment'
import flatten from "flat";
import { Modal } from 'reactstrap';
import { Redirect } from 'react-router';
import { connect } from 'react-redux'
import {withTranslation} from "react-i18next";



class MaintenanceJob extends Component {
  constructor(props) {
    super(props);
    
    var authUser = JSON.parse(sessionStorage.getItem("authUser"))

    this.state = {
        authUser: authUser,
        maintenance: '',
        modal_delete: false,
        showAlert: false,
        alertText: '',
        deleted: false,
        uuid: this.props.match.params.uuid
    };

    if (this.props.match.params.id) {
       
      this.getMaintenance()
    }
    
  }

  getMaintenance = () => {
    console.log("terekest")
    fetch(config.get('apiUrl')+'admin/maintenance/'+this.props.match.params.id,{
        headers: {
            Authorization: 'Bearer '+ this.state.authUser.token
        }
    })
    .then(res=>res.json())
    .then((data)=> {
        console.log(data)
            this.setState({
                maintenance: data.maintenance,
            })
        })
    }

    deleteRecord = () => {
        fetch(config.get('apiUrl')+'admin/maintenance/'+ this.props.match.params.uuid,{
            method: 'DELETE',
            headers: {
            'Content-Type' : 'application/json',
            Authorization: 'Bearer '+ this.state.authUser.token
            }
        })
            .then(res=>res.json())
            .then((data)=> {
            console.log(data);
            if (data.status === 'deleted'){
                this.setState({deleted: true});
            }
        
            });
    }


    updateObjectAttribute = (attr, value) => {
        this.setState(prevState => ({
            boat: {
                ...prevState.maintenance,
                [attr]:value
            }
        }))
    }


    updateObject = (event, values) => {
      var objectRequest = {
        uuid: this.state.uuid,
        title: values.title,
        description: values.description,
        maintenanceDate: values.maintenanceDate
      }
        fetch(config.get('apiUrl')+'admin/maintenance',{
            method: "PATCH",
            headers: {
                'Content-Type' : 'application/json',
                Authorization: 'Bearer '+ this.state.authUser.token
            },
            body: JSON.stringify({maintenance: objectRequest})
        })
        .then(res=>res.json())
        .then((data)=> {
            this.setState({
              showAlert: true,
              alertText: data.message
            })
      
        })
    }

    createObject = (event, values) => {
        var objectRequest = {
            title: values.title,
            description: values.description,
            maintenanceDate: values.maintenanceDate,
            iddevice: this.props.selectedDevice.value
        }

      fetch(config.get('apiUrl')+'admin/maintenance',{
          method: "POST",
          headers: {
              'Content-Type' : 'application/json',
              Authorization: 'Bearer '+ this.state.authUser.token
          },
          body: JSON.stringify({maintenance: objectRequest})
      })
      .then(res=>res.json())
      .then((data)=> {
          console.log(data)
          this.setState({
            maintenance: data.maintenance,
            uuid: data.maintenance.uuid,
            showAlert: true,
            alertText: "Maintenance record created",

          })
    
      })

  }

  render() {

    if (this.state.uuid && this.state.maintenance == null) {
        return ''
    }

    if (this.state.deleted) {
        return <Redirect to='/maintenance' />;
      }

    return (
      <React.Fragment>
        <div className="container-fluid">
          <Row className="align-items-center">
            <Col sm={6}>
              <div className="page-title-box">
                <h4 className="font-size-18">Maintenance {this.state.maintenance.title}</h4>
                <ol className="breadcrumb mb-0">
                  <li className="breadcrumb-item">
                    <Link to="/#">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item active">Maintenance</li>
                </ol>
              </div>
            </Col>

          </Row>

          <div className="row">
            <div className="col-12">
              {this.state.showAlert ? <Alert color="success">{this.state.alertText}</Alert>: ''}
              <div className="card">
              
                <div className="card-body">
                <h4 className="card-title">{this.state.uuid ? "Maintenance" : "Create new maintenance record"}</h4>
                  <AvForm onValidSubmit={this.state.uuid ? this.updateObject : this.createObject}>
                        <AvField
                            name="title"
                            label="Title"
                            grid={{sm:9}}
                            value={this.state.maintenance.title}
                            placeholder="Enter title for record"
                            type="text"
                            errorMessage="Title is mandatory"
                            validate={{
                              required: { value: true }
                            }}
                          />
                        <AvField
                            name="description"
                            label="Description"
                            grid={{sm:9}}
                            value={this.state.maintenance.description}
                            placeholder="Enter description for record"
                            type="textarea"
                            errorMessage="Description is mandatory"
                            validate={{
                              required: { value: true }
                            }}
                          />
                          <AvField
                            name="maintenanceDate"
                            label="Maintenance date"
                            grid={{sm:9}}
                            value={moment(this.state.maintenance.maintenanceDate).format('yyyy-MM-ddTHH:mm:ss')}
                            placeholder="Enter maintenance date for record"
                            type="datetime-local"
                            errorMessage="Maintenance date is mandatory"
                            validate={{
                              required: { value: true }
                            }}
                          />

                    <Row className="form-group">
                            <label
                            className="col-sm-3 col-form-label"
                            >
                            </label>
                            <Col className="col-form-label" sm={9}>
                            <Button color="primary" className="mr-1">
                                {this.state.uuid ? "Save" : "Create record" } 
                            </Button>
                            {this.state.uuid ? 
                                <Button color="secondary" onClick={()=>this.setState({modal_delete: true})}>
                                Kustuta
                                </Button>
                            : '' }
                        
                            </Col>
                        </Row>
                        </AvForm>
                </div>
              </div>
            </div>
          </div>


        </div>

        <Modal
          isOpen={this.state.modal_delete}
          toggle={this.tog_center}
        >
          <div className="modal-header">
            <h5 className="modal-title mt-0">Delete confirmation</h5>
            <button
              type="button"
              onClick={() =>
                this.setState({ modal_delete: false })
              }
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <p>
                Are you sure to delete the maintenance record?
            </p>
            <p>
              <button type="button" className="btn btn-primary" onClick={()=>this.deleteCustomer()}>
                Delete
              </button>
              <button
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal"
                onClick={() =>
                  this.setState({ modal_delete: false })
                }
              >
                Cancel
              </button>
            </p>
          </div>
        </Modal>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state){
    return {
      sensors: state.Device.sensors,
      actuators: state.Device.actuators,
      configuration: state.Device.config,
      commandIn: state.Device.commandIn,
      selectedDevice: state.Device.selectedDevice
    }
  }
  
  export default withTranslation('common')(connect(
    mapStateToProps
  )(MaintenanceJob))

