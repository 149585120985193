import React from 'react';

export default class OptionThree extends React.Component {


  render () { 

    
    return (
<svg width="960" height="600" viewBox="0 0 960 600">
  <defs>
    <filter id="XV03BG" x="380.5" y="474.5" width="57" height="44" filterUnits="userSpaceOnUse">
      <feOffset dx="2" dy="3" input="SourceAlpha"/>
      <feGaussianBlur stdDeviation="2.5" result="blur"/>
      <feFlood flood-opacity="0.443"/>
      <feComposite operator="in" in2="blur"/>
      <feComposite in="SourceGraphic"/>
    </filter>
    <filter id="RV01BG" x="521.5" y="474.5" width="59" height="44" filterUnits="userSpaceOnUse">
      <feOffset dx="2" dy="3" input="SourceAlpha"/>
      <feGaussianBlur stdDeviation="2.5" result="blur-2"/>
      <feFlood flood-opacity="0.439"/>
      <feComposite operator="in" in2="blur-2"/>
      <feComposite in="SourceGraphic"/>
    </filter>
    <clipPath id="clip-option-3">
      <rect width="960" height="600"/>
    </clipPath>
  </defs>
  <g id="option-3" clip-path="url(#clip-option-3)">
    <rect width="960" height="600" fill="none"/>
    <g id="XV" transform="translate(378 475) rotate(90)">
      <g id="Polygon_1" data-name="Polygon 1" transform="translate(18.29 17.5) rotate(90)" fill="#fff">
        <path d="M 20.13642883300781 17.79032135009766 L 0.8635713458061218 17.79032135009766 L 10.5 1.004287004470825 L 20.13642883300781 17.79032135009766 Z" stroke="none"/>
        <path d="M 10.5 2.008565902709961 L 1.727138519287109 17.29032135009766 L 19.27286148071289 17.29032135009766 L 10.5 2.008565902709961 M 10.5 1.9073486328125e-06 L 21 18.29032135009766 L 0 18.29032135009766 L 10.5 1.9073486328125e-06 Z" stroke="none" fill="#242323"/>
      </g>
      <g id="Polygon_2" data-name="Polygon 2" transform="translate(18.29 38.5) rotate(-90)" fill="#fff">
        <path d="M 20.13642883300781 17.79032135009766 L 0.8635713458061218 17.79032135009766 L 10.5 1.004286050796509 L 20.13642883300781 17.79032135009766 Z" stroke="none"/>
        <path d="M 10.5 2.008563995361328 L 1.727138519287109 17.29032135009766 L 19.27286148071289 17.29032135009766 L 10.5 2.008563995361328 M 10.5 0 L 21 18.29032135009766 L 0 18.29032135009766 L 10.5 0 Z" stroke="none" fill="#242323"/>
      </g>
      <g id="Rectangle_2" data-name="Rectangle 2" transform="translate(10.79)" fill="#fff" stroke="#242323" stroke-width="1">
        <rect width="15" height="15" stroke="none"/>
        <rect x="0.5" y="0.5" width="14" height="14" fill="none"/>
      </g>
      <line id="Line_2" data-name="Line 2" y2="14" transform="translate(18.29 14.5)" fill="none" stroke="#242323" stroke-width="1"/>
    </g>
    <g id="FL" transform="translate(622 522)">
      <g id="Group_3" data-name="Group 3">
        <g id="Group_1" data-name="Group 1" transform="translate(290 -146) rotate(90)">
          <g id="Polygon_5" data-name="Polygon 5" transform="translate(146.5 233)" fill="#fff">
            <path d="M 36.17016220092773 34.5 L 0.8298364281654358 34.5 L 18.5 1.069960832595825 L 36.17016220092773 34.5 Z" stroke="none"/>
            <path d="M 18.5 2.139911651611328 L 1.65966796875 34 L 35.34033203125 34 L 18.5 2.139911651611328 M 18.5 0 L 37 35 L 0 35 L 18.5 0 Z" stroke="none" fill="#242323"/>
          </g>
          <rect id="Rectangle_3" data-name="Rectangle 3" width="49" height="7" transform="translate(146 265)" fill="#fff"/>
          <g id="Ellipse_2" data-name="Ellipse 2" transform="translate(147 254)" fill="#fff" stroke="#242323" stroke-width="1">
            <circle cx="18" cy="18" r="18" stroke="none"/>
            <circle cx="18" cy="18" r="17.5" fill="none"/>
          </g>
        </g>
      </g>
    </g>
    <g id="RV" transform="translate(532 516.581)">
      <g id="Polygon_3" data-name="Polygon 3" transform="translate(18 13) rotate(90)" fill="#fff">
        <path d="M 20.12947845458984 17.5 L 0.8705216646194458 17.5 L 10.5 0.9923228621482849 L 20.12947845458984 17.5 Z" stroke="none"/>
        <path d="M 10.5 1.984636306762695 L 1.741037368774414 17 L 19.25896263122559 17 L 10.5 1.984636306762695 M 10.5 0 L 21 18 L 0 18 L 10.5 0 Z" stroke="none" fill="#242323"/>
      </g>
      <g id="Polygon_4" data-name="Polygon 4" transform="translate(17.355 34) rotate(-90)" fill="#fff">
        <path d="M 20.13642883300781 17.79032135009766 L 0.8635713458061218 17.79032135009766 L 10.5 1.004287004470825 L 20.13642883300781 17.79032135009766 Z" stroke="none"/>
        <path d="M 10.5 2.008565902709961 L 1.727138519287109 17.29032135009766 L 19.27286148071289 17.29032135009766 L 10.5 2.008565902709961 M 10.5 1.9073486328125e-06 L 21 18.29032135009766 L 0 18.29032135009766 L 10.5 1.9073486328125e-06 Z" stroke="none" fill="#242323"/>
      </g>
      <line id="Line_3" data-name="Line 3" y2="23" transform="translate(18)" fill="none" stroke="#242323" stroke-width="1"/>
      <line id="Line_4" data-name="Line 4" x2="26" transform="translate(5)" fill="none" stroke="#242323" stroke-width="1"/>
    </g>
    <path id="Path_6" data-name="Path 6" d="M7.557-58.361V12.277H144V175" transform="translate(205.5 300.5)" fill="none" stroke="#242323" stroke-width="1"/>
    <text id="XV-3-2" data-name="XV-3" transform="translate(309 497)" fill="#242323" font-size="12" font-family="Roboto-Bold, Roboto" font-weight="700" letter-spacing="0.01em"><tspan x="0" y="0">XV-3</tspan></text>
    {this.props.mode === 2 ? 
      <g id="ON_OFF" data-name="ON / OFF" text-anchor="middle" transform="translate(386 479)">
      <g transform="matrix(1, 0, 0, 1, -386, -479)" filter="url(#XV03BG)">
        <rect id="XV03BG-2" data-name="XV03BG" width="42" height="29" rx="8" transform="translate(386 479)" fill={this.props.actuators.xv03.status}/>
      </g>
      <text id="XV03" transform="translate(19 20)" fill={this.props.actuators.xv03.textStatus} font-size="18" font-family="Roboto-Bold, Roboto" font-weight="700" letter-spacing="0.01em"><tspan x="0" y="0">{this.props.actuators.xv03.value}</tspan></text>
      </g> : 
      <g id="ON_OFF" data-name="ON / OFF" text-anchor="middle" transform="translate(386 479)">
      <g id="XV03BG-2" fill={this.props.actuators.xv03.status} >
        <path d="M 43 25.5 L 5 25.5 C 2.51869010925293 25.5 0.5 23.48130989074707 0.5 21 L 0.5 5 C 0.5 2.51869010925293 2.51869010925293 0.5 5 0.5 L 43 0.5 C 45.4813117980957 0.5 47.5 2.51869010925293 47.5 5 L 47.5 21 C 47.5 23.48130989074707 45.4813117980957 25.5 43 25.5 Z" stroke="none"/>
        <path d="M 5 1 C 2.794391632080078 1 1 2.794389724731445 1 5 L 1 21 C 1 23.20561027526855 2.794391632080078 25 5 25 L 43 25 C 45.20560836791992 25 47 23.20561027526855 47 21 L 47 5 C 47 2.794389724731445 45.20560836791992 1 43 1 L 5 1 M 5 0 L 43 0 C 45.76142120361328 0 48 2.238580703735352 48 5 L 48 21 C 48 23.76141929626465 45.76142120361328 26 43 26 L 5 26 C 2.238571166992188 26 0 23.76141929626465 0 21 L 0 5 C 0 2.238580703735352 2.238571166992188 0 5 0 Z" stroke="none" fill="#d8d2d2"/>
      </g>
      <text id="XV03" transform="translate(22 18)" fill={this.props.actuators.xv03.textStatus} font-size="14" font-family="Roboto-Bold, Roboto" font-weight="700" letter-spacing="0.01em"><tspan x="0" y="0">{this.props.actuators.xv03.value}</tspan></text>
      </g>
    }

    
    
    <g id="flowKitConnector" transform="translate(-201 -34)">
      <path id="line" d="M551,545.581h0V574.5H734.177" fill="none" stroke="#242323" stroke-width="1"/>
      <path id="leftEdge" transform="translate(551 545.581)" fill="none" stroke="rgba(0,0,0,0)" stroke-width="1"/>
      <path id="rightEdge" transform="translate(734.177 574.5)" fill="none" stroke="rgba(0,0,0,0)" stroke-width="1"/>
    </g>
    <text id="RV-1" transform="translate(537 562)" fill="#242323" font-size="12" font-family="Roboto-Bold, Roboto" font-weight="700" letter-spacing="0.01em"><tspan x="0" y="0">RV-1</tspan></text>
    <line id="Line_16" data-name="Line 16" x2="54" transform="translate(568.5 540.5)" fill="none" stroke="#242323" stroke-width="1"/>
    <text id="FL4" transform="translate(639 545)" fill="#242323" font-size="12" font-family="Roboto-Bold, Roboto" font-weight="700" letter-spacing="0.01em"><tspan x="-10.099" y="0">FL4</tspan></text>

    {this.props.mode === 2 ? 
      <g id="ON_OFF-2" data-name="ON / OFF" text-anchor="middle" transform="translate(527 479)">
      <g transform="matrix(1, 0, 0, 1, -527, -479)" filter="url(#RV01BG)">
        <rect id="RV01BG-2" data-name="RV01BG" width="44" height="29" rx="8" transform="translate(527 479)" fill={this.props.actuators.rv01.status}/>
      </g>
      <text id="RV01" transform="translate(20 20)" fill={this.props.actuators.rv01.textStatus} font-size="16" font-family="Roboto-Bold, Roboto" font-weight="700" letter-spacing="0.01em"><tspan x="0" y="0">{this.props.actuators.rv01.value}</tspan></text>
      </g>: 
     <g id="ON_OFF-2" data-name="ON / OFF" text-anchor="middle" transform="translate(527 479)">
      <g id="RV01BG-2" fill={this.props.actuators.rv01.status} >
        <path d="M 43 25.5 L 5 25.5 C 2.51869010925293 25.5 0.5 23.48130989074707 0.5 21 L 0.5 5 C 0.5 2.51869010925293 2.51869010925293 0.5 5 0.5 L 43 0.5 C 45.4813117980957 0.5 47.5 2.51869010925293 47.5 5 L 47.5 21 C 47.5 23.48130989074707 45.4813117980957 25.5 43 25.5 Z" stroke="none"/>
        <path d="M 5 1 C 2.794391632080078 1 1 2.794389724731445 1 5 L 1 21 C 1 23.20561027526855 2.794391632080078 25 5 25 L 43 25 C 45.20560836791992 25 47 23.20561027526855 47 21 L 47 5 C 47 2.794389724731445 45.20560836791992 1 43 1 L 5 1 M 5 0 L 43 0 C 45.76142120361328 0 48 2.238580703735352 48 5 L 48 21 C 48 23.76141929626465 45.76142120361328 26 43 26 L 5 26 C 2.238571166992188 26 0 23.76141929626465 0 21 L 0 5 C 0 2.238580703735352 2.238571166992188 0 5 0 Z" stroke="none" fill="#d8d2d2"/>
      </g>
      <text id="RV01" transform="translate(22 18)" fill={this.props.actuators.rv01.textStatus} font-size="14" font-family="Roboto-Bold, Roboto" font-weight="700" letter-spacing="0.01em"><tspan x="0" y="0">{this.props.actuators.rv01.value}</tspan></text>
      </g>
    }
    
    <g id="Reading-label" text-anchor="middle" transform="translate(616 494.5)">
      <g id="FL04-1BG" fill={this.props.input.fl04.status}>
        <path d="M 43 25.5 L 5 25.5 C 2.51869010925293 25.5 0.5 23.48130989074707 0.5 21 L 0.5 5 C 0.5 2.51869010925293 2.51869010925293 0.5 5 0.5 L 43 0.5 C 45.4813117980957 0.5 47.5 2.51869010925293 47.5 5 L 47.5 21 C 47.5 23.48130989074707 45.4813117980957 25.5 43 25.5 Z" stroke="none"/>
        <path d="M 5 1 C 2.794391632080078 1 1 2.794389724731445 1 5 L 1 21 C 1 23.20561027526855 2.794391632080078 25 5 25 L 43 25 C 45.20560836791992 25 47 23.20561027526855 47 21 L 47 5 C 47 2.794389724731445 45.20560836791992 1 43 1 L 5 1 M 5 0 L 43 0 C 45.76142120361328 0 48 2.238580703735352 48 5 L 48 21 C 48 23.76141929626465 45.76142120361328 26 43 26 L 5 26 C 2.238571166992188 26 0 23.76141929626465 0 21 L 0 5 C 0 2.238580703735352 2.238571166992188 0 5 0 Z" stroke="none" fill="#d8d2d2"/>
      </g>
      <text id="FL04-1" transform="translate(22 17)" fill="#0a0909" font-size="14" font-family="Roboto-Bold, Roboto" font-weight="700" letter-spacing="0.01em"><tspan x="0" y="0">{this.props.input.fl04.cvalue}</tspan></text>
    </g>
    <g id="Reading-label-2" text-anchor="middle" data-name="Reading-label" transform="translate(615 466)">
      <g id="FL04-2BG" fill={this.props.input.fl04.status}>
        <path d="M 43 25.5 L 5 25.5 C 2.51869010925293 25.5 0.5 23.48130989074707 0.5 21 L 0.5 5 C 0.5 2.51869010925293 2.51869010925293 0.5 5 0.5 L 43 0.5 C 45.4813117980957 0.5 47.5 2.51869010925293 47.5 5 L 47.5 21 C 47.5 23.48130989074707 45.4813117980957 25.5 43 25.5 Z" stroke="none"/>
        <path d="M 5 1 C 2.794391632080078 1 1 2.794389724731445 1 5 L 1 21 C 1 23.20561027526855 2.794391632080078 25 5 25 L 43 25 C 45.20560836791992 25 47 23.20561027526855 47 21 L 47 5 C 47 2.794389724731445 45.20560836791992 1 43 1 L 5 1 M 5 0 L 43 0 C 45.76142120361328 0 48 2.238580703735352 48 5 L 48 21 C 48 23.76141929626465 45.76142120361328 26 43 26 L 5 26 C 2.238571166992188 26 0 23.76141929626465 0 21 L 0 5 C 0 2.238580703735352 2.238571166992188 0 5 0 Z" stroke="none" fill="#d8d2d2"/>
      </g>
      <text id="FL04-2" transform="translate(22 17)" fill="#0a0909" font-size="14" font-family="Roboto-Bold, Roboto" font-weight="700" letter-spacing="0.01em"><tspan x="0" y="0">{this.props.input.fl04.tvalue}</tspan></text>
    </g>
    <g id="flowKitConnector-2" data-name="flowKitConnector" transform="translate(513.054 792.188) rotate(-90)">
      <path id="line-2" data-name="line" d="M250.667,163.667h0v210.78h62.522" transform="translate(0)" fill="none" stroke="#242323" stroke-width="1"/>
      <path id="leftEdge-2" data-name="leftEdge" transform="translate(250.667 163.667)" fill="none" stroke="rgba(0,0,0,0)" stroke-width="1"/>
      <path id="rightEdge-2" data-name="rightEdge" d="M-7.5-4h0L0,0-7.5,4Z" transform="translate(313.188 374.446)" fill="#666" stroke="#666" stroke-width="1"/>
    </g>
    <text id="FEED" transform="translate(850 534)" fill="#242323" font-size="12" font-family="Roboto-Bold, Roboto" font-weight="700" letter-spacing="0.01em"><tspan x="0" y="0">FEED</tspan></text>
  </g>
</svg>



);
}
}