import React, { Component } from 'react';
import Chart from 'chart.js/auto';
import zoomPlugin from 'chartjs-plugin-zoom';


export default class LineChart extends Component {
    chartRef = React.createRef();

	componentDidMount() {
		const ctx = this.chartRef.current.getContext("2d");

		let theData = this.props.measurementData
		Chart.register(zoomPlugin);

		new Chart(ctx, {
			type: "line",
			data: {
			  datasets: [
				{
				  label: this.props.sensorName + ' (' + this.props.sensorUnit + ')',
				  data: theData,
				  fill: false,
				  indexAxis: 'x',
				  radius: 0,
				  backgroundColor: 'rgb(255, 99, 132)',
				  borderColor: 'rgba(255, 99, 132, 0.2)',
				}
			  ]
			},
			options: {
			  animation: false,
			  parsing: false,
			  plugins: {
				  zoom: {
				      zoom: {
				            drag: {
				              enabled: true
				            },
				            mode: 'x'
				          },
					  
				  }, 
				  decimation: {
					enabled: true,
					algorithm: 'lttb',
					
				  }
			  },
			  scales: {
				  x: {
					type: 'time',
					time: {
					  unit: this.props.selectedTimeRange.value === 168 ? 'hour' : 'minute',
					  displayFormats:{
						'millisecond': this.props.selectedTimeRange.value === 168 ? 'DD-MM HH:mm' : 'HH:mm', 
						'second': this.props.selectedTimeRange.value === 168 ? 'DD-MM HH:mm' : 'HH:mm',  
						'minute': this.props.selectedTimeRange.value === 168 ? 'DD-MM HH:mm' : 'HH:mm',  
						'hour': this.props.selectedTimeRange.value === 168 ? 'DD-MM HH:mm' : 'HH:mm',  
						'day': this.props.selectedTimeRange.value === 168 ? 'DD-MM HH:mm' : 'HH:mm',  
						'week': this.props.selectedTimeRange.value === 168 ? 'DD-MM HH:mm' : 'HH:mm', 
						'month': this.props.selectedTimeRange.value === 168 ? 'DD-MM HH:mm' : 'HH:mm',  
						'quarter': this.props.selectedTimeRange.value === 168 ? 'DD-MM HH:mm' : 'HH:mm', 
						'year': this.props.selectedTimeRange.value === 168 ? 'DD-MM HH:mm' : 'HH:mm', 
					  },
					  tooltipFormat: 'DD.MM.YYYY HH:mm:ss'
					},
					ticks: {
						source: 'auto',
						// Disabled rotation for performance
						maxRotation: 0,
						autoSkip: true,
					  }
				  },
				y: {}
	
			  }
			}
			
			},
		  );

		  
	}
	render() {
		return (
			<div>
				<canvas
				id="myChart"
				ref={this.chartRef}
				/>
			</div>
			)
	}
	
}