import React from 'react';
import config from 'react-global-configuration';


export default class Schematics extends React.Component {

  getBackgroundColor = (levels, value) => {
    if (value === "N/A") {return config.get('statusInactive')}
    if ((value > (levels.n - levels.nl)) && (value < (levels.n + levels.nh))){return config.get('statusGreen')}
    else if ((value > (levels.n - levels.nl - levels.wl)) && (value < (levels.n + levels.nh + levels.wh))){return config.get('statusYellow')}
    else {return config.get('statusRed')}
    
  }


  render () { 

    return (

      <svg id="schematics" width="960" height="600" viewBox="0 0 960 600">
  <defs>

    <filter id="RV03BG" x="559.5" y="177.5" width="59" height="44" filterUnits="userSpaceOnUse">
      <feOffset dx="2" dy="3" input="SourceAlpha"/>
      <feGaussianBlur stdDeviation="2.5" result="blur-3"/>
      <feFlood flood-opacity="0.443"/>
      <feComposite operator="in" in2="blur-3"/>
      <feComposite in="SourceGraphic"/>
    </filter>
    <filter id="RV02BG" x="606.5" y="178" width="59" height="44" filterUnits="userSpaceOnUse">
      <feOffset dx="2" dy="3" input="SourceAlpha"/>
      <feGaussianBlur stdDeviation="2.5" result="blur-4"/>
      <feFlood flood-opacity="0.443"/>
      <feComposite operator="in" in2="blur-4"/>
      <feComposite in="SourceGraphic"/>
    </filter>
    <filter id="XV02BG" x="653.5" y="247" width="59" height="44" filterUnits="userSpaceOnUse">
      <feOffset dx="2" dy="3" input="SourceAlpha"/>
      <feGaussianBlur stdDeviation="2.5" result="blur-5"/>
      <feFlood flood-opacity="0.443"/>
      <feComposite operator="in" in2="blur-5"/>
      <feComposite in="SourceGraphic"/>
    </filter>
    <filter id="XV01BG" x="162.5" y="174.5" width="59" height="44" filterUnits="userSpaceOnUse">
      <feOffset dx="2" dy="3" input="SourceAlpha"/>
      <feGaussianBlur stdDeviation="2.5" result="blur-6"/>
      <feFlood flood-opacity="0.443"/>
      <feComposite operator="in" in2="blur-6"/>
      <feComposite in="SourceGraphic"/>
    </filter>
    <filter id="P01BG" x="409.5" y="370.5" width="59" height="44" filterUnits="userSpaceOnUse">
      <feOffset dx="2" dy="3" input="SourceAlpha"/>
      <feGaussianBlur stdDeviation="2.5" result="blur-7"/>
      <feFlood flood-opacity="0.443"/>
      <feComposite operator="in" in2="blur-7"/>
      <feComposite in="SourceGraphic"/>
    </filter>
    <filter id="DPBG" x="368.5" y="47.5" width="59" height="44" filterUnits="userSpaceOnUse">
      <feOffset dx="2" dy="3" input="SourceAlpha"/>
      <feGaussianBlur stdDeviation="2.5" result="blur-8"/>
      <feFlood flood-opacity="0.443"/>
      <feComposite operator="in" in2="blur-8"/>
      <feComposite in="SourceGraphic"/>
    </filter>
    <clipPath id="clip-Front_-_FULL_SCOPE_1">
      <rect width="960" height="600"/>
    </clipPath>
  </defs>
  <g id="Front_-_FULL_SCOPE_1" data-name="Front - FULL SCOPE – 1" clip-path="url(#clip-Front_-_FULL_SCOPE_1)">
    <rect width="960" height="600" fill="#fff"/>
    <g id="XV" transform="translate(173 215)">
      <g id="Polygon_1" data-name="Polygon 1" transform="translate(18.29 17.5) rotate(90)" fill="#fff">
        <path d="M 20.13642883300781 17.79032135009766 L 0.8635713458061218 17.79032135009766 L 10.5 1.004287004470825 L 20.13642883300781 17.79032135009766 Z" stroke="none"/>
        <path d="M 10.5 2.008565902709961 L 1.727138519287109 17.29032135009766 L 19.27286148071289 17.29032135009766 L 10.5 2.008565902709961 M 10.5 1.9073486328125e-06 L 21 18.29032135009766 L 0 18.29032135009766 L 10.5 1.9073486328125e-06 Z" stroke="none" fill="#242323"/>
      </g>
      <g id="Polygon_2" data-name="Polygon 2" transform="translate(18.29 38.5) rotate(-90)" fill="#fff">
        <path d="M 20.13642883300781 17.79032135009766 L 0.8635713458061218 17.79032135009766 L 10.5 1.004286050796509 L 20.13642883300781 17.79032135009766 Z" stroke="none"/>
        <path d="M 10.5 2.008563995361328 L 1.727138519287109 17.29032135009766 L 19.27286148071289 17.29032135009766 L 10.5 2.008563995361328 M 10.5 0 L 21 18.29032135009766 L 0 18.29032135009766 L 10.5 0 Z" stroke="none" fill="#242323"/>
      </g>
      <g id="Rectangle_2" data-name="Rectangle 2" transform="translate(10.79)" fill="#fff" stroke="#242323" stroke-width="1">
        <rect width="15" height="15" stroke="none"/>
        <rect x="0.5" y="0.5" width="14" height="14" fill="none"/>
      </g>
      <line id="Line_2" data-name="Line 2" y2="14" transform="translate(18.29 14.5)" fill="none" stroke="#242323" stroke-width="1"/>
    </g>
    <g id="Pump" transform="translate(410 411)">
      <g id="Polygon_6" data-name="Polygon 6" transform="translate(7 35)" fill="#fff">
        <path d="M 37.85338592529297 20.5 L 1.146613597869873 20.5 L 19.5 0.7348146438598633 L 37.85338592529297 20.5 Z" stroke="none"/>
        <path d="M 19.5 1.469608306884766 L 2.293209075927734 20 L 36.70679092407227 20 L 19.5 1.469608306884766 M 19.5 0 L 39 21 L 0 21 L 19.5 0 Z" stroke="none" fill="#242323"/>
      </g>
      <g id="Ellipse_3" data-name="Ellipse 3" fill="#fff" stroke="#242323" stroke-width="1">
        <ellipse cx="26.5" cy="25.5" rx="26.5" ry="25.5" stroke="none"/>
        <ellipse cx="26.5" cy="25.5" rx="26" ry="25" fill="none"/>
      </g>
    </g>
    <g id="Tank" transform="translate(217 183.804)">
      <g id="Rectangle_4" data-name="Rectangle 4" fill="#fff" stroke="#242323" stroke-width="1">
        <rect width="49" height="117" rx="20" stroke="none"/>
        <rect x="0.5" y="0.5" width="48" height="116" rx="19.5" fill="none"/>
      </g>
    </g>
    <g id="flowKitConnector" transform="translate(-87.5 37.108)">
      <path id="line" d="M107.5,205.5h60" fill="none" stroke="#242323" stroke-width="1"/>
      <path id="leftEdge" transform="translate(107.5 205.5)" fill="none" stroke="rgba(0,0,0,0)" stroke-width="1"/>
      <path id="rightEdge" d="M-7.5-4h0L0,0-7.5,4Z" transform="translate(167.5 205.5)" fill="#666" stroke="#666" stroke-width="1"/>
    </g>
    <g id="Group_6" data-name="Group 6">
      <g id="sensor" transform="translate(52 195)">
        <g id="Ellipse_1" data-name="Ellipse 1" transform="translate(1.5 2)" fill="#fff" stroke="#242323" stroke-width="1">
          <circle cx="18.5" cy="18.5" r="18.5" stroke="none"/>
          <circle cx="18.5" cy="18.5" r="18" fill="none"/>
        </g>
        <line id="Line_1" data-name="Line 1" x2="39" transform="translate(0.5 20)" fill="none" stroke="#242323" stroke-width="1"/>
        <g id="Rectangle_1" data-name="Rectangle 1" fill="none" stroke="#242323" stroke-width="1">
          <rect width="40" height="40" stroke="none"/>
          <rect x="0.5" y="0.5" width="39" height="39" fill="none"/>
        </g>
      </g>
      <g id="Group_41" data-name="Group 41">
        <text id="PS" transform="translate(65 212)" fill="#242323" font-size="12" font-family="Roboto-Bold, Roboto" font-weight="700" letter-spacing="0.01em"><tspan x="0" y="0">PS</tspan></text>
        <text id="_1" data-name="1" transform="translate(72 228)" fill="#242323" font-size="12" font-family="Roboto-Bold, Roboto" font-weight="700" letter-spacing="0.01em"><tspan x="-3.442" y="0">1</tspan></text>
      </g>
       </g>
 
    <line id="Line_5" data-name="Line 5" x2="93" transform="translate(79.776 242.5)" fill="none" stroke="#242323" stroke-width="1"/>
    <g id="Tank-2" data-name="Tank" transform="translate(273 183.804)">
      <g id="Rectangle_4-2" data-name="Rectangle 4" fill="#fff" stroke="#242323" stroke-width="1">
        <rect width="49" height="117" rx="20" stroke="none"/>
        <rect x="0.5" y="0.5" width="48" height="116" rx="19.5" fill="none"/>
      </g>
    </g>
    <g id="sensor-3" data-name="sensor" transform="translate(330 195)">
      <g id="Ellipse_1-3" data-name="Ellipse 1" transform="translate(1.5 2)" fill="#fff" stroke="#242323" stroke-width="1">
        <circle cx="18.5" cy="18.5" r="18.5" stroke="none"/>
        <circle cx="18.5" cy="18.5" r="18" fill="none"/>
      </g>
      <line id="Line_1-3" data-name="Line 1" x2="39" transform="translate(0.5 20)" fill="none" stroke="#242323" stroke-width="1"/>
      <g id="Rectangle_1-3" data-name="Rectangle 1" fill="none" stroke="#242323" stroke-width="1">
        <rect width="40" height="40" stroke="none"/>
        <rect x="0.5" y="0.5" width="39" height="39" fill="none"/>
      </g>
    </g>
    <g id="Group_42" data-name="Group 42">
      <text id="PS-2" data-name="PS" transform="translate(343 211)" fill="#242323" font-size="12" font-family="Roboto-Bold, Roboto" font-weight="700" letter-spacing="0.01em"><tspan x="0" y="0">PS</tspan></text>
      <text id="_2" data-name="2" transform="translate(350 227)" fill="#242323" font-size="12" font-family="Roboto-Bold, Roboto" font-weight="700" letter-spacing="0.01em"><tspan x="-3.442" y="0">2</tspan></text>
    </g>

    <g id="Group_46" data-name="Group 46" transform="translate(118 -1.361)">
      <g id="sensor-12" data-name="sensor" transform="translate(428 195)">
        <g id="Ellipse_1-12" data-name="Ellipse 1" transform="translate(1.5 2)" fill="#fff" stroke="#242323" stroke-width="1">
          <circle cx="18.5" cy="18.5" r="18.5" stroke="none"/>
          <circle cx="18.5" cy="18.5" r="18" fill="none"/>
        </g>
        <line id="Line_1-12" data-name="Line 1" x2="39" transform="translate(0.5 20)" fill="none" stroke="#242323" stroke-width="1"/>
        <g id="Rectangle_1-12" data-name="Rectangle 1" fill="none" stroke="#242323" stroke-width="1">
          <rect width="40" height="40" stroke="none"/>
          <rect x="0.5" y="0.5" width="39" height="39" fill="none"/>
        </g>
      </g>
      <line id="Line_10" data-name="Line 10" y2="79.361" transform="translate(448.5 237.312)" fill="none" stroke="#242323" stroke-width="1"/>
      <g id="Reading-label-23" data-name="Reading-label" text-anchor="middle" transform="translate(425 167)">
        <g id="PS04BG" fill={this.getBackgroundColor(this.props.config.p.p4, this.props.input.ps04.value)}>
          <path d="M 43 25.5 L 5 25.5 C 2.51869010925293 25.5 0.5 23.48130989074707 0.5 21 L 0.5 5 C 0.5 2.51869010925293 2.51869010925293 0.5 5 0.5 L 43 0.5 C 45.4813117980957 0.5 47.5 2.51869010925293 47.5 5 L 47.5 21 C 47.5 23.48130989074707 45.4813117980957 25.5 43 25.5 Z" stroke="none"/>
          <path d="M 5 1 C 2.794391632080078 1 1 2.794389724731445 1 5 L 1 21 C 1 23.20561027526855 2.794391632080078 25 5 25 L 43 25 C 45.20560836791992 25 47 23.20561027526855 47 21 L 47 5 C 47 2.794389724731445 45.20560836791992 1 43 1 L 5 1 M 5 0 L 43 0 C 45.76142120361328 0 48 2.238580703735352 48 5 L 48 21 C 48 23.76141929626465 45.76142120361328 26 43 26 L 5 26 C 2.238571166992188 26 0 23.76141929626465 0 21 L 0 5 C 0 2.238580703735352 2.238571166992188 0 5 0 Z" stroke="none" fill="#d8d2d2"/>
        </g>
        <text id="PS04" transform="translate(22 18)" fill="#0a0909" font-size="14" font-family="Roboto-Bold, Roboto" font-weight="700" letter-spacing="0.01em"><tspan x="0" y="0">{this.props.input.ps04.value}</tspan></text>
      </g>
      <g id="Group_43" data-name="Group 43">
        <text id="PS-5" data-name="PS" transform="translate(441 212)" fill="#242323" font-size="12" font-family="Roboto-Bold, Roboto" font-weight="700" letter-spacing="0.01em"><tspan x="0" y="0">PS</tspan></text>
        <text id="_4-2" data-name="4" transform="translate(448 228)" fill="#242323" font-size="12" font-family="Roboto-Bold, Roboto" font-weight="700" letter-spacing="0.01em"><tspan x="-3.442" y="0">4</tspan></text>
      </g>
    </g>
    
    <g id="RV-2" data-name="RV" transform="translate(616 219)">
      <g id="Polygon_3-2" data-name="Polygon 3" transform="translate(18 13) rotate(90)" fill="#fff">
        <path d="M 20.12947845458984 17.5 L 0.8705216646194458 17.5 L 10.5 0.9923228621482849 L 20.12947845458984 17.5 Z" stroke="none"/>
        <path d="M 10.5 1.984636306762695 L 1.741037368774414 17 L 19.25896263122559 17 L 10.5 1.984636306762695 M 10.5 0 L 21 18 L 0 18 L 10.5 0 Z" stroke="none" fill="#242323"/>
      </g>
      <g id="Polygon_4-2" data-name="Polygon 4" transform="translate(17.355 34) rotate(-90)" fill="#fff">
        <path d="M 20.13642883300781 17.79032135009766 L 0.8635713458061218 17.79032135009766 L 10.5 1.004287004470825 L 20.13642883300781 17.79032135009766 Z" stroke="none"/>
        <path d="M 10.5 2.008565902709961 L 1.727138519287109 17.29032135009766 L 19.27286148071289 17.29032135009766 L 10.5 2.008565902709961 M 10.5 1.9073486328125e-06 L 21 18.29032135009766 L 0 18.29032135009766 L 10.5 1.9073486328125e-06 Z" stroke="none" fill="#242323"/>
      </g>
      <line id="Line_3-2" data-name="Line 3" y2="23" transform="translate(18)" fill="none" stroke="#242323" stroke-width="1"/>
      <line id="Line_4-2" data-name="Line 4" x2="26" transform="translate(5)" fill="none" stroke="#242323" stroke-width="1"/>
    </g>
    <g id="FL-2" data-name="FL" transform="translate(728 224)">
      <g id="Group_3-2" data-name="Group 3">
        <g id="Group_1-2" data-name="Group 1" transform="translate(290 -146) rotate(90)">
          <g id="Polygon_5-2" data-name="Polygon 5" transform="translate(146.5 233)" fill="#fff">
            <path d="M 36.17016220092773 34.5 L 0.8298364281654358 34.5 L 18.5 1.069960832595825 L 36.17016220092773 34.5 Z" stroke="none"/>
            <path d="M 18.5 2.139911651611328 L 1.65966796875 34 L 35.34033203125 34 L 18.5 2.139911651611328 M 18.5 0 L 37 35 L 0 35 L 18.5 0 Z" stroke="none" fill="#242323"/>
          </g>
          <rect id="Rectangle_3-2" data-name="Rectangle 3" width="49" height="7" transform="translate(146 265)" fill="#fff"/>
          <g id="Ellipse_2-2" data-name="Ellipse 2" transform="translate(147 254)" fill="#fff" stroke="#242323" stroke-width="1">
            <circle cx="18" cy="18" r="18" stroke="none"/>
            <circle cx="18" cy="18" r="17.5" fill="none"/>
          </g>
        </g>
      </g>
    </g>
    <g id="XV-2" data-name="XV" transform="translate(664 287.75)">
      <g id="Polygon_1-2" data-name="Polygon 1" transform="translate(18.29 17.5) rotate(90)" fill="#fff">
        <path d="M 20.13642883300781 17.79032135009766 L 0.8635713458061218 17.79032135009766 L 10.5 1.004287004470825 L 20.13642883300781 17.79032135009766 Z" stroke="none"/>
        <path d="M 10.5 2.008565902709961 L 1.727138519287109 17.29032135009766 L 19.27286148071289 17.29032135009766 L 10.5 2.008565902709961 M 10.5 1.9073486328125e-06 L 21 18.29032135009766 L 0 18.29032135009766 L 10.5 1.9073486328125e-06 Z" stroke="none" fill="#242323"/>
      </g>
      <g id="Polygon_2-2" data-name="Polygon 2" transform="translate(18.29 38.5) rotate(-90)" fill="#fff">
        <path d="M 20.13642883300781 17.79032135009766 L 0.8635713458061218 17.79032135009766 L 10.5 1.004286050796509 L 20.13642883300781 17.79032135009766 Z" stroke="none"/>
        <path d="M 10.5 2.008563995361328 L 1.727138519287109 17.29032135009766 L 19.27286148071289 17.29032135009766 L 10.5 2.008563995361328 M 10.5 0 L 21 18.29032135009766 L 0 18.29032135009766 L 10.5 0 Z" stroke="none" fill="#242323"/>
      </g>
      <g id="Rectangle_2-2" data-name="Rectangle 2" transform="translate(10.79)" fill="#fff" stroke="#242323" stroke-width="1">
        <rect width="15" height="15" stroke="none"/>
        <rect x="0.5" y="0.5" width="14" height="14" fill="none"/>
      </g>
      <line id="Line_2-2" data-name="Line 2" y2="14" transform="translate(18.29 14.5)" fill="none" stroke="#242323" stroke-width="1"/>
    </g>
    <g id="sensor-7" data-name="sensor" transform="translate(699 413)">
      <g id="Ellipse_1-7" data-name="Ellipse 1" transform="translate(1.5 2)" fill="#fff" stroke="#242323" stroke-width="1">
        <circle cx="18.5" cy="18.5" r="18.5" stroke="none"/>
        <circle cx="18.5" cy="18.5" r="18" fill="none"/>
      </g>
      <line id="Line_1-7" data-name="Line 1" x2="39" transform="translate(0.5 20)" fill="none" stroke="#242323" stroke-width="1"/>
      <g id="Rectangle_1-7" data-name="Rectangle 1" fill="none" stroke="#242323" stroke-width="1">
        <rect width="40" height="40" stroke="none"/>
        <rect x="0.5" y="0.5" width="39" height="39" fill="none"/>
      </g>
    </g>
    <g id="FL-3" data-name="FL" transform="translate(762.332 442.5)">
      <g id="Group_3-3" data-name="Group 3">
        <g id="Group_1-3" data-name="Group 1" transform="translate(290 -146) rotate(90)">
          <g id="Polygon_5-3" data-name="Polygon 5" transform="translate(146.5 233)" fill="#fff">
            <path d="M 36.17016220092773 34.5 L 0.8298364281654358 34.5 L 18.5 1.069960832595825 L 36.17016220092773 34.5 Z" stroke="none"/>
            <path d="M 18.5 2.139911651611328 L 1.65966796875 34 L 35.34033203125 34 L 18.5 2.139911651611328 M 18.5 0 L 37 35 L 0 35 L 18.5 0 Z" stroke="none" fill="#242323"/>
          </g>
          <rect id="Rectangle_3-3" data-name="Rectangle 3" width="49" height="7" transform="translate(146 265)" fill="#fff"/>
          <g id="Ellipse_2-3" data-name="Ellipse 2" transform="translate(147 254)" fill="#fff" stroke="#242323" stroke-width="1">
            <circle cx="18" cy="18" r="18" stroke="none"/>
            <circle cx="18" cy="18" r="17.5" fill="none"/>
          </g>
        </g>
      </g>
    </g>
    <text id="FEED" transform="translate(20 259)" fill="#242323" font-size="12" font-family="Roboto-Bold, Roboto" font-weight="700" letter-spacing="0.01em"><tspan x="0" y="0">FEED</tspan></text>
    <text id="XV-1" transform="translate(178 269)" fill="#242323" font-size="12" font-family="Roboto-Bold, Roboto" font-weight="700" letter-spacing="0.01em"><tspan x="0" y="0">XV-1</tspan></text>
    <line id="Line_7" data-name="Line 7" x2="8" transform="translate(209.5 242.5)" fill="none" stroke="#707070" stroke-width="1"/>
    <line id="Line_8" data-name="Line 8" x2="7" transform="translate(266.5 242.5)" fill="none" stroke="#707070" stroke-width="1"/>
    <text id="F-1_5MKR" data-name="F-1
5MKR" transform="translate(242 208)" fill="#242323" font-size="11" font-family="Roboto-Bold, Roboto" font-weight="700" letter-spacing="0.01em"><tspan x="-8.411" y="0">F-1</tspan><tspan x="-15.14" y="13">5MKR</tspan></text>
    <text id="F-2_CARBON" data-name="F-2
CARBON" transform="translate(297 208)" fill="#242323" font-size="11" font-family="Roboto-Bold, Roboto" font-weight="700" letter-spacing="0.01em"><tspan x="-8.411" y="0">F-2</tspan><tspan x="-22.275" y="13">CARBON</tspan></text>
    <g id="flowKitConnector-3" data-name="flowKitConnector" transform="translate(215 37.108)">
      <path id="line-3" data-name="line" d="M107.5,205.5h69.168" fill="none" stroke="#242323" stroke-width="1"/>
      <path id="leftEdge-3" data-name="leftEdge" transform="translate(107.5 205.5)" fill="none" stroke="rgba(0,0,0,0)" stroke-width="1"/>
      <path id="rightEdge-3" data-name="rightEdge" d="M-7.5-4h0L0,0-7.5,4Z" transform="translate(176.668 205.5)" fill="#666" stroke="#666" stroke-width="1"/>
    </g>
    <g id="FL1flowkitconnector" transform="translate(535.5 450.5) rotate(180)">
      <path id="line-4" data-name="line" d="M107.5,205.5h32" transform="translate(0 1.5)" fill="none" stroke="#242323" stroke-width="1"/>
      <path id="leftEdge-4" data-name="leftEdge" transform="translate(107.5 207)" fill="none" stroke="rgba(0,0,0,0)" stroke-width="1"/>
      <path id="rightEdge-4" data-name="rightEdge" d="M-7.5-4h0L0,1.5-7.5,7Z" transform="translate(139.5 205.5)" fill="#666" stroke="#666" stroke-width="1"/>
    </g>
    <g id="flowKitConnector-5" data-name="flowKitConnector" transform="translate(152.557 49.193)">
      <path id="line-5" data-name="line" d="M241.111,193.111h0v197.7h42.832" fill="none" stroke="#242323" stroke-width="1"/>
      <path id="leftEdge-5" data-name="leftEdge" transform="translate(241.111 193.111)" fill="none" stroke="rgba(0,0,0,0)" stroke-width="1"/>
      <path id="rightEdge-5" data-name="rightEdge" d="M-7.5-4h0L0,0-7.5,4Z" transform="translate(283.943 390.807)" fill="#666" stroke="#666" stroke-width="1"/>
    </g>
    <line id="Line_11" data-name="Line 11" y2="7" transform="translate(350.5 235.5)" fill="none" stroke="#242323" stroke-width="1"/>
    <line id="Line_13" data-name="Line 13" x2="21" transform="translate(469.5 243)" fill="none" stroke="#242323" stroke-width="1"/>
    <line id="Line_14" data-name="Line 14" y1="1" x2="90" transform="translate(524.5 242.5)" fill="none" stroke="#242323" stroke-width="1"/>
    <line id="Line_15" data-name="Line 15" x2="78" transform="translate(650.5 242.5)" fill="none" stroke="#242323" stroke-width="1"/>

    <text id="RV-2-2" data-name="RV-2" transform="translate(621 269)" fill="#242323" font-size="12" font-family="Roboto-Bold, Roboto" font-weight="700" letter-spacing="0.01em"><tspan x="0" y="0">RV-2</tspan></text>
    
    <g id="Group_48" data-name="Group 48" transform="translate(-82 -1)">
      <g id="RV-3" data-name="RV" transform="translate(570 219)">
        <g id="Polygon_3-3" data-name="Polygon 3" transform="translate(18 13) rotate(90)" fill="#fff">
          <path d="M 20.12947845458984 17.5 L 0.8705216646194458 17.5 L 10.5 0.9923228621482849 L 20.12947845458984 17.5 Z" stroke="none"/>
          <path d="M 10.5 1.984636306762695 L 1.741037368774414 17 L 19.25896263122559 17 L 10.5 1.984636306762695 M 10.5 0 L 21 18 L 0 18 L 10.5 0 Z" stroke="none" fill="#242323"/>
        </g>
        <g id="Polygon_4-3" data-name="Polygon 4" transform="translate(17.355 34) rotate(-90)" fill="#fff">
          <path d="M 20.13642883300781 17.79032135009766 L 0.8635713458061218 17.79032135009766 L 10.5 1.004287004470825 L 20.13642883300781 17.79032135009766 Z" stroke="none"/>
          <path d="M 10.5 2.008565902709961 L 1.727138519287109 17.29032135009766 L 19.27286148071289 17.29032135009766 L 10.5 2.008565902709961 M 10.5 1.9073486328125e-06 L 21 18.29032135009766 L 0 18.29032135009766 L 10.5 1.9073486328125e-06 Z" stroke="none" fill="#242323"/>
        </g>
        <line id="Line_3-3" data-name="Line 3" y2="23" transform="translate(18)" fill="none" stroke="#242323" stroke-width="1"/>
        <line id="Line_4-3" data-name="Line 4" x2="26" transform="translate(5)" fill="none" stroke="#242323" stroke-width="1"/>
      </g>
      <text id="RV-3-2" data-name="RV-3" transform="translate(575 269)" fill="#242323" font-size="12" font-family="Roboto-Bold, Roboto" font-weight="700" letter-spacing="0.01em"><tspan x="0" y="0">RV-3</tspan></text>
      
      {this.props.mode === 2 ? 

      
      <g id="ON_OFF-3" text-anchor="middle" data-name="ON / OFF" transform="translate(565 182)">
      <g transform="matrix(1, 0, 0, 1, -565, -182)" filter="url(#RV03BG)">
        <rect id="RV03BG-2" data-name="RV03BG" width="44" height="29" rx="8" transform="translate(565 182)" fill={this.props.actuators.rv03.status}/>
      </g>
      <text id="RV03" transform="translate(20 21)" fill={this.props.actuators.rv03.textStatus} font-size="16" font-family="Roboto-Bold, Roboto" font-weight="700" letter-spacing="0.01em"><tspan x="0" y="0">{this.props.actuators.rv03.value}</tspan></text>
    </g> : 
      <g id="ON_OFF-3" text-anchor="middle" data-name="ON / OFF" transform="translate(565 182)">
      <g id="RV03BG-2" fill={this.props.actuators.rv03.status} >
        <path d="M 43 25.5 L 5 25.5 C 2.51869010925293 25.5 0.5 23.48130989074707 0.5 21 L 0.5 5 C 0.5 2.51869010925293 2.51869010925293 0.5 5 0.5 L 43 0.5 C 45.4813117980957 0.5 47.5 2.51869010925293 47.5 5 L 47.5 21 C 47.5 23.48130989074707 45.4813117980957 25.5 43 25.5 Z" stroke="none"/>
        <path d="M 5 1 C 2.794391632080078 1 1 2.794389724731445 1 5 L 1 21 C 1 23.20561027526855 2.794391632080078 25 5 25 L 43 25 C 45.20560836791992 25 47 23.20561027526855 47 21 L 47 5 C 47 2.794389724731445 45.20560836791992 1 43 1 L 5 1 M 5 0 L 43 0 C 45.76142120361328 0 48 2.238580703735352 48 5 L 48 21 C 48 23.76141929626465 45.76142120361328 26 43 26 L 5 26 C 2.238571166992188 26 0 23.76141929626465 0 21 L 0 5 C 0 2.238580703735352 2.238571166992188 0 5 0 Z" stroke="none" fill="#d8d2d2"/>
      </g>
      <text id="RV03" transform="translate(22 18)" fill={this.props.actuators.rv03.textStatus} font-size="14" font-family="Roboto-Bold, Roboto" font-weight="700" letter-spacing="0.01em"><tspan x="0" y="0">{this.props.actuators.rv03.value}</tspan></text>
      </g>
    }
    </g>
    
    
    
  {this.props.mode === 2 ? 
      <g id="ON_OFF-4" text-anchor="middle" data-name="ON / OFF" transform="translate(612 182.5)">
      <g transform="matrix(1, 0, 0, 1, -612, -182.5)" filter="url(#RV02BG)">
        <rect id="RV02BG-2" data-name="RV02BG" width="44" height="29" rx="8" transform="translate(612 182.5)" fill={this.props.actuators.rv02.status}/>
      </g>
      <text id="RV02" transform="translate(20 21)" fill={this.props.actuators.rv02.textStatus} font-size="16" font-family="Roboto-Bold, Roboto" font-weight="700" letter-spacing="0.01em"><tspan x="0" y="0">{this.props.actuators.rv02.value}</tspan></text>
    </g> : 
      <g id="ON_OFF-4" text-anchor="middle" data-name="ON / OFF" transform="translate(612 182.5)">
      <g id="RV02BG-2" fill={this.props.actuators.rv02.status} >
        <path d="M 43 25.5 L 5 25.5 C 2.51869010925293 25.5 0.5 23.48130989074707 0.5 21 L 0.5 5 C 0.5 2.51869010925293 2.51869010925293 0.5 5 0.5 L 43 0.5 C 45.4813117980957 0.5 47.5 2.51869010925293 47.5 5 L 47.5 21 C 47.5 23.48130989074707 45.4813117980957 25.5 43 25.5 Z" stroke="none"/>
        <path d="M 5 1 C 2.794391632080078 1 1 2.794389724731445 1 5 L 1 21 C 1 23.20561027526855 2.794391632080078 25 5 25 L 43 25 C 45.20560836791992 25 47 23.20561027526855 47 21 L 47 5 C 47 2.794389724731445 45.20560836791992 1 43 1 L 5 1 M 5 0 L 43 0 C 45.76142120361328 0 48 2.238580703735352 48 5 L 48 21 C 48 23.76141929626465 45.76142120361328 26 43 26 L 5 26 C 2.238571166992188 26 0 23.76141929626465 0 21 L 0 5 C 0 2.238580703735352 2.238571166992188 0 5 0 Z" stroke="none" fill="#d8d2d2"/>
      </g>
      <text id="RV02" transform="translate(22 18)" fill={this.props.actuators.rv02.textStatus} font-size="14" font-family="Roboto-Bold, Roboto" font-weight="700" letter-spacing="0.01em"><tspan x="0" y="0">{this.props.actuators.rv02.value}</tspan></text>
      </g>
    }

    <text id="FL2" transform="translate(745 246)" fill="#242323" font-size="12" font-family="Roboto-Bold, Roboto" font-weight="700" letter-spacing="0.01em"><tspan x="-10.099" y="0">FL2</tspan></text>
    <text id="XV-2-2" data-name="XV-2" transform="translate(669 339)" fill="#242323" font-size="12" font-family="Roboto-Bold, Roboto" font-weight="700" letter-spacing="0.01em"><tspan x="0" y="0">XV-2</tspan></text>
    {this.props.mode === 2 ? 
     <g id="ON_OFF-5" text-anchor="middle" data-name="ON / OFF" transform="translate(659 251.5)">
     <g transform="matrix(1, 0, 0, 1, -659, -251.5)" filter="url(#XV02BG)">
       <rect id="XV02BG-2" data-name="XV02BG" width="44" height="29" rx="8" transform="translate(659 251.5)" fill={this.props.actuators.xv02.status}/>
     </g>
     <text id="XV02" transform="translate(20 21)" fill={this.props.actuators.xv02.textStatus} font-size="18" font-family="Roboto-Bold, Roboto" font-weight="700" letter-spacing="0.01em"><tspan x="0" y="0">{this.props.actuators.xv02.value}</tspan></text>
    </g> : 
     <g id="ON_OFF-5" text-anchor="middle" data-name="ON / OFF" transform="translate(659 251.5)">
     <g id="XV02BG-2" fill={this.props.actuators.xv02.status}>
        <path d="M 43 25.5 L 5 25.5 C 2.51869010925293 25.5 0.5 23.48130989074707 0.5 21 L 0.5 5 C 0.5 2.51869010925293 2.51869010925293 0.5 5 0.5 L 43 0.5 C 45.4813117980957 0.5 47.5 2.51869010925293 47.5 5 L 47.5 21 C 47.5 23.48130989074707 45.4813117980957 25.5 43 25.5 Z" stroke="none"/>
        <path d="M 5 1 C 2.794391632080078 1 1 2.794389724731445 1 5 L 1 21 C 1 23.20561027526855 2.794391632080078 25 5 25 L 43 25 C 45.20560836791992 25 47 23.20561027526855 47 21 L 47 5 C 47 2.794389724731445 45.20560836791992 1 43 1 L 5 1 M 5 0 L 43 0 C 45.76142120361328 0 48 2.238580703735352 48 5 L 48 21 C 48 23.76141929626465 45.76142120361328 26 43 26 L 5 26 C 2.238571166992188 26 0 23.76141929626465 0 21 L 0 5 C 0 2.238580703735352 2.238571166992188 0 5 0 Z" stroke="none" fill="#d8d2d2"/>
      </g>
      <text id="XV0201" transform="translate(22 18)" fill={this.props.actuators.xv02.textStatus} font-size="14" font-family="Roboto-Bold, Roboto" font-weight="700" letter-spacing="0.01em"><tspan x="0" y="0">{this.props.actuators.xv02.value}</tspan></text>
      </g>
    }

    <g id="flowKitConnector-7" data-name="flowKitConnector" transform="translate(374.914 111.333)">
      <path id="line-7" data-name="line" d="M338.362,131.667h0v72.312h-12.7" transform="translate(0)" fill="none" stroke="#242323" stroke-width="1"/>
      <path id="leftEdge-7" data-name="leftEdge" transform="translate(338.362 131.667)" fill="none" stroke="rgba(0,0,0,0)" stroke-width="1"/>
      <path id="rightEdge-7" data-name="rightEdge" transform="translate(325.667 203.978)" fill="none" stroke="rgba(0,0,0,0)" stroke-width="1"/>
    </g>
    <g id="Group_8" data-name="Group 8" transform="translate(30)">
      <g id="Rectangle_9" data-name="Rectangle 9" transform="translate(491 358)" fill="#fff" stroke="#242323" stroke-width="1">
        <rect width="152" height="40" stroke="none"/>
        <rect x="0.5" y="0.5" width="151" height="39" fill="none"/>
      </g>
      <text id="X_MEMBRANES" data-name="X MEMBRANES" transform="translate(509 382)" fill="#242323" font-size="11" font-family="Roboto-Bold, Roboto" font-weight="700" letter-spacing="0.01em"><tspan x="0" y="0">X MEMBRANES</tspan></text>
    </g>
    <g id="Group_7" data-name="Group 7" transform="translate(422 163)">
      <g id="sensor-8" data-name="sensor" transform="translate(52 195)">
        <g id="Ellipse_1-8" data-name="Ellipse 1" transform="translate(1.5 2)" fill="#fff" stroke="#242323" stroke-width="1">
          <circle cx="18.5" cy="18.5" r="18.5" stroke="none"/>
          <circle cx="18.5" cy="18.5" r="18" fill="none"/>
        </g>
        <line id="Line_1-8" data-name="Line 1" x2="39" transform="translate(0.5 20)" fill="none" stroke="#242323" stroke-width="1"/>
        <g id="Rectangle_1-8" data-name="Rectangle 1" fill="none" stroke="#242323" stroke-width="1">
          <rect width="40" height="40" stroke="none"/>
          <rect x="0.5" y="0.5" width="39" height="39" fill="none"/>
        </g>
      </g>
      <g id="Group_44" data-name="Group 44">
        <text id="PS-4" data-name="PS" transform="translate(65 212)" fill="#242323" font-size="12" font-family="Roboto-Bold, Roboto" font-weight="700" letter-spacing="0.01em"><tspan x="0" y="0">PS</tspan></text>
        <text id="_3-2" data-name="3" transform="translate(72 228)" fill="#242323" font-size="12" font-family="Roboto-Bold, Roboto" font-weight="700" letter-spacing="0.01em"><tspan x="-3.442" y="0">3</tspan></text>
      </g>
       </g>
    <g id="Ellipse_4" data-name="Ellipse 4" transform="translate(656 376)" fill="#fff" stroke="#121212" stroke-width="1">
      <circle cx="2.5" cy="2.5" r="2.5" stroke="none"/>
      <circle cx="2.5" cy="2.5" r="2" fill="none"/>
    </g>
    <g id="Ellipse_5" data-name="Ellipse 5" transform="translate(526 376)" fill="#fff" stroke="#171616" stroke-width="1">
      <circle cx="2.5" cy="2.5" r="2.5" stroke="none"/>
      <circle cx="2.5" cy="2.5" r="2" fill="none"/>
    </g>
    <g id="flowKitConnector-8" data-name="flowKitConnector" transform="translate(759.5 583.5) rotate(180)">
      <path id="line-8" data-name="line" d="M107.5,205.5h28" transform="translate(0 -0.5)" fill="none" stroke="#0a0a0a" stroke-width="1"/>
      <path id="leftEdge-8" data-name="leftEdge" transform="translate(107.5 205)" fill="none" stroke="rgba(0,0,0,0)" stroke-width="1"/>
      <path id="rightEdge-8" data-name="rightEdge" d="M-7.5-4h0L0-.5-7.5,3Z" transform="translate(135.5 205.5)" fill="#242323" stroke="#242323" stroke-width="1"/>
    </g>
    <g id="flowKitConnector-9" data-name="flowKitConnector" transform="translate(243.389 652.111) rotate(-90)">
      <path id="line-9" data-name="line" d="M241.111,193.111h0v222.2h30" fill="none" stroke="#242323" stroke-width="1"/>
      <path id="leftEdge-9" data-name="leftEdge" transform="translate(241.111 193.111)" fill="none" stroke="rgba(0,0,0,0)" stroke-width="1"/>
      <path id="rightEdge-9" data-name="rightEdge" d="M-7.5-4h0L0,0-7.5,4Z" transform="translate(271.111 415.307)" fill="#666" stroke="#666" stroke-width="1"/>
    </g>
    <g id="flowKitConnector-10" data-name="flowKitConnector" transform="translate(735.781 208) rotate(90)">
      <path id="line-10" data-name="line" d="M107.5,205.5H170" transform="translate(0 1.141)" fill="none" stroke="#242323" stroke-width="1"/>
      <path id="leftEdge-10" data-name="leftEdge" d="M7.5-4h0L0,1.141,7.5,6.281Z" transform="translate(107.5 205.5)" fill="#666" stroke="#666" stroke-width="1"/>
      <path id="rightEdge-10" data-name="rightEdge" transform="translate(170 206.641)" fill="none" stroke="rgba(0,0,0,0)" stroke-width="1"/>
    </g>
    <line id="Line_17" data-name="Line 17" x1="134" transform="translate(530.5 315.5)" fill="none" stroke="#242323" stroke-width="1"/>
    <line id="Line_18" data-name="Line 18" y2="12" transform="translate(494.5 398.5)" fill="none" stroke="#242323" stroke-width="1"/>
    <g id="flowKitConnector-11" data-name="flowKitConnector" transform="translate(267.889 191.389)">
      <path id="line-11" data-name="line" d="M393.111,187.111h23.622v84.5h76.878" transform="translate(0)" fill="none" stroke="#242323" stroke-width="1"/>
      <path id="leftEdge-11" data-name="leftEdge" transform="translate(393.111 187.111)" fill="none" stroke="rgba(0,0,0,0)" stroke-width="1"/>
      <path id="rightEdge-11" data-name="rightEdge" d="M-7.5-4h0L0,0-7.5,4Z" transform="translate(493.611 271.611)" fill="#666" stroke="#666" stroke-width="1"/>
    </g>
    <line id="Line_19" data-name="Line 19" y2="10" transform="translate(719 453)" fill="none" stroke="#9b9a9a" stroke-width="1"/>
    <g id="Group_38" data-name="Group 38">
      <text id="ECT-2" data-name="ECT" transform="translate(708 430)" fill="#242323" font-size="12" font-family="Roboto-Bold, Roboto" font-weight="700" letter-spacing="0.01em"><tspan x="0" y="0">ECT</tspan></text>
      <text id="_1-2" data-name="1" transform="translate(719 446)" fill="#242323" font-size="12" font-family="Roboto-Bold, Roboto" font-weight="700" letter-spacing="0.01em"><tspan x="-3.442" y="0">1</tspan></text>
    </g>

 <g id="Reading-label-3" data-name="Reading-label" text-anchor="middle" transform="translate(695 385)">
      <g id="ECT01-1BG" fill={this.getBackgroundColor(this.props.config.c.c1, this.props.input.ect01.c.value)}>
        <path d="M 43 25.5 L 5 25.5 C 2.51869010925293 25.5 0.5 23.48130989074707 0.5 21 L 0.5 5 C 0.5 2.51869010925293 2.51869010925293 0.5 5 0.5 L 43 0.5 C 45.4813117980957 0.5 47.5 2.51869010925293 47.5 5 L 47.5 21 C 47.5 23.48130989074707 45.4813117980957 25.5 43 25.5 Z" stroke="none"/>
        <path d="M 5 1 C 2.794391632080078 1 1 2.794389724731445 1 5 L 1 21 C 1 23.20561027526855 2.794391632080078 25 5 25 L 43 25 C 45.20560836791992 25 47 23.20561027526855 47 21 L 47 5 C 47 2.794389724731445 45.20560836791992 1 43 1 L 5 1 M 5 0 L 43 0 C 45.76142120361328 0 48 2.238580703735352 48 5 L 48 21 C 48 23.76141929626465 45.76142120361328 26 43 26 L 5 26 C 2.238571166992188 26 0 23.76141929626465 0 21 L 0 5 C 0 2.238580703735352 2.238571166992188 0 5 0 Z" stroke="none" fill="#d8d2d2"/>
      </g>
      <text id="ECT01-1" transform="translate(22 17)" fill="#0a0909" font-size="14" font-family="Roboto-Bold, Roboto" font-weight="700" letter-spacing="0.01em"><tspan x="0" y="0">{this.props.input.ect01.c.value}</tspan></text>
    </g>
    <g id="Reading-label-4" data-name="Reading-label" text-anchor="middle" transform="translate(695 356.5)">
      <g id="ECT01-2BG" fill={this.props.input.ect01.t.status}>
        <path d="M 43 25.5 L 5 25.5 C 2.51869010925293 25.5 0.5 23.48130989074707 0.5 21 L 0.5 5 C 0.5 2.51869010925293 2.51869010925293 0.5 5 0.5 L 43 0.5 C 45.4813117980957 0.5 47.5 2.51869010925293 47.5 5 L 47.5 21 C 47.5 23.48130989074707 45.4813117980957 25.5 43 25.5 Z" stroke="none"/>
        <path d="M 5 1 C 2.794391632080078 1 1 2.794389724731445 1 5 L 1 21 C 1 23.20561027526855 2.794391632080078 25 5 25 L 43 25 C 45.20560836791992 25 47 23.20561027526855 47 21 L 47 5 C 47 2.794389724731445 45.20560836791992 1 43 1 L 5 1 M 5 0 L 43 0 C 45.76142120361328 0 48 2.238580703735352 48 5 L 48 21 C 48 23.76141929626465 45.76142120361328 26 43 26 L 5 26 C 2.238571166992188 26 0 23.76141929626465 0 21 L 0 5 C 0 2.238580703735352 2.238571166992188 0 5 0 Z" stroke="none" fill="#d8d2d2"/>
      </g>
      <text id="ECT01-2" transform="translate(22 17)" fill="#0a0909" font-size="14" font-family="Roboto-Bold, Roboto" font-weight="700" letter-spacing="0.01em"><tspan x="0" y="0">{this.props.input.ect01.t.value}</tspan></text>
    </g>
    <text id="FL3" transform="translate(779.332 466.5)" fill="#242323" font-size="12" font-family="Roboto-Bold, Roboto" font-weight="700" letter-spacing="0.01em"><tspan x="-10.099" y="0">FL3</tspan></text>
     <g id="Reading-label-5" data-name="Reading-label" text-anchor="middle" transform="translate(327 167)">
      <g id="PS02BG" fill={this.getBackgroundColor(this.props.config.p.p2, this.props.input.ps02.value)}>
        <path d="M 42.14633941650391 25.5 L 5.853658199310303 25.5 C 2.901638031005859 25.5 0.4999980926513672 23.48130989074707 0.4999980926513672 21 L 0.4999980926513672 5 C 0.4999980926513672 2.51869010925293 2.901638031005859 0.5 5.853658199310303 0.5 L 42.14633941650391 0.5 C 45.09835815429688 0.5 47.5 2.51869010925293 47.5 5 L 47.5 21 C 47.5 23.48130989074707 45.09835815429688 25.5 42.14633941650391 25.5 Z" stroke="none"/>
        <path d="M 5.853656768798828 1 C 3.177337646484375 1 0.9999961853027344 2.794389724731445 0.9999961853027344 5 L 0.9999961853027344 21 C 0.9999961853027344 23.20561027526855 3.177337646484375 25 5.853656768798828 25 L 42.14633941650391 25 C 44.82265853881836 25 47 23.20561027526855 47 21 L 47 5 C 47 2.794389724731445 44.82265853881836 1 42.14633941650391 1 L 5.853656768798828 1 M 5.853656768798828 0 L 42.14633941650391 0 C 45.37922668457031 0 48 2.238580703735352 48 5 L 48 21 C 48 23.76141929626465 45.37922668457031 26 42.14633941650391 26 L 5.853656768798828 26 C 2.620769500732422 26 -3.814697265625e-06 23.76141929626465 -3.814697265625e-06 21 L -3.814697265625e-06 5 C -3.814697265625e-06 2.238580703735352 2.620769500732422 0 5.853656768798828 0 Z" stroke="none" fill="#d8d2d2"/>
      </g>
      <text id="PS02" transform="translate(22 18)" fill="#0a0909" font-size="14" font-family="Roboto-Bold, Roboto" font-weight="700" letter-spacing="0.01em"><tspan x="0" y="0">{this.props.input.ps02.value}</tspan></text>
    </g>
    
    <g id="Group_47" data-name="Group 47">
    <g id="FL-4" data-name="FL" transform="translate(472.276 262.5) rotate(180)">
        <g id="Group_3-4" data-name="Group 3">
          <g id="Group_1-4" data-name="Group 1" transform="translate(290 -146) rotate(90)">
            <g id="Polygon_5-4" data-name="Polygon 5" transform="translate(146.5 233)" fill="#fff">
              <path d="M 36.17016220092773 34.5 L 0.8298364281654358 34.5 L 18.5 1.069960832595825 L 36.17016220092773 34.5 Z" stroke="none"/>
              <path d="M 18.5 2.139911651611328 L 1.65966796875 34 L 35.34033203125 34 L 18.5 2.139911651611328 M 18.5 0 L 37 35 L 0 35 L 18.5 0 Z" stroke="none" fill="#242323"/>
            </g>
            <rect id="Rectangle_3-4" data-name="Rectangle 3" width="49" height="7" transform="translate(146 265)" fill="#fff"/>
            <g id="Ellipse_2-4" data-name="Ellipse 2" transform="translate(147 254)" fill="#fff" stroke="#242323" stroke-width="1">
              <circle cx="18" cy="18" r="18" stroke="none"/>
              <circle cx="18" cy="18" r="17.5" fill="none"/>
            </g>
          </g>
        </g>
      </g>
      <text id="FL1" transform="translate(454.776 247)" fill="#242323" font-size="12" font-family="Roboto-Bold, Roboto" font-weight="700" letter-spacing="0.01em"><tspan x="-10.099" y="0">FL1</tspan></text>
      <g id="Reading-label-7" data-name="Reading-label" text-anchor="middle" transform="translate(429.336 196)">
      <g id="FL01-1BG" text-anchor="middle" fill={this.props.input.fl01.status}>
        <path d="M 43 25.5 L 5 25.5 C 2.51869010925293 25.5 0.5 23.48130989074707 0.5 21 L 0.5 5 C 0.5 2.51869010925293 2.51869010925293 0.5 5 0.5 L 43 0.5 C 45.4813117980957 0.5 47.5 2.51869010925293 47.5 5 L 47.5 21 C 47.5 23.48130989074707 45.4813117980957 25.5 43 25.5 Z" stroke="none"/>
        <path d="M 5 1 C 2.794391632080078 1 1 2.794389724731445 1 5 L 1 21 C 1 23.20561027526855 2.794391632080078 25 5 25 L 43 25 C 45.20560836791992 25 47 23.20561027526855 47 21 L 47 5 C 47 2.794389724731445 45.20560836791992 1 43 1 L 5 1 M 5 0 L 43 0 C 45.76142120361328 0 48 2.238580703735352 48 5 L 48 21 C 48 23.76141929626465 45.76142120361328 26 43 26 L 5 26 C 2.238571166992188 26 0 23.76141929626465 0 21 L 0 5 C 0 2.238580703735352 2.238571166992188 0 5 0 Z" stroke="none" fill="#d8d2d2"/>
      </g>
      <text id="FL01-1" transform="translate(22 17)" fill="#0a0909" font-size="14" font-family="Roboto-Bold, Roboto" font-weight="700" letter-spacing="0.01em"><tspan x="0" y="0">{this.props.input.fl01.cvalue}</tspan></text>
    </g>
    </g>

    <g id="Reading-label-10" data-name="Reading-label" text-anchor="middle" transform="translate(724 196)">
      <g id="FL02-1BG" fill={this.props.input.fl02.status}>
        <path d="M 43 25.5 L 5 25.5 C 2.51869010925293 25.5 0.5 23.48130989074707 0.5 21 L 0.5 5 C 0.5 2.51869010925293 2.51869010925293 0.5 5 0.5 L 43 0.5 C 45.4813117980957 0.5 47.5 2.51869010925293 47.5 5 L 47.5 21 C 47.5 23.48130989074707 45.4813117980957 25.5 43 25.5 Z" stroke="none"/>
        <path d="M 5 1 C 2.794391632080078 1 1 2.794389724731445 1 5 L 1 21 C 1 23.20561027526855 2.794391632080078 25 5 25 L 43 25 C 45.20560836791992 25 47 23.20561027526855 47 21 L 47 5 C 47 2.794389724731445 45.20560836791992 1 43 1 L 5 1 M 5 0 L 43 0 C 45.76142120361328 0 48 2.238580703735352 48 5 L 48 21 C 48 23.76141929626465 45.76142120361328 26 43 26 L 5 26 C 2.238571166992188 26 0 23.76141929626465 0 21 L 0 5 C 0 2.238580703735352 2.238571166992188 0 5 0 Z" stroke="none" fill="#d8d2d2"/>
      </g>
      <text id="FL02-1" transform="translate(22 17)" fill="#0a0909" font-size="14" font-family="Roboto-Bold, Roboto" font-weight="700" letter-spacing="0.01em"><tspan x="0" y="0">{this.props.input.fl02.cvalue}</tspan></text>
    </g>
    <g id="Reading-label-14" data-name="Reading-label" text-anchor="middle" transform="translate(756 414)">
      <g id="FL03-1BG" fill={this.props.input.fl03.status}>
        <path d="M 43 25.5 L 5 25.5 C 2.51869010925293 25.5 0.5 23.48130989074707 0.5 21 L 0.5 5 C 0.5 2.51869010925293 2.51869010925293 0.5 5 0.5 L 43 0.5 C 45.4813117980957 0.5 47.5 2.51869010925293 47.5 5 L 47.5 21 C 47.5 23.48130989074707 45.4813117980957 25.5 43 25.5 Z" stroke="none"/>
        <path d="M 5 1 C 2.794391632080078 1 1 2.794389724731445 1 5 L 1 21 C 1 23.20561027526855 2.794391632080078 25 5 25 L 43 25 C 45.20560836791992 25 47 23.20561027526855 47 21 L 47 5 C 47 2.794389724731445 45.20560836791992 1 43 1 L 5 1 M 5 0 L 43 0 C 45.76142120361328 0 48 2.238580703735352 48 5 L 48 21 C 48 23.76141929626465 45.76142120361328 26 43 26 L 5 26 C 2.238571166992188 26 0 23.76141929626465 0 21 L 0 5 C 0 2.238580703735352 2.238571166992188 0 5 0 Z" stroke="none" fill="#d8d2d2"/>
      </g>
      <text id="FL03-1" transform="translate(22 17)" fill="#0a0909" font-size="14" font-family="Roboto-Bold, Roboto" font-weight="700" letter-spacing="0.01em"><tspan x="0" y="0">{this.props.input.fl03.cvalue}</tspan></text>
    </g>
    <g id="Reading-label-16" data-name="Reading-label" text-anchor="middle" transform="translate(470 329)">
      <g id="PS03BG" fill={this.getBackgroundColor(this.props.config.p.p3, this.props.input.ps03.value)}>
        <path d="M 43 25.5 L 5 25.5 C 2.51869010925293 25.5 0.5 23.48130989074707 0.5 21 L 0.5 5 C 0.5 2.51869010925293 2.51869010925293 0.5 5 0.5 L 43 0.5 C 45.4813117980957 0.5 47.5 2.51869010925293 47.5 5 L 47.5 21 C 47.5 23.48130989074707 45.4813117980957 25.5 43 25.5 Z" stroke="none"/>
        <path d="M 5 1 C 2.794391632080078 1 1 2.794389724731445 1 5 L 1 21 C 1 23.20561027526855 2.794391632080078 25 5 25 L 43 25 C 45.20560836791992 25 47 23.20561027526855 47 21 L 47 5 C 47 2.794389724731445 45.20560836791992 1 43 1 L 5 1 M 5 0 L 43 0 C 45.76142120361328 0 48 2.238580703735352 48 5 L 48 21 C 48 23.76141929626465 45.76142120361328 26 43 26 L 5 26 C 2.238571166992188 26 0 23.76141929626465 0 21 L 0 5 C 0 2.238580703735352 2.238571166992188 0 5 0 Z" stroke="none" fill="#d8d2d2"/>
      </g>
      <text id="PS03" transform="translate(22 18)" fill="#0a0909" font-size="14" font-family="Roboto-Bold, Roboto" font-weight="700" letter-spacing="0.01em"><tspan x="0" y="0">{this.props.input.ps03.value}</tspan></text>
    </g>

    <g id="flowKitConnector-13" data-name="flowKitConnector" transform="translate(655.165 729.667) rotate(-90)">
      <path id="line-13" data-name="line" d="M268.667,163.667h0v68.668h-18" fill="none" stroke="#242323" stroke-width="1"/>
      <path id="leftEdge-13" data-name="leftEdge" transform="translate(268.667 163.667)" fill="none" stroke="rgba(0,0,0,0)" stroke-width="1"/>
      <path id="rightEdge-13" data-name="rightEdge" d="M0-4H0L-7.5,0,0,4Z" transform="translate(258.167 232.335)" fill="#666" stroke="#666" stroke-width="1"/>
    </g>
    <g id="flowKitConnector-14" data-name="flowKitConnector" transform="translate(778.5 273.5)">
      <path id="line-14" data-name="line" d="M107.5,205.5h60" fill="none" stroke="#242323" stroke-width="1"/>
      <path id="leftEdge-14" data-name="leftEdge" transform="translate(107.5 205.5)" fill="none" stroke="rgba(0,0,0,0)" stroke-width="1"/>
      <path id="rightEdge-14" data-name="rightEdge" d="M-7.5-4h0L0,0-7.5,4Z" transform="translate(167.5 205.5)" fill="#666" stroke="#666" stroke-width="1"/>
    </g>
    <g id="flowKitConnector-15" data-name="flowKitConnector" transform="translate(620.333 571.619) rotate(-90)">
      <path id="line-15" data-name="line" d="M329.119,163.667h0v56H250.667" transform="translate(0 0)" fill="none" stroke="#242323" stroke-width="1"/>
      <path id="leftEdge-15" data-name="leftEdge" transform="translate(329.119 163.667)" fill="none" stroke="rgba(0,0,0,0)" stroke-width="1"/>
      <path id="rightEdge-15" data-name="rightEdge" d="M0-4H0L-7.5,0,0,4Z" transform="translate(258.167 219.667)" fill="#666" stroke="#666" stroke-width="1"/>
    </g>
    <g id="Group_12" data-name="Group 12" transform="translate(12)">
      <line id="Line_22" data-name="Line 22" y2="33" transform="translate(811.583 316.235) rotate(-30)" fill="none" stroke="#242323" stroke-width="1"/>
      <line id="Line_23" data-name="Line 23" y2="33" transform="translate(844.583 316.044) rotate(30)" fill="none" stroke="#242323" stroke-width="1"/>
    </g>
    <text id="CONCENT." transform="translate(785 237)" fill="#242323" font-size="12" font-family="Roboto-Bold, Roboto" font-weight="700" letter-spacing="0.01em"><tspan x="0" y="0">CONCENT.</tspan></text>
    <text id="PERMEATE" transform="translate(822 455)" fill="#242323" font-size="12" font-family="Roboto-Bold, Roboto" font-weight="700" letter-spacing="0.01em"><tspan x="0" y="0">PERMEATE</tspan></text>
    <text id="P-1" transform="translate(427 431)" fill="#242323" font-size="12" font-family="Roboto-Bold, Roboto" font-weight="700" letter-spacing="0.01em"><tspan x="0" y="0">P-1</tspan></text>
    {this.props.mode === 2 ? 
    <g id="ON_OFF-6" data-name="ON / OFF" text-anchor="middle" transform="translate(168 179)">
      <g transform="matrix(1, 0, 0, 1, -168, -179)" filter="url(#XV01BG)">
        <rect id="XV01BG-2" data-name="XV01BG" width="44" height="29" rx="8" transform="translate(168 179)" fill={this.props.actuators.xv01.status}/>
      </g>
      <text id="XV01" transform="translate(20 21)" fill={this.props.actuators.xv01.textStatus} font-size="18" font-family="Roboto-Bold, Roboto" font-weight="700" letter-spacing="0.01em"><tspan x="0" y="0">{this.props.actuators.xv01.value}</tspan></text>
    </g> : 
    <g id="ON_OFF-6" data-name="ON / OFF" text-anchor="middle" transform="translate(168 179)">
      <g id="XV01BG-2" fill={this.props.actuators.xv01.status}>
        <path d="M 43 25.5 L 5 25.5 C 2.51869010925293 25.5 0.5 23.48130989074707 0.5 21 L 0.5 5 C 0.5 2.51869010925293 2.51869010925293 0.5 5 0.5 L 43 0.5 C 45.4813117980957 0.5 47.5 2.51869010925293 47.5 5 L 47.5 21 C 47.5 23.48130989074707 45.4813117980957 25.5 43 25.5 Z" stroke="none"/>
        <path d="M 5 1 C 2.794391632080078 1 1 2.794389724731445 1 5 L 1 21 C 1 23.20561027526855 2.794391632080078 25 5 25 L 43 25 C 45.20560836791992 25 47 23.20561027526855 47 21 L 47 5 C 47 2.794389724731445 45.20560836791992 1 43 1 L 5 1 M 5 0 L 43 0 C 45.76142120361328 0 48 2.238580703735352 48 5 L 48 21 C 48 23.76141929626465 45.76142120361328 26 43 26 L 5 26 C 2.238571166992188 26 0 23.76141929626465 0 21 L 0 5 C 0 2.238580703735352 2.238571166992188 0 5 0 Z" stroke="none" fill="#d8d2d2"/>
      </g>
      <text id="XV01" transform="translate(22 18)" fill={this.props.actuators.xv01.textStatus} font-size="14" font-family="Roboto-Bold, Roboto" font-weight="700" letter-spacing="0.01em"><tspan x="0" y="0">{this.props.actuators.xv01.value}</tspan></text>
      </g>
    }
    <g id="Reading-label-20" data-name="Reading-label" text-anchor="middle" transform="translate(48 165)">
      <g id="PS01BG" fill={this.getBackgroundColor(this.props.config.p.p1, this.props.input.ps01.value)}>
        <path d="M 43 25.5 L 5 25.5 C 2.51869010925293 25.5 0.5 23.48130989074707 0.5 21 L 0.5 5 C 0.5 2.51869010925293 2.51869010925293 0.5 5 0.5 L 43 0.5 C 45.4813117980957 0.5 47.5 2.51869010925293 47.5 5 L 47.5 21 C 47.5 23.48130989074707 45.4813117980957 25.5 43 25.5 Z" stroke="none"/>
        <path d="M 5 1 C 2.794391632080078 1 1 2.794389724731445 1 5 L 1 21 C 1 23.20561027526855 2.794391632080078 25 5 25 L 43 25 C 45.20560836791992 25 47 23.20561027526855 47 21 L 47 5 C 47 2.794389724731445 45.20560836791992 1 43 1 L 5 1 M 5 0 L 43 0 C 45.76142120361328 0 48 2.238580703735352 48 5 L 48 21 C 48 23.76141929626465 45.76142120361328 26 43 26 L 5 26 C 2.238571166992188 26 0 23.76141929626465 0 21 L 0 5 C 0 2.238580703735352 2.238571166992188 0 5 0 Z" stroke="none" fill="#d8d2d2"/>
      </g>
      <text id="PS01" transform="translate(22 18)" fill="#0a0909" font-size="14" font-family="Roboto-Bold, Roboto" font-weight="700" letter-spacing="0.01em"><tspan x="0" y="0">{this.props.input.ps01.value}</tspan></text>
    </g>
    
    
    {this.props.mode === 2 ? 
    <g id="ON_OFF-7" data-name="ON / OFF" text-anchor="middle" transform="translate(415 375)">
    <g transform="matrix(1, 0, 0, 1, -415, -375)" filter="url(#P01BG)">
      <rect id="P01BG-2" data-name="P01BG" width="44" height="29" rx="8" transform="translate(415 375)" fill={this.props.actuators.p01.status}/>
    </g>
    <text id="P01" transform="translate(20 21)" fill={this.props.actuators.p01.textStatus} font-size="18" font-family="Roboto-Bold, Roboto" font-weight="700" letter-spacing="0.01em"><tspan x="0" y="0">{this.props.actuators.p01.value}</tspan></text>
    </g> : 
    <g id="ON_OFF-7" data-name="ON / OFF" text-anchor="middle" transform="translate(415 375)">
      <g id="P01BG-2" fill={this.props.actuators.p01.status}>
        <path d="M 43 25.5 L 5 25.5 C 2.51869010925293 25.5 0.5 23.48130989074707 0.5 21 L 0.5 5 C 0.5 2.51869010925293 2.51869010925293 0.5 5 0.5 L 43 0.5 C 45.4813117980957 0.5 47.5 2.51869010925293 47.5 5 L 47.5 21 C 47.5 23.48130989074707 45.4813117980957 25.5 43 25.5 Z" stroke="none"/>
        <path d="M 5 1 C 2.794391632080078 1 1 2.794389724731445 1 5 L 1 21 C 1 23.20561027526855 2.794391632080078 25 5 25 L 43 25 C 45.20560836791992 25 47 23.20561027526855 47 21 L 47 5 C 47 2.794389724731445 45.20560836791992 1 43 1 L 5 1 M 5 0 L 43 0 C 45.76142120361328 0 48 2.238580703735352 48 5 L 48 21 C 48 23.76141929626465 45.76142120361328 26 43 26 L 5 26 C 2.238571166992188 26 0 23.76141929626465 0 21 L 0 5 C 0 2.238580703735352 2.238571166992188 0 5 0 Z" stroke="none" fill="#d8d2d2"/>
      </g>
      <text id="P01" transform="translate(22 18)" fill={this.props.actuators.p01.textStatus} font-size="14" font-family="Roboto-Bold, Roboto" font-weight="700" letter-spacing="0.01em"><tspan x="0" y="0">{this.props.actuators.p01.value}</tspan></text>
      </g>
    }


    
  </g> 
</svg>

    );
  }
}