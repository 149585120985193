import React, { useState, memo, useEffect} from 'react';
import { useTranslation } from "react-i18next";
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Alert from 'react-bootstrap/Alert'
import  store  from '../store/index'
import Button from 'react-bootstrap/Button'
import RoundButton from '../components/RoundButton';
import config from 'react-global-configuration';


const SettingsFooter = memo(props => {
  const { t } = useTranslation('common')
  const editConfig = useSelector(state => state.Device.config)
  const selectedDevice = useSelector(state => state.Device.selectedDevice)
  const [show, setShow] = useState(false)
  const [showSuccess, setShowSuccess] = useState(false)

    const sendCommand = (payload) => {
        fetch(config.get('apiUrl')+'admin/command',{
            method: "POST",
            headers: {
                'Content-Type' : 'application/json',
                Authorization: 'Bearer '+ JSON.parse(sessionStorage.getItem('authUser')).token
            },
            body: JSON.stringify({message:payload, device: selectedDevice.deviceId})
        })
        .then(res=>res.json())
        .then((data)=> {
            console.log(data)
            
        })
      }

    const compareConfiguration = (object1, object2) => {
        const keys1 = Object.keys(object1);
        const keys2 = Object.keys(object2);
      
        if (keys1.length !== keys2.length) {
          return false;
        }
      
        for (const key of keys1) {
          const val1 = object1[key];
          const val2 = object2[key];
          const areObjects = isObject(val1) && isObject(val2);
          if (
            areObjects && !compareConfiguration(val1, val2) ||
            !areObjects && val1 !== val2
          ) {
            return false;
          }
        }
      
        return true;
      }
      
    const isObject = (object) => {
        return object != null && typeof object === 'object';
      }

    const saveConfiguration = () => {
       sendCommand({c: editConfig})
       setShowSuccess(true)
        store.dispatch({type:"STORE_CONFIG",payload: JSON.parse(JSON.stringify(editConfig))})

    }

   return (<div>
        <Container className="settings-footer">
          <Row>
              {/* <Col md="4"><Link to={(location) => {
            if (this.compareConfiguration(this.props.config, this.props.editConfig)){
                console.log("vordsed")
                return "/"
            }
            else {
                console.log("tere")
               
            }
            }
            }
            onClick={() => {
                if (!this.compareConfiguration(this.props.config, this.props.editConfig)){
                    this.setState({show:true})
                }

            }
            }
            ><RoundButton title={t('buttons.home')} color="button-secondary float-left" size="size-20 regular-button"/></Link></Col> */}
              <Col>
                  <RoundButton title={t('buttons.save')} size="size-16 btn btn-primary" onClick={saveConfiguration}/>
                  </Col>
          </Row>

      </Container>
      {show ? 
      <div className="alert-background">
      <Alert show={show} variant="warning">
        <Alert.Heading>{t('alerts.unsaved.title')}</Alert.Heading>
        <p>
            {t('alerts.unsaved.description')} 
        </p>
        <hr />
        <div className="d-flex justify-content-end">
          <Button onClick={() => setShow(false)} variant="outline-secondary">
          {t('buttons.cancel')}
          </Button>
          <Link to="/">
          <Button onClick={() => setShow(false)} variant="outline-primary">
          {t('buttons.continue')}
          </Button>
          </Link>
        </div>
      </Alert>
      </div> : '' }
      {showSuccess ? 
        <Alert variant="success" onClose={() => setShowSuccess(false)} dismissible>
        {t('messages.configuration_saved')}
        </Alert> : ''
      }
      </div>
   )
      
  })
  
  export default SettingsFooter

