export const actuators = {
    xv01: {
        value: "N/A",
        status: '#CCC8C8',
        textStatus: '#CCC8C8'
    },
    xv02: {
        value: "N/A",
        status: '#CCC8C8',
        textStatus: '#CCC8C8'
    },
    xv03: {
        value: "N/A",
        status: '#CCC8C8',
        textStatus: '#CCC8C8'
    },
    rv01: {
        value: "N/A",
        status: '#CCC8C8',
        textStatus: '#CCC8C8'
    },
    rv02: {
        value: "N/A",
        status: '#CCC8C8',
        textStatus: '#CCC8C8'
    },
    rv03: {
        value: "N/A",
        status: '#CCC8C8',
        textStatus: '#CCC8C8'
    },
    dp: {
        value: "N/A",
        status: '#CCC8C8',
        textStatus: '#CCC8C8'
    },
    p01: {
        value: "N/A",
        status: '#CCC8C8',
        textStatus: '#CCC8C8'
    }
}