import React, { Component } from 'react';
import { Row, Col, Button } from 'reactstrap';
import { Link } from 'react-router-dom';
import { MDBDataTable } from 'mdbreact';
import config from 'react-global-configuration';
import flatten from 'flat';
import moment from 'moment';
import { connect } from 'react-redux'
import {withTranslation} from "react-i18next";

class Errors extends Component {
  constructor(props) {
    super(props);
    
    let authUser = JSON.parse(sessionStorage.getItem('authUser'));
    this.state = {
      errors: [],
      authUser: authUser
    };
    if (this.props.selectedDevice.value !== undefined){
      this.getErrors();
    }

  }
  static getDerivedStateFromProps(nextProps, prevState){
    if(nextProps.selectedDevice!==prevState.selectedDevice){
      return { selectedDevice: nextProps.someValue};
   }
   else return null;
 }

 componentDidUpdate(prevProps, prevState) {
    if(prevProps.selectedDevice!==this.props.selectedDevice){
      //Perform some operation here
      this.getErrors()
    }
  }
 

  handleRowClick = (id) => {
    console.log(id);
    this.props.history.push('/location/'+id);
  }

  getErrors = () => {
    fetch(config.get('apiUrl')+'admin/errors/'+this.props.selectedDevice.value,{
      headers: {
        Authorization: 'Bearer '+ this.state.authUser.token
      }
    })
      .then(res=>res.json())
      .then((data)=> {
        console.log(data);

        data.map(error => {
          error.timestamp = moment(error.timestamp).format('DD-MM-YYYY HH:mm:ss');
          return null;
        });

        Object.keys(data).forEach(function(key) {
          data[key] = flatten(data[key]);
        });
        
        this.setState({
          errors: data
        });
      });
  }

  render() {
    const data = {
      columns: [
        {
          label: 'Error',
          field: 'errorcode.description',
          sort: 'asc',
          width: 270
        },
        {
          label: 'Sensor',
          field: 'sensor.name',
          sort: 'asc',
          width: 270
        },
        {
          label: 'Value',
          field: 'errorValue',
          sort: 'asc',
          width: 270
        },
        {
          label: 'Timestamp',
          field: 'timestamp',
          sort: 'asc',
          width: 270
        },
        
      ],
      rows: this.state.errors
      
    };

    return (
      <React.Fragment>
        <div className="container-fluid">
          <Row className="align-items-center">
            <Col sm={6}>
              <div className="page-title-box">
                <h4 className="font-size-18">Errors</h4>
                <ol className="breadcrumb mb-0">
                  <li className="breadcrumb-item">
                    <Link to="/#">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item active">Errors</li>
                </ol>
              </div>
            </Col>

          </Row>

          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-body">

                  <MDBDataTable 
                    responsive 
                    bordered 
                    className="rows-clickable"
                    data={data} />
                </div>
              </div>
            </div>
          </div>

        </div>
      </React.Fragment>
    );
  }
}


function mapStateToProps(state){
  return {
    sensors: state.Device.sensors,
    actuators: state.Device.actuators,
    configuration: state.Device.config,
    commandIn: state.Device.commandIn,
    selectedDevice: state.Device.selectedDevice
  }
}

export default withTranslation('common')(connect(
  mapStateToProps
)(Errors))
