
export const sensors =  {
    ps01: {
        value:"N/A",
        status:'#CCC8C8'
    },
    ps02: {
        value:"N/A",
        status:'#CCC8C8'
    },
    ps03: {
        value:"N/A",
        status:'#CCC8C8'
    },
    ps04: {
        value:"N/A",
        status:'#CCC8C8'
    },
    ps05: {
        value:"N/A",
        status:'#CCC8C8'
    },
    ect01: {
        c:{
            value:"N/A",
            status:'#CCC8C8'
        },
        t:{
            value:"N/A",
            status:'#CCC8C8' 
        }
    },
    ect02: {
        c:{
            value:"N/A",
            status:'#CCC8C8'
        },
        t:{
            value:"N/A",
            status:'#CCC8C8' 
        }
    },
    ect03: {
        c:{
            value:"N/A",
            status:'#CCC8C8'
        },
        t:{
            value:"N/A",
            status:'#CCC8C8' 
        }
    },
    ls01: {
        value:"OFF",
        status:'#CCC8C8'
    },
    ls02: {
        value:"OFF",
        status:'#CCC8C8'
    },
    ls03: {
        value:"OFF",
        status:'#CCC8C8'
    },
    ls04: {
        value:"OFF",
        status:'#CCC8C8'
    },
    fl01: {
        cvalue:"N/A",
        tvalue:"N/A",
        status:'#CCC8C8'
    },
    fl02: {
        cvalue:"N/A",
        tvalue:"N/A",
        status:'#CCC8C8'
    },
    fl03: {
        cvalue:"N/A",
        tvalue:"N/A",
        status:'#CCC8C8'
    },
    fl04: {
        cvalue:"N/A",
        tvalue:"N/A",
        status:'#CCC8C8'
    }
}