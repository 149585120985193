import { combineReducers } from "redux";

// Front
import Layout from "./layout/reducer";
import Device from './device/reducer';

// Authentication
import Login from "./auth/login/reducer";
import Account from "./auth/register/reducer";
import ForgetPassword from "./auth/forgetpwd/reducer";

const rootReducer = combineReducers({
  // public
  Layout,
  Login,
  Account,
  ForgetPassword, 
  Device
});

export default rootReducer;
