import React, {useEffect, memo} from 'react'
import { useForm, Controller } from 'react-hook-form'
import { useTranslation } from "react-i18next";
import { useSelector } from 'react-redux';
import { updateConfiguration, updateParameters, updateParameter } from '../../../../helpers/utils'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'


export const GeneralDeviceSettingsForm = memo(props => {
    const { handleSubmit, control, setValue, getValues } = useForm({mode:"onSubmit",reValidateMode:"onSubmit"})
    const { t } = useTranslation('common')
    const editConfig = useSelector(state => state.Device.config)
    const onSubmit = values => {
  
    }

    useEffect(() => { 
        [ 
            { name: 'p5s', value: editConfig.g.p5s },
            { name: 'l3s', value: editConfig.g.l3s },
            { name: 'rp-pl', value: editConfig.g.rp === 0 ? 'pl' : '0' },
            { name: 'rp-pu', value: editConfig.g.rp === 1 ? 'pu' : '0' },
            { name: 'mtm', value: editConfig.g.mtm },
            { name: 'ctm', value: editConfig.g.ctm }
          ].forEach(({ name, value }) => setValue(name, value))
        
      }, [editConfig]);
  
    const handleChange = (value, sensor) => {

        if (value === 'pl'){
            setValue(`${sensor}-pl`,'pl')
            setValue(`${sensor}-pu`,0)
        }
        else {
            setValue(`${sensor}-pl`,0)
            setValue(`${sensor}-pu`,'pu')
        }
        
        var configuration = JSON.parse(JSON.stringify(editConfig.g))
        configuration[sensor] = value === 'pl' ? 0 : 1
        updateConfiguration("g", null, configuration, editConfig)
        
      };


  
    return (
      <form onSubmit={handleSubmit(onSubmit)}>
          <Container>
              <Row>
                <Col md="9" className="parameter-text">{t('general.device_settings.stop_ps05')}</Col>
                <Col md="3">
                    <Controller 
                          render={({ onChange }) => (
                              <Form.Check 
                                  id="p5s"
                                  type="switch"
                                  checked={getValues("p5s") ? true: false}
                                  onClick={(value) => {
                                      console.log(value.target.checked)
                                      updateParameters(editConfig, "g", [{"p5s": value.target.checked ? 1 : 0},{"l3s": value.target.checked ? 0 : 1}])
                                  }}/>
                          )} 
                          name="p5s"
                          control={control}
                      />
                  </Col>
              </Row>
              <Row>
                <Col md="9" className="parameter-text">{t('general.device_settings.stop_ls3ls4')}</Col>
                <Col md="3">
                    <Controller 
                          render={({ onChange }) => (
                              <Form.Check 
                                  id="l3s"
                                  type="switch"
                                  checked={getValues("l3s") ? true: false}
                                  onClick={(value) => {
                                    updateParameters(editConfig, "g",[{"l3s": value.target.checked ? 1 : 0},{"p5s": value.target.checked ? 0 : 1}])
                                  }}/>
                          )} 
                          name="l3s"
                          control={control}
                      />
                  </Col>
              </Row>
              <Row>
                  <Col md="7" className="parameter-text">{t('general.device_settings.rinse_pressure')}</Col>
                  <Col md="2"><Controller 
                          render={({ onChange }) => (
                            <Form.Check type="radio">
                                <Form.Check.Input
                                    className="custom"
                                    value="pl"
                                    type="radio"
                                    title=""
                                    onChange={(e) => handleChange(e.target.value, 'rp')}
                                    checked={getValues('rp-pl') === 'pl'}
                                />
                                <Form.Check.Label onClick={(e) => handleChange('pl', 'rp')}>
                                    {t('general.device_settings.low')}
                                </Form.Check.Label>
                            </Form.Check>
                          )} 
                          name="rp-pl"
                          control={control}
                      /></Col>
                  <Col md="3"><Controller 
                          render={({ onChange }) => (
                            <Form.Check type="radio">
                                <Form.Check.Input
                                    className="custom"
                                    value="pu"
                                    type="radio"
                                    label={t('general.device_settings.high')}
                                    title=""
                                    onChange={(e) => handleChange(e.target.value, 'rp')}
                                    checked={getValues('rp-pu') === 'pu'}
                                />
                                <Form.Check.Label onClick={(e) => handleChange('pu', 'rp')}>
                                    {t('general.device_settings.high')}
                                </Form.Check.Label>
                            </Form.Check>
                          )} 
                          name="rp-pu"
                          control={control}
                      />
                 </Col>
             </Row>
             <Row>
                <Col md="9" className="parameter-text">{t('general.device_settings.max_motor_temp')}</Col>
                <Col md="3">
                <Controller 
                          render={({ onChange }) => (
                            <Form.Control 
                                as="input"
                                className="input-digit-big"
                                id="mtm"
                                onChange={(e) => updateParameter(editConfig, "g", "mtm", e.target.value)}
                                value={getValues("mtm")}
                                />
                          )} 
                          name="mtm"
                          control={control}
                      />
                  </Col>
              </Row>
              <Row>
                <Col md="9" className="parameter-text">{t('general.device_settings.max_controller_temp')}</Col>
                <Col md="3">
                <Controller 
                          render={({ onChange }) => (
                            <Form.Control 
                                as="input"
                                className="input-digit-big"
                                id="ctm"
                                onChange={(e) => updateParameter(editConfig, "g", "ctm", e.target.value)}
                                
                                value={getValues("ctm")}
                                />
                          )} 
                          name="ctm"
                          control={control}
                      />
                  </Col>
              </Row>
            
            </Container>
            
      </form>
    )
  })