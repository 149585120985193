import React, {useState, memo} from 'react';
import { useTranslation } from "react-i18next";
import Tabs from 'react-bootstrap/Tabs'
import Tab from 'react-bootstrap/Tab'
import {GeneralOptionsForm} from './Options'
import { GeneralDeviceSettingsForm } from './DeviceSettings';
import { GeneralDisplaySettingsForm } from './DisplaySettings';
import { GeneralTimeSettingsForm } from './GeneralTimeSettingsForm';


const General = memo(props => {
  const { t } = useTranslation('common')
  const [key, setKey] = useState(props.tabIndex)

  const processTabSelect = (k) => {
    setKey(k)
    props.setTabIndex(k)
  }
    return ( 
            <Tabs defaultActiveKey="0" activeKey={key} id="general" onSelect={(k) => processTabSelect(k)}>
              <Tab eventKey="0" title={t('general.tabs.options')}>
                <GeneralOptionsForm/>
              </Tab>
              <Tab eventKey="1" title={t('general.tabs.device_settings')}>
                  <GeneralDeviceSettingsForm/>
              </Tab>
              <Tab eventKey="2" title={t('general.tabs.display_settings')}>
                  <GeneralDisplaySettingsForm/>
              </Tab>
              <Tab eventKey="3" title={t('general.tabs.time_settings')}>
                  <GeneralTimeSettingsForm showTimeKeyboard={props.showTimeKeyboard}/>
              </Tab>
            </Tabs>
    )
})
  
export default General
