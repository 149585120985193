import React, { useEffect} from 'react';
import { useTranslation } from "react-i18next";
import { updateConfiguration, updateParameters, updateParameter } from '../../../../helpers/utils'
import { useSelector } from 'react-redux';
import { useForm, Controller } from 'react-hook-form'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'


export const PressureSensorI2CAddresses = (props) => {
    const { t } = useTranslation('common')
    const editConfig = useSelector(state => state.Device.config)
    const { handleSubmit, control, setValue, getValues } = useForm({mode:"onSubmit",reValidateMode:"onSubmit"})


    const pressureSensors = [{
      title: 'ps1',
      key: 'p1'
      },
      {title: 'ps2',
      key: 'p2'
      },
      {title: 'ps3',
      key: 'p3'
      },
      {title: 'ps4',
      key: 'p4'
      },
      {title: 'ps5',
      key: 'p5'
      }]

    const onSubmit = () => {

    }

     
    useEffect(() => {
      [ 
          { name: 'p1-i2c', value: editConfig.p.p1.i2c },
          { name: 'p2-i2c', value: editConfig.p.p2.i2c },
          { name: 'p3-i2c', value: editConfig.p.p3.i2c },
          { name: 'p4-i2c', value: editConfig.p.p4.i2c },
          { name: 'p5-i2c', value: editConfig.p.p5.i2c }
        ].forEach(({ name, value }) => setValue(name, value))
      
    }, [editConfig]);

    return (
      <form onSubmit={handleSubmit(onSubmit)}>
          <Container>
              <Row>
                  <Col md="2"></Col>
                  <Col md="3" className="column-title">{t('pressure.sensor_i2c_addresses.address')}</Col>
              </Row>
             
          { pressureSensors.map((sensor, index) => 
              <Row key={index}>
                <Col md="2" className="parameter-text">{sensor.title.toUpperCase()}</Col>
                <Col md="3" className="text-center"><Controller 
                          render={({ onChange }) => (
                            <Form.Control 
                                as="input"
                                className="input-digit-big"
                                id={`${sensor.key}-i2c`} 
                                onChange={(e) => updateParameter(editConfig, "p", `${sensor.key}-i2c`, e.target.value)}
                                value={getValues(`${sensor.key}-i2c`)}
                                />
                          )} 
                          name={`${sensor.key}-i2c`} 
                          control={control}
                      />
                </Col>
              
              </Row>
          )}
              
            </Container>
           
      </form>
      
    )
  }