import React, {useEffect, memo} from 'react';
import { useTranslation } from "react-i18next";
import { updateConfiguration, updateParameters, updateParameter } from '../../../../helpers/utils'
import { useSelector } from 'react-redux';
import { useForm, Controller } from 'react-hook-form'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'

export const SolenoidValve = memo(props => {
    const { handleSubmit, control, setValue, getValues } = useForm({mode:"onSubmit",reValidateMode:"onSubmit"})
    const { t } = useTranslation('common')
    const editConfig = useSelector(state => state.Device.config)
  
    const onSubmit = values => {
  
    }


    useEffect(() => {
        [ 
            { name: 'x2r', value: editConfig.x.x2r },
            { name: 'x2rt', value: editConfig.x.x2rt },
            { name: 'x3m', value: editConfig.x.x3m },
            { name: 'dp', value: editConfig.x.dp },
            { name: 'vno', value: editConfig.x.vno }
          ].forEach(({ name, value }) => setValue(name, value))
        
      }, [editConfig]);
  
  
    return (
      <form onSubmit={handleSubmit(onSubmit)}>
          <Container>
              <Row>
                <Col md="7" className="parameter-text">{t('valve.solenoid_valve.xv02_rinse')}</Col>
                <Col md="2">
                    <Controller 
                          render={({ onChange }) => (
                              <Form.Check 
                                  id="x2r"
                                  type="switch"
                                  checked={getValues("x2r") ? true: false}
                                  onChange={(value) => {
                                      updateParameter(editConfig, "x", "x2r", value.target.checked ? 1 : 0)
                                  }}/>
                          )} 
                          name="x2r"
                          control={control}
                      />
                  </Col>
              </Row>
              <Row>
                <Col md="7" className="parameter-text">{t('valve.solenoid_valve.xv02_rinse_time')}</Col>
                <Col md="2">
                <Controller 
                          render={({ onChange }) => (
                            <Form.Control 
                                as="input"
                                className="input-digit-big"
                                id="x2rt" 
                                onChange={(e) => updateParameter(editConfig, "x", "x2rt", e.target.value)}
                                value={getValues("x2rt")}
                                />
                          )} 
                          name="x2rt"
                          control={control}
                      />
                  </Col>
                  <Col md="3" className="parameter-description">{t('valve.solenoid_valve.sek')}</Col>
              </Row>
              {editConfig.g.o3 ? 
              <Row className="d-none">
                <Col md="7" className="parameter-text">{t('valve.solenoid_valve.use_xv03')}</Col>
                <Col md="2">
                    <Controller 
                          render={({ onChange }) => (
                              <Form.Check 
                                  id="x3m"
                                  type="switch"
                                  checked={getValues("x3m") ? true: false}
                                  onChange={(value) => {
                                      updateParameter(editConfig, "x", "x3m", value.target.checked ? 1 : 0)
                                  }}/>
                          )} 
                          name="x3m"
                          control={control}
                      />
                  </Col>
              </Row> : '' }
              {editConfig.g.o1 ? 
              <Row>
                <Col md="7" className="parameter-text">{t('valve.solenoid_valve.enable_dp')}</Col>
                <Col md="2">
                    <Controller 
                          render={({ onChange }) => (
                              <Form.Check 
                                  id="dp"
                                  type="switch"
                                  checked={getValues("dp") ? true: false}
                                  onChange={(value) => {
                                      updateParameter(editConfig, "x", "dp", value.target.checked ? 1 : 0)
                                  }}/>
                          )} 
                          name="dp"
                          control={control}
                      />
                  </Col>
              </Row> : '' }
              <Row className="d-none">
                <Col md="7" className="parameter-text">{t('valve.solenoid_valve.5v_con')}</Col>
                <Col md="2">
                <Controller 
                          render={({ onChange }) => (
                            <Form.Control 
                                as="input"
                                className="input-digit-big"
                                id="vno" 
                                onChange={(e) => updateParameter(editConfig, "x", "vno", e.target.value)}
                                value={getValues("vno")}
                                />
                          )} 
                          name="vno"
                          control={control}
                      />
                  </Col>
                  <Col md="3" className="parameter-description">{t('valve.solenoid_valve.us')}</Col>
              </Row>
              
            </Container>
  
  
      </form>
    )
  })