import React, {useState, useEffect, memo} from 'react';
import { useForm, Controller } from 'react-hook-form'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'

export const Wifi = memo(props => {
    const { handleSubmit, errors, control, setValue, getValues } = useForm({mode:"onSubmit",reValidateMode:"onSubmit"})

    const securityTypes = ['None','WEP','WPA','WPA2']
    const networks = ['None available']
  
    const onSubmit = values => {
  
    }

    const processValue = (key, value) => {
        setValue(key, value);
        console.log(value)
        var configuration = props.config
        var fieldToUpdate = key.split('-')
        if (!fieldToUpdate[1]) {
          configuration[key] = value
          props.updateConfiguration("o", configuration)
        }
        else {
          configuration[fieldToUpdate[0]][fieldToUpdate[1]] = value
          props.updateConfiguration("o", configuration)
        }
    }

    useEffect(() => {
        if (props.keyboardField !== undefined && props.keyboardField !== ""){
            setValue(props.keyboardField,props.keyboardValue)
        }
      }, [props.keyboardField, props.keyboardValue]);
     
  
    return (
      <form onSubmit={handleSubmit(onSubmit)}>
          <Container>
              <Row>
                <Col md="6" className="parameter-text">{props.t('online.wifi.network')}</Col>
                <Col md="6">
                    <Controller 
                          render={({ onChange }) => (
                            <Form.Control as="select" custom>
                                 { networks.map((type, index) => 
                                    <option value={type}>{type}</option> )}
                            </Form.Control>
                          )} 
                          name="networks"
                          control={control}
                      />
                  </Col>
              </Row>
              <Row>
                <Col md="6" className="parameter-text">{props.t('online.wifi.password')}</Col>
                <Col md="6">
                <Controller 
                          render={({ onChange }) => (
                            <Form.Control 
                                as="input"
                                id="password"
                                type="password"
                                onChange={(e) => {processValue("password",e.target.value)}}
                                value={getValues("password")}
                                />
                          )} 
                          name="password"
                          control={control}
                      />
                  </Col>
              </Row>
              <Row>
                <Col md="6" className="parameter-text">{props.t('online.wifi.security')}</Col>
                <Col md="6">
                    <Controller 
                          render={({ onChange }) => (
                            <Form.Control as="select" custom>
                                 { securityTypes.map((type, index) => 
                                    <option value={type}>{type}</option> )}
                            </Form.Control>
                          )} 
                          name="wifi-sec"
                          control={control}
                      />
                  </Col>
              </Row>
              
              
            </Container>
  
  
      </form>
    )
  })