import React, { useState, useEffect, memo} from 'react';
import Container from 'react-bootstrap/Container'
import { useTranslation } from "react-i18next";
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Card  from 'react-bootstrap/Card'
import { updateParameter, updateParameters, updateDeepParameters } from '../../../helpers/utils'
import SettingsFooter from '../../../components/Footer';
import { Link } from 'react-router-dom';

import DateTime from '../../../components/DateTime';
import General from './general/index';
import Pressure from './pressure/index';
import Conductivity from './conductivity/index';
import NeedleValve from './needlevalve/index';
import Valve from './valve/index';
import Flow from './flow/index';

const Settings = memo(props => {

    const [showDateTime, setShowDateTime] = useState(false)
    const [showKeyboard, setShowKeyboard] = useState(false)
    const [showWifiKeyboard, setShowWifiKeyboard] = useState(false)
    const [showFullKeyboard, setShowFullKeyboard] = useState(false)
    const [keyboardField, setKeyboardField] = useState("")
    const [keyboardValue, setKeyboardValue] = useState("")
    const [keyboardLabel, setKeyboardLabel] = useState("")
    const [keyboardInputMask, setKeyboardInputMask] = useState("")
    const [selectedSubmenu, setSelectedSubmenu] = useState(props.match.params.id)
    const [keyboardEditConfig, setKeyboardEditConfig] = useState("")
    const [keyboardRoot, setKeyboardRoot] = useState("")
    const [keyboardRoot2, setKeyboardRoot2] = useState("")
    const [tabIndex, setTabIndex] = useState("0")
    const [pageTitle, setPageTitle] = useState("")
    const { t } = useTranslation('common')


    const keyboardCallback = (field, label, value, editConfig, root) => {
        setShowKeyboard(true)
        setKeyboardField(field)
        setKeyboardValue(value)
        setKeyboardLabel(label)
        setKeyboardEditConfig(editConfig)
        setKeyboardRoot(root)
    }

    const timeKeyboardCallback = (field, label, value, editConfig, root) => {
        setShowDateTime(true)
        setKeyboardField(field)
        setKeyboardValue(value)
        setKeyboardLabel(label)
        setKeyboardEditConfig(editConfig)
        setKeyboardRoot(root)
    }

    const wifiKeyboardCallback = (field, label, value, editConfig, root1, root2) => {
        setShowWifiKeyboard(true)
        setKeyboardField(field)
        setKeyboardValue(value)
        setKeyboardLabel(label)
        setKeyboardEditConfig(editConfig)
        setKeyboardRoot(root1)
        setKeyboardRoot2(root2)
    }


    const hideKeyboard = () => {
      setShowKeyboard(false)
    }

    const hideWifiKeyboard = () => {
      setShowWifiKeyboard(false)
    }

    const storeTabIndex = (index) => {
        setTabIndex(index)
    }

    const hideTimeKeyboard = () => {
      setShowDateTime(false)
    }

    const enterKeyboardValue = (field, value) => {

        updateParameter(keyboardEditConfig, keyboardRoot, field, value)
        setShowKeyboard(false)
        setShowDateTime(false)
    }

    const enterTimeKeyboardValue = (field, value) => {
        updateParameters(keyboardEditConfig, keyboardRoot, [{[field]: value}, {"tc":1}])
        setShowKeyboard(false)
        setShowDateTime(false)
    }

    const enterWifiKeyboardValue = (field, value) => {
        updateDeepParameters(keyboardEditConfig, keyboardRoot, keyboardRoot2, [{[field]: value}, {"fwc":1}])
        setShowKeyboard(false)
        setShowWifiKeyboard(false)
        setShowDateTime(false)
    }

    useEffect(() => {
        setSelectedSubmenu(props.match.params.id)
        setTabIndex(0)
    }, [props.match.params.id])


    const SubPage = () => {
        switch (selectedSubmenu){
            case "general": 
                setPageTitle(t('menu.general'))
                return <General tabIndex={tabIndex} setTabIndex={storeTabIndex} showTimeKeyboard={timeKeyboardCallback}/>
            case "pressure": 
                setPageTitle(t('menu.pressure'))
                return <Pressure tabIndex={tabIndex} setTabIndex={storeTabIndex} />
            case "conductivity": 
                setPageTitle(t('menu.conductivity'))
                return <Conductivity tabIndex={tabIndex} setTabIndex={storeTabIndex} />
            case "valve": 
                setPageTitle(t('menu.valve5v'))
                return <Valve tabIndex={tabIndex} setTabIndex={storeTabIndex} />
            case "needlevalve": 
                setPageTitle(t('menu.needle_valve'))
                return <NeedleValve tabIndex={tabIndex} setTabIndex={storeTabIndex} />
            case "flow": 
                setPageTitle(t('menu.flow'))
                return <Flow tabIndex={tabIndex} setTabIndex={storeTabIndex} />
            default: 
                setPageTitle(t('menu.general'))
                return <General tabIndex={tabIndex} setTabIndex={storeTabIndex} showTimeKeyboard={timeKeyboardCallback}/>
        }
    }

    return (<span><Container className="settings-container" fluid> 
        <Col className="page-container">
        <Row className="align-items-center">
        <Col sm={6}>
          <div className="page-title-box">
            <h4 className="font-size-18">{pageTitle}</h4>
            <ol className="breadcrumb mb-0">
              <li className="breadcrumb-item">
                <Link to="/#">{t('web.dashboard')}</Link>
              </li>
              <li className="breadcrumb-item">
              {t('web.settings')}
              </li>
              <li className="breadcrumb-item active">{pageTitle}</li>
            </ol>
          </div>
        </Col>
      </Row>
      <Row>
       <Col>
        <Card>
          <Card.Body>
            <SubPage/>
            <SettingsFooter />    
            </Card.Body>
            </Card>
            </Col>
            </Row>
            </Col>
        </Container>
        {showDateTime ? <DateTime field={keyboardField} label={keyboardLabel} value={keyboardValue} cancelKeyboard={hideTimeKeyboard} enterValue={enterTimeKeyboardValue}/> : '' }

        </span>
    )
})

export default Settings