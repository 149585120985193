import React, { Component } from 'react';
import { Row, Col, Button } from 'reactstrap';
import { Link } from 'react-router-dom';
import { MDBDataTable } from 'mdbreact';
import config from 'react-global-configuration';


class Devices extends Component {
  constructor(props) {
    super(props);
    
    let authUser = JSON.parse(sessionStorage.getItem('authUser'));
    this.state = {
      locations: [],
      authUser: authUser
    };

    this.getDevices();
  }
 

  handleRowClick = (id) => {
    console.log(id);
    this.props.history.push('/device/'+id);
  }

  getDevices = () => {
    console.log(this.state.user);
    fetch(config.get('apiUrl')+'admin/devices',{
      headers: {
        Authorization: 'Bearer '+ this.state.authUser.token
      }
    })
      .then(res=>res.json())
      .then((data)=> {
        console.log(data);

        data.map(object => {
          object.clickEvent = e => this.handleRowClick(object.id);
          return null;
        });

        
        this.setState({
          locations: data
        });
      });
  }

  render() {
    const data = {
      columns: [
        {
          label: 'Name',
          field: 'name',
          sort: 'asc',
          width: 270
        },
        {
            label: 'Status',
            field: 'status',
            sort: 'asc',
            width: 270
          },
        
      ],
      rows: this.state.locations
      
    };

    return (
      <React.Fragment>
        <div className="container-fluid">
          <Row className="align-items-center">
            <Col sm={6}>
              <div className="page-title-box">
                <h4 className="font-size-18">Devices</h4>
                <ol className="breadcrumb mb-0">
                  <li className="breadcrumb-item">
                    <Link to="/#">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item active">Devices</li>
                </ol>
              </div>
            </Col>

          </Row>

          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-body">
                  <Link to="/newdevice" className="waves-effect">
                    <Button color="primary" className="mr-1">
                               Add Device
                    </Button>
                  </Link>
                </div>
              </div>
            </div>
          </div>


          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-body">

                  <MDBDataTable 
                    responsive 
                    bordered 
                    className="rows-clickable"
                    data={data} />
                </div>
              </div>
            </div>
          </div>

        </div>
      </React.Fragment>
    );
  }
}

export default Devices;
