import React from 'react';

export default class OptionSeven extends React.Component {

  render () { 

    
    return (
<svg width="960" height="600" viewBox="0 0 960 600">
  <defs>
    <filter id="DPBG" x="368.5" y="47.5" width="59" height="44" filterUnits="userSpaceOnUse">
      <feOffset dx="2" dy="3" input="SourceAlpha"/>
      <feGaussianBlur stdDeviation="2.5" result="blur"/>
      <feFlood flood-opacity="0.443"/>
      <feComposite operator="in" in2="blur"/>
      <feComposite in="SourceGraphic"/>
    </filter>
    <clipPath id="clip-option-1">
      <rect width="960" height="600"/>
    </clipPath>
  </defs>
  <g id="option-7" clip-path="url(#clip-option-1)">
    <rect width="960" height="600" fill="none"/>
    <g id="Pump" transform="translate(370 88)">
      <g id="Polygon_6" data-name="Polygon 6" transform="translate(7 34)" fill="#fff">
        <path d="M 36.87333679199219 20.5 L 1.126662373542786 20.5 L 19 0.7452584505081177 L 36.87333679199219 20.5 Z" stroke="none"/>
        <path d="M 19 1.490507125854492 L 2.253314971923828 20 L 35.74668502807617 20 L 19 1.490507125854492 M 19 0 L 38 21 L 0 21 L 19 0 Z" stroke="none" fill="#242323"/>
      </g>
      <g id="Ellipse_3" data-name="Ellipse 3" fill="#fff" stroke="#242323" stroke-width="1">
        <ellipse cx="26" cy="25" rx="26" ry="25" stroke="none"/>
        <ellipse cx="26" cy="25" rx="25.5" ry="24.5" fill="none"/>
      </g>
    </g>

    <text id="DOSING_PUMP" data-name="DOSING
PUMP" transform="translate(396 112)" fill="#242323" font-size="11" font-family="Roboto-Bold, Roboto" font-weight="700" letter-spacing="0.01em"><tspan x="-20.261" y="0">DOSING</tspan><tspan x="-15.698" y="13">PUMP</tspan></text>
    <g id="flowKitConnector" transform="translate(599.168 34.5) rotate(90)">
      <path id="line" d="M107.5,205.5H207.8" fill="none" stroke="#242323" stroke-width="1"/>
      <path id="leftEdge" transform="translate(107.5 205.5)" fill="none" stroke="rgba(0,0,0,0)" stroke-width="1"/>
      <path id="rightEdge" d="M-7.5-4h0L0,0-7.5,4Z" transform="translate(207.804 205.5)" fill="#666" stroke="#666" stroke-width="1"/>
    </g>
    {this.props.config.o1 ? 
    <line id="Line_9" data-name="Line 9" x2="29" transform="translate(364.5 88.5)" fill="none" stroke="#242323" stroke-width="1"/>
    : ''}
    {this.props.mode === 2 ? 
      <g id="ON_OFF" data-name="ON / OFF" text-anchor="middle" transform="translate(374 52)">
      <g transform="matrix(1, 0, 0, 1, -374, -52)" filter="url(#DPBG)">
        <rect id="DPBG-2" data-name="DPBG" width="44" height="29" rx="8" transform="translate(374 52)" fill={this.props.actuators.dp.status}/>
      </g>
      <text id="DP" transform="translate(20 21)" fill={this.props.actuators.dp.textStatus} font-size="18" font-family="Roboto-Bold, Roboto" font-weight="700" letter-spacing="0.01em"><tspan x="0" y="0">{this.props.actuators.dp.value}</tspan></text>
      </g> : 
      <g id="ON_OFF" data-name="ON / OFF" text-anchor="middle" transform="translate(374 52)">
      <g id="DPBG-2" fill={this.props.actuators.dp.status} >
        <path d="M 43 25.5 L 5 25.5 C 2.51869010925293 25.5 0.5 23.48130989074707 0.5 21 L 0.5 5 C 0.5 2.51869010925293 2.51869010925293 0.5 5 0.5 L 43 0.5 C 45.4813117980957 0.5 47.5 2.51869010925293 47.5 5 L 47.5 21 C 47.5 23.48130989074707 45.4813117980957 25.5 43 25.5 Z" stroke="none"/>
        <path d="M 5 1 C 2.794391632080078 1 1 2.794389724731445 1 5 L 1 21 C 1 23.20561027526855 2.794391632080078 25 5 25 L 43 25 C 45.20560836791992 25 47 23.20561027526855 47 21 L 47 5 C 47 2.794389724731445 45.20560836791992 1 43 1 L 5 1 M 5 0 L 43 0 C 45.76142120361328 0 48 2.238580703735352 48 5 L 48 21 C 48 23.76141929626465 45.76142120361328 26 43 26 L 5 26 C 2.238571166992188 26 0 23.76141929626465 0 21 L 0 5 C 0 2.238580703735352 2.238571166992188 0 5 0 Z" stroke="none" fill="#d8d2d2"/>
      </g>
      <text id="DP" transform="translate(22 18)" fill={this.props.actuators.dp.textStatus} font-size="14" font-family="Roboto-Bold, Roboto" font-weight="700" letter-spacing="0.01em"><tspan x="0" y="0">{this.props.actuators.dp.value}</tspan></text>
      </g>
    }

   
  </g>
</svg>

);
}
}