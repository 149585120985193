import React from 'react';

export default class RoundButton extends React.Component {

    render() {

      var type = "submit"
      if (this.props.type) {
        type = this.props.type
      }

      return <div className="buttonContainer"><button
        type={type} 
        className={`button ${this.props.color} ${this.props.size} ${this.props.icon}`} 
        disabled={this.props.disabled}
        onClick={this.props.onClick}>{this.props.title}</button>
        </div>;
    }
  }