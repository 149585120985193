import React, { Component } from "react";
import { Row, Col, Button, FormGroup, Alert } from "reactstrap";
import { Link } from "react-router-dom";
import config from 'react-global-configuration';
import { connect } from 'react-redux'
import { AvForm, AvField } from "availity-reactstrap-validation";
import Back from "../../components/Back";


class HWDevice extends Component {
  constructor(props) {
    super(props);
    
    var authUser = JSON.parse(sessionStorage.getItem("authUser"))

    this.state = {
        authUser: authUser,
        device: '',
        deviceUuid: this.props.match.params.id,
        deviceCreated: false, 
        savingSuccess: false,
        savingError: false,
        codeError: false
    };

    if (this.props.match.params.id){
      this.getDevice()
  }

    
  }

  createDevice = (event, values) => {
      this.setState({
          codeError: false
      })
    var deviceRequest = {
        deviceName: values.deviceName,
        verificationCode: values.verificationCode
    }
    fetch(config.get('apiUrl')+'admin/device',{
        method: 'POST',
        headers: {
            'Content-Type' : 'application/json',
            Authorization: 'Bearer '+ this.state.authUser.token
        },
        body: JSON.stringify({device:deviceRequest})
    })
    .then(res=>res.json())
    .then((json)=> {
        console.log(json)
        if (json === null){
            this.setState({
                codeError: true
            })
        }
        else {
        this.setState({
            deviceUuid: json.device.id,
            savingSuccess: true,
            device: json.device
        })
    }
    })
  }

  editDevice = (event, values) => {
    this.setState({
      savingSuccess: false,
      savingError: false
    })
    var deviceRequest = {
      name: values.deviceName,
      id: this.state.deviceUuid
  }
  fetch(config.get('apiUrl')+'admin/device',{
      method: 'PATCH',
      headers: {
          'Content-Type' : 'application/json',
          Authorization: 'Bearer '+ this.state.authUser.token
      },
      body: JSON.stringify({device: deviceRequest})
  })
  .then(res=>res.json())
  .then((json)=> {
      if (json.error){
        this.setState({
          savingError: true,
          error: json.error
        })
      }
      else {
        this.setState({
            deviceCreated: true,
            savingSuccess: true
        })
    }
  })

  }

  getDevice = () => {
    fetch(config.get('apiUrl')+'admin/device/'+this.state.deviceUuid,{
      headers: {
          Authorization: 'Bearer '+ this.state.authUser.token
      }
  })
      .then(response => response.json())
      .then(json => {


          this.setState({
              device: json
            })
      })
  }



  render() {

    if (this.props.match.params.id && this.state.user === '') {
      return ''
    }

  

    return (
      <React.Fragment>
        <div className="container-fluid">
          <Row className="align-items-center">
            <Col sm={6}>
              <div className="page-title-box">
                <h4 className="font-size-18">Device</h4>
                <ol className="breadcrumb mb-0">
                  <li className="breadcrumb-item">
                    <Link to="/#">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item active">Device</li>
                </ol>
              </div>
            </Col>

          </Row>

          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-body">
                    <AvForm onValidSubmit={this.state.device.id ? this.editDevice : this.createDevice}>
                    {this.state.savingSuccess ? 
                    <Alert color="success" >
                      Device saved
                    </Alert> : '' }
                    {this.state.codeError ? 
                    <Alert color="warning" >
                      Invalid verification code
                    </Alert> : '' }
                    {this.state.savingError ? 
                    <Alert color="warning" >
                      {this.state.error}
                    </Alert> : '' }
                    <AvField
                      name="deviceName"
                      label="Device name "
                      placeholder="Enter device name"
                      type="text"
                      value={this.state.device.name}
                      errorMessage="Device name is mandatory"
                      validate={{
                        required: { value: true }
                      }}
                    />
                    {!this.state.device.id ?
                    <AvField
                      name="verificationCode"
                      label="Verification code"
                      placeholder="Enter verification code"
                      type="text"
                      errorMessage="Verification code is mandatory"
                      validate={{
                        required: { value: true }
                      }}
                    /> : '' }
                  
                    <FormGroup className="mb-0">
                      <div>
                        <Button type="submit" color="primary" className="mr-1">
                          {this.state.device.id ? 'Save' : 'Add'}
                        </Button>{" "}
                        <Back/>
                      </div>
                    </FormGroup>
                    </AvForm>
                    
                </div>
              </div>
            </div>
          </div>

        </div>
      </React.Fragment>
    );
  }
}
function mapStateToProps(state){
  return {
    sensors: state.Device.sensors,
    actuators: state.Device.actuators,
    errors: state.Device.errors,
    config: state.Device.config,
    editConfig: state.Device.config,
    devices: state.Device.devices,
    selectedDevice: state.Device.selectedDevice
  }
}

export default (connect(
  mapStateToProps
)(HWDevice));
