export const STORE_CONFIG = "STORE_CONFIG"
export const EDIT_CONFIG = "EDIT_CONFIG"
export const SENSORS = "SENSORS"
export const ACTUATORS = "ACTUATORS"
export const COMMANDIN = "COMMANDIN"
export const DEVICES = "DEVICES"
export const SELECTED_DEVICE = "SELECTED_DEVICE"
export const DEVICE_SENSORS = "DEVICE_SENSORS"
export const LAST_UPDATE = "LAST_UPDATE"

export function storeConfig(payload){
    return {type: STORE_CONFIG, payload}
}

export function editConfig(payload){
    return {type: EDIT_CONFIG, payload}
}

export function sensors(payload){
    return {type: SENSORS, payload}
}

export function actuators(payload){
    return {type: ACTUATORS, payload}
}

export function commandIn(payload){
    return {type: COMMANDIN, payload}
}

export function devices(payload){
    return {type: DEVICES, payload}
}

export function selectedDevice(payload){
    return {type: SELECTED_DEVICE, payload}
}

export function deviceSensors(payload){
    return {type: DEVICE_SENSORS, payload}
}

export function lastUpdate(payload){
    return {type: LAST_UPDATE, payload}
}