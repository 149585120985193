import React, { useEffect} from 'react';
import { useTranslation } from "react-i18next";
import { useSelector } from 'react-redux';
import { updateConfiguration, updateParameters, updateParameter } from '../../../../helpers/utils'
import { useForm, Controller } from 'react-hook-form'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'


export const ConductivitySensorParameters = (props) => {
    const { t } = useTranslation('common')
    const editConfig = useSelector(state => state.Device.config)
    const { handleSubmit,  control, setValue, getValues } = useForm({mode:"onSubmit",reValidateMode:"onSubmit"})


    var conductivitySensors = [{
        title: 'ect1',
        key: 'c1'
        }
        ]

    if (editConfig.g.o2) {
        conductivitySensors = [...conductivitySensors, {title: 'ect2',
        key: 'c2'
        }]
    }

    if (editConfig.g.o4) {
        conductivitySensors = [...conductivitySensors, {title: 'ect3',
        key: 'c3'
        }]
    }


    const onSubmit = () => {

    }
     
    useEffect(() => {
        [ 
            { name: 'c1-k', value: editConfig.c.c1.k },
            { name: 'c2-k', value: editConfig.c.c2.k },
            { name: 'c3-k', value: editConfig.c.c3.k },
            { name: 'c1-tc', value: editConfig.c.c1.tc },
            { name: 'c2-tc', value: editConfig.c.c2.tc },
            { name: 'c3-tc', value: editConfig.c.c3.tc }
          ].forEach(({ name, value }) => setValue(name, value))
        
      }, [editConfig]);
  
  
    return (
      <form onSubmit={handleSubmit(onSubmit)}>
          <Container>
              <Row>
                  <Col md="2"></Col>
                  <Col md="3" className="column-title">{t('conductivity.sensor_parameters.k_factor')}</Col>
                  <Col md="3" className="column-title d-none">{t('conductivity.sensor_parameters.temp_correction')}</Col>
              </Row>
             
          { conductivitySensors.map((sensor, index) => 
              <Row key={index}>
                <Col md="2" className="parameter-text">{sensor.title.toUpperCase()}</Col>
                <Col md="3" className="text-center"><Controller 
                          render={({ onChange }) => (
                            <Form.Control 
                                as="input"
                                className="input-digit-big"
                                id={`${sensor.key}-k`} 
                                onChange={(e) => updateParameter(editConfig, "c", `${sensor.key}-k`, e.target.value)}
                                value={getValues(`${sensor.key}-k`)}
                                />
                          )} 
                          name={`${sensor.key}-k`}
                          control={control}
                      />
                </Col>
                <Col md="3" className="d-none">
                    <Controller 
                          render={({ onChange }) => (
                              <Form.Check 
                                  id={`${sensor.key}-tc`}
                                  type="switch"
                                  checked={getValues(`${sensor.key}-tc`) ? true: false}
                                  onChange={(value) => {
                                      updateParameter(editConfig, "c", `${sensor.key}-tc`, value.target.checked ? 1 : 0)
                                  }}/>
                          )} 
                          name={`${sensor.key}-tc`}
                          control={control}
                      />
                  </Col>
              
              </Row>
          )}
              
            </Container>
           
      </form>
      
    )
  }