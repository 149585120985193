import React, {useEffect,useState, memo} from 'react'
import { useForm, Controller } from 'react-hook-form'
import { useTranslation } from "react-i18next";
import { useSelector } from 'react-redux';
import { updateParameter, updateConfiguration } from '../../../../helpers/utils'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import moment from 'moment'
import TimezonePicker, {timezones} from 'react-timezone';
import 'moment-timezone'


export const GeneralTimeSettingsForm = memo(props => {
    const { handleSubmit, control, setValue } = useForm({mode:"onSubmit",reValidateMode:"onSubmit"})
    const { t } = useTranslation('common')
    const editConfig = useSelector(state => state.Device.config)
    const [currentTime, setCurrentTime] = useState(moment(new Date()).format("DD.MM.YYYY HH:mm"));
    const [currentTimezone, setCurrentTimezone] = useState('');
    const onSubmit = values => {
  
    }

    const analyzeTimezone = (timezone) => {
      timezones.forEach(element => {
        if (timezone === element.name) {
          var configuration = JSON.parse(JSON.stringify(editConfig.g))
          setCurrentTimezone(element.name)
          configuration.tz = element.name + ',' +element.offset;
          configuration.tc = 1
          updateConfiguration("g", null, configuration, editConfig)
        
        }
      });
    }


    useEffect(() => {
        [ 
            { name: 'tz', value: editConfig.g.tz.split(/,/)[0]}
        ].forEach(({ name, value }) => {setCurrentTimezone(value)
         
        })
        
      }, [editConfig]);

    
  
    return (
      <form onSubmit={handleSubmit(onSubmit)}>
          <Container>
       
             <Row>
                <Col md="7" className="parameter-text">{t('general.time_settings.datetime')}</Col>
                <Col md="5">
                    <Controller 
                          render={({ onChange }) => (
                            <Form.Control 
                                        as="input"
                                        id="t" 
                                        onFocus={()=>{
                                            var label = t('general.time_settings.datetime')
                                            var field = "t"
                                            var value = editConfig.g !== undefined && editConfig.g.tc ? moment(editConfig.g.t,"x").format("DD.MM.YYYY HH:mm") : currentTime
                                            props.showTimeKeyboard(field, label, value.toString(), editConfig, "g")
                                          }}
                                        value={editConfig.g !== undefined && editConfig.g.tc ? moment(editConfig.g.t,"x").format("DD.MM.YYYY HH:mm") : currentTime}
                                        />
                                )} 
                                name="t"
                                control={control}
                         
                      />
                  </Col>
              </Row>
              <Row>
                <Col md="4" className="parameter-text">{t('general.time_settings.timezone')}</Col>
                <Col md="8">
                  <TimezonePicker
                    value={currentTimezone}
                    onChange={timezone => analyzeTimezone(timezone)}
                    inputProps={{
                      placeholder: 'Select Timezone...',
                      name: 'timezone',
                    }}
                />
                  </Col>
              </Row>
              
            </Container>
            
      </form>
    )
  })