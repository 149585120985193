import React, {useEffect, memo} from 'react';
import { useTranslation } from "react-i18next";
import { useSelector } from 'react-redux';
import { updateConfiguration, updateParameters, updateParameter } from '../../../../helpers/utils'
import { useForm, Controller } from 'react-hook-form'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'

export const Advanced = memo(props => {
    const { t } = useTranslation('common')
    const editConfig = useSelector(state => state.Device.config)
    const { handleSubmit, control, setValue, getValues } = useForm({mode:"onSubmit",reValidateMode:"onSubmit"})
  
    const onSubmit = values => {
  
    }
     
    useEffect(() => {
        [ 
            { name: 'x2r', value: !editConfig.x.x2r },
            { name: 'x2rt', value: editConfig.x.x2rt }
          ].forEach(({ name, value }) => setValue(name, value))
        
      }, [editConfig]);
  
    return (
      <form onSubmit={handleSubmit(onSubmit)}>
          <Container>
              <Row>
                <Col md="7" className="parameter-text">{t('needle_valve.advanced.rv02_rinse')}</Col>
                <Col md="2">
                <Controller 
                          render={({ onChange }) => (
                              <Form.Check 
                                  id="x2r"
                                  type="switch"
                                  checked={getValues("x2r") ? true: false}
                                  onChange={(value) => {
                                      updateParameter(editConfig, "x", "x2r", value.target.checked ? 0 : 1)
                                  }}/>
                          )} 
                          name="x2r"
                          control={control}
                      />
                  </Col>
              </Row>
              <Row>
                <Col md="7" className="parameter-text">{t('needle_valve.advanced.rv02_rinse_time')}</Col>
                <Col md="2">
                <Controller 
                          render={({ onChange }) => (
                            <Form.Control 
                                as="input"
                                className="input-digit-big"
                                id="x2rt" 
                                onChange={(e) => updateParameter(editConfig, "x", "x2rt", e.target.value)}
                                value={getValues("x2rt")}
                                />
                          )} 
                          name="x2rt"
                          control={control}
                      />
                  </Col>
                  <Col md="3" className="parameter-description">{t('needle_valve.advanced.sek')}</Col>
              </Row>
              
            </Container>
  
  
      </form>
    )
  })