import React, { useEffect} from 'react';
import { useTranslation } from "react-i18next";
import { useSelector } from 'react-redux';
import { updateConfiguration, updateParameters, updateParameter } from '../../../../helpers/utils'
import { useForm, Controller } from 'react-hook-form'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'

export const FlowForm = (props) => {
    const { handleSubmit, control, setValue, getValues } = useForm({mode:"onSubmit",reValidateMode:"onSubmit"})
    const { t } = useTranslation('common')
    const editConfig = useSelector(state => state.Device.config)
    const sensors = useSelector(state => state.Device.sensors)

    var flowSensors = [{
      title: 'fl1',
      key: 'f1'
      },
      {title: 'fl2',
      key: 'f2'
      },
      {title: 'fl3',
      key: 'f3'
      }]

    if (editConfig.g.o3) {
        flowSensors = [...flowSensors,
          {title: 'fl4',
          key: 'f4'
          }
        ]
    }

    const onSubmit = () => {

    }
     
    useEffect(() => {
        [ 
            { name: 'f1-q', value: editConfig.f.f1.q },
            { name: 'f2-q', value: editConfig.f.f2.q },
            { name: 'f3-q', value: editConfig.f.f3.q },
            { name: 'f4-q', value: editConfig.f.f4.q }
         ].forEach(({ name, value }) => setValue(name, value))
    },[editConfig]);

    useEffect(() => {
        [ 
            { name: 'f1-t', value: sensors.fl01.tvalue },
            { name: 'f2-t', value: sensors.fl02.tvalue },
            { name: 'f3-t', value: sensors.fl03.tvalue },
            { name: 'f4-t', value: sensors.fl04.tvalue }
         ].forEach(({ name, value }) => setValue(name, value))
    },[sensors]);
  
    return (
      <form onSubmit={handleSubmit(onSubmit)}>
          <Container>
              <Row>
                  <Col md="2"></Col>
                  <Col md="3" className="column-title">{t('flow.flow.q')}</Col>
                  {editConfig.g.s === 0 ? <Col md="5" className="column-title">{t('flow.flow.total_qty')}</Col> : ''}
              </Row>
             
          { flowSensors.map((sensor, index) => 
              <Row key={index}>
                <Col md="2" className="parameter-text">{sensor.title.toUpperCase()}</Col>
                <Col md="3" className="text-center"><Controller 
                          render={({ onChange }) => (
                            <Form.Control 
                                as="input"
                                className="input-digit-big"
                                id={`${sensor.key}-q`} 
                                onChange={(e) => updateParameter(editConfig, "f", `${sensor.key}-q`, e.target.value)}
                                value={getValues(`${sensor.key}-q`)}
                                />
                          )} 
                          name={`${`${sensor.key}-q`}`}
                          control={control}
                      />
                </Col>
                {editConfig.g.s === 0 ? 
                <Col md="5">
                <Controller 
                          render={({ onChange }) => (
                            <Form.Control 
                                as="input"
                                disabled={true}
                                className="flow-digits"
                                id={`${sensor.key}-t`} 
                                onChange={(e) => updateParameter(editConfig, "f", `${sensor.key}-t`, e.target.value)}
                                value={getValues(`${sensor.key}-t`)}
                                />
                          )} 
                          name={`${`${sensor.key}-t`}`}
                          control={control}
                      />
                  </Col>
                  : '' }
              
              </Row>
          )}
              
            </Container>
           
      </form>
      
    )
  }