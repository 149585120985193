import React, {useEffect, memo} from 'react';
import { useTranslation } from "react-i18next";
import { useSelector } from 'react-redux';
import { useForm, Controller } from 'react-hook-form'
import { updateParameter } from '../../../../helpers/utils'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'

export const GeneralOptionsForm = memo(props => {
    const { handleSubmit,  control, setValue, getValues } = useForm({mode:"onSubmit",reValidateMode:"onSubmit"})
    const { t } = useTranslation('common')
    const editConfig = useSelector(state => state.Device.config)

    const onSubmit = values => {
  
    }

    useEffect(() => {
        [ 
            { name: 'o1', value: editConfig.g.o1 },
            { name: 'o2', value: editConfig.g.o2 },
            { name: 'o3', value: editConfig.g.o3 },
            { name: 'o4', value: editConfig.g.o4 }
          ].forEach(({ name, value }) => setValue(name, value))
        
      }, [editConfig]);
  
  
  
    return (
      <form onSubmit={handleSubmit(onSubmit)}>
          <Container>
              <Row>
                <Col md="3" className="parameter-text">{t('general.options.option1')}</Col>
                <Col md="6" className="parameter-description">{t('general.options.option1_title')}</Col>
                <Col md="3">
                    <Controller 
                          render={({ onChange }) => (
                              <Form.Check 
                                  id="o1"
                                  type="switch"
                                  checked={getValues("o1") ? true: false}
                                  onChange={(value) => {
                                      updateParameter(editConfig, "g", "o1", value.target.checked ? 1 : 0)
                                  }}/>
                          )} 
                          name="o1"
                          control={control}
                      />
                  </Col>
              </Row>
              <Row>
                <Col md="3" className="parameter-text">{t('general.options.option2')}</Col>
                <Col md="6" className="parameter-description">{t('general.options.option2_title')}</Col>
                <Col md="3">
                    <Controller 
                          render={({ onChange }) => (
                              <Form.Check 
                                  id="o2"
                                  type="switch"
                                  checked={getValues("o2") ? true: false}
                                  onChange={(value) => {
                                    updateParameter(editConfig, "g", "o2", value.target.checked ? 1 : 0)
                                  }}/>
                          )} 
                          name="o2"
  
                          control={control}
                      />
                  </Col>
              </Row>
              <Row>
                <Col md="3" className="parameter-text">{t('general.options.option3')}</Col>
                <Col md="6" className="parameter-description">{t('general.options.option3_title')}</Col>
                <Col md="3">
                    <Controller 
                          render={({ onChange }) => (
                              <Form.Check 
                                  id="o3"
                                  type="switch"
                                  checked={getValues("o3") ? true: false}
                                  onChange={(value) => {
                                    updateParameter(editConfig, "g", "o3", value.target.checked ? 1 : 0)
                                  }}/>
                          )} 
                          name="o3"
  
                          control={control}
                      />
                  </Col>
              </Row>
              <Row>
                <Col md="3" className="parameter-text">{t('general.options.option4')}</Col>
                <Col md="6" className="parameter-description">{t('general.options.option4_title')}</Col>
                <Col md="3">
                    <Controller 
                          render={({ onChange }) => (
                              <Form.Check 
                                  id="o4"
                                  type="switch"
                                  checked={getValues("o4") ? true: false}
                                  onChange={(value) => {
                                    updateParameter(editConfig, "g", "o4", value.target.checked ? 1 : 0)
                                  }}/>
                          )} 
                          name="o4"
  
                          control={control}
                      />
                  </Col>
              </Row>
              
            </Container>
  
  
      </form>
    )
  })
