import React, {useState, memo} from 'react';
import { useTranslation } from "react-i18next";
import Tabs from 'react-bootstrap/Tabs'
import Tab from 'react-bootstrap/Tab'
import { SolenoidValve } from './Parameters';



const Valve = memo(props => {
  const { t } = useTranslation('common')

    return (  <Tabs defaultActiveKey="0" id="sensor_ranges">
              <Tab eventKey="0" title={t('valve.tabs.solenoid_valve')}>
                <SolenoidValve showKeyboard={props.showKeyboard} />
              </Tab>
            </Tabs>
    )
    })

  
  export default Valve
