import React, { useState, useRef, useEffect, memo} from 'react';
//import { w3cwebsocket as W3CWebSocket } from "websocket";
import config from 'react-global-configuration';
import  store  from '../store/index';
import { useSelector } from 'react-redux';


const WebSocketConnection = memo(props => {

    const [userTriggered, setUserTriggered] = useState(false)
    const selectedDevice = useSelector(state => state.Device.selectedDevice)

    const ws = useRef(null);
    const currentDevice = useRef(null)

   
    const manageConnection = (deviceId) => {
        let connectInterval;
        ws.current = new WebSocket(config.get('wsUrl')+'/'+ deviceId);
        
        ws.current.onopen = () => {
            console.log("ws opened");
            clearTimeout(connectInterval);
        }
         ws.current.onclose = (e) => {
                console.log(selectedDevice)
                console.log(e.currentTarget.url.split('/')[3])
                console.log(e)
                if (userTriggered){
                    setUserTriggered(false)
                }
                else if (e.currentTarget.url.split('/')[3] === currentDevice.current){
                    manageConnection(deviceId)
                }
        //     setTo(to + 250)
  
        //     connectInterval = setTimeout(this.check, this.state.timeout)

        }

        ws.current.onmessage = (message) => {
            const parsedMessage = JSON.parse(message.data);
            if (parsedMessage.g !== undefined){
              store.dispatch({type:"STORE_CONFIG",payload: parsedMessage})
            }
            else if (parsedMessage.p2 !== undefined){
              
              var sensor = {
                ps01: {
                    value:parsedMessage.p1,
                    status:config.get('statusGreen')
                },
                ps02: {
                    value:parsedMessage.p2,
                    status:config.get('statusGreen')
                },
                ps03: {
                    value:parsedMessage.p3,
                    status:config.get('statusGreen')
                },
                ps04: {
                    value:parsedMessage.p4,
                    status:config.get('statusGreen')
                },
                ps05: {
                    value:parsedMessage.p5,
                    status:config.get('statusGreen')
                },
                ect01: {
                    c:{
                        value:parsedMessage.c1.c,
                        status:config.get('statusGreen')
                    },
                    t:{
                        value:parsedMessage.c2.t,
                        status:config.get('statusGreen') 
                    }
                },
                ect02: {
                    c:{
                        value:parsedMessage.c2.c,
                        status:config.get('statusGreen')
                    },
                    t:{
                        value:parsedMessage.c2.t,
                        status:config.get('statusGreen') 
                    }
                },
                ect03: {
                    c:{
                        value:parsedMessage.c3.c,
                        status:config.get('statusGreen')
                    },
                    t:{
                        value:parsedMessage.c3.t,
                        status:config.get('statusGreen') 
                    }
                },
                ls01: {
                    value:parsedMessage.l1 ? "ON" : "OFF",
                    status:config.get('statusGreen')
                },
                ls02: {
                    value:parsedMessage.l2 ? "ON" : "OFF",
                    status:config.get('statusGreen')
                },
                ls03: {
                    value:parsedMessage.l3 ? "ON" : "OFF",
                    status:config.get('statusGreen')
                },
                ls04: {
                    value:parsedMessage.l4 ? "ON" : "OFF",
                    status:config.get('statusGreen')
                },
                fl01: {
                    cvalue:parsedMessage.f1.c,
                    tvalue:parsedMessage.f1.t,
                    status:config.get('statusGreen')
                },
                fl02: {
                    cvalue:parsedMessage.f2.c,
                    tvalue:parsedMessage.f2.t,
                    status:config.get('statusGreen')
                },
                fl03: {
                    cvalue:parsedMessage.f3.c,
                    tvalue:parsedMessage.f3.t,
                    status:config.get('statusGreen')
                },
                fl04: {
                    cvalue:parsedMessage.f4.c,
                    tvalue:parsedMessage.f4.t,
                    status:config.get('statusGreen')
                }
            }
              store.dispatch({type:"SENSORS",payload: sensor})
              store.dispatch({type:"LAST_UPDATE",payload: parsedMessage.t})
            }
            else if (parsedMessage.x1 !== undefined){
              var actuator = {
                xv01: {
                    value: parsedMessage.x1 ? "ON" : "OFF",
                    status: parsedMessage.x1 ? config.get('statusActive') : config.get('statusInactive'),
                    textStatus: parsedMessage.x1 ? config.get('textActive') : config.get('textInactive')
                },
                xv02: {
                    value: parsedMessage.x2 ? "ON" : "OFF",
                    status: parsedMessage.x2 ? config.get('statusActive') : config.get('statusInactive'),
                    textStatus: parsedMessage.x2 ? config.get('textActive') : config.get('textInactive')
                },
                xv03: {
                    value: parsedMessage.x3 ? "ON" : "OFF",
                    status: parsedMessage.x3 ? config.get('statusActive') : config.get('statusInactive'),
                    textStatus: parsedMessage.x3 ? config.get('textActive') : config.get('textInactive')
                },
                rv01: {
                    value: parsedMessage.r1,
                    status: parsedMessage.r1 ? config.get('statusActive') : config.get('statusInactive'),
                    textStatus: parsedMessage.r1 ? config.get('textActive') : config.get('textInactive')
                },
                rv02: {
                    value: parsedMessage.r2,
                    status: parsedMessage.r2 ? config.get('statusActive') : config.get('statusInactive'),
                    textStatus: parsedMessage.r2 ? config.get('textActive') : config.get('textInactive')
                },
                rv03: {
                    value: parsedMessage.r3,
                    status: parsedMessage.r3 ? config.get('statusActive') : config.get('statusInactive'),
                    textStatus: parsedMessage.r3 ? config.get('textActive') : config.get('textInactive')
                },
                dp: {
                    value: parsedMessage.dp ? "ON" : "OFF",
                    status: parsedMessage.dp ? config.get('statusActive') : config.get('statusInactive'),
                    textStatus: parsedMessage.dp ? config.get('textActive') : config.get('textInactive')
                },
                p01: {
                    value: parsedMessage.p1 ? "ON" : "OFF",
                    status: parsedMessage.p1 ? config.get('statusActive') : config.get('statusInactive'),
                    textStatus: parsedMessage.p1 ? config.get('textActive') : config.get('textInactive')
                }
            }
              store.dispatch({type:"ACTUATORS",payload: actuator})
            }
            else if (parsedMessage.c !== undefined){
              var commandIn = {
                c: {
                    n: parsedMessage.c.n,
                    e: parsedMessage.c.e,
                    m: parsedMessage.c.m,
                    os: parsedMessage.c.os,
                    s: parsedMessage.c.s
                }
              }
                store.dispatch({type:"COMMANDIN",payload: commandIn})
            }
            //store.dispatch({type:"STORE_CONFIG",payload: message})
          };

          
        
    };

    
    

    useEffect(() => {
        currentDevice.current = selectedDevice.deviceId
        manageConnection(selectedDevice.deviceId)
        return () => {
            setUserTriggered(true)
            ws.current.close();
        };
    }, [selectedDevice]);



      return(null)
    
})

export default WebSocketConnection