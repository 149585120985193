import React, { Component } from 'react';
import { Row, Col, Button } from 'reactstrap';
import { Link } from 'react-router-dom';
import { MDBDataTable } from 'mdbreact';
import config from 'react-global-configuration';
import flatten from 'flat';
import moment from 'moment'
import { connect } from 'react-redux'
import {withTranslation} from "react-i18next";

class Maintenance extends Component {
  constructor(props) {
    super(props);
    
    let authUser = JSON.parse(sessionStorage.getItem('authUser'));
    this.state = {
      maintenance: [],
      authUser: authUser
    };
    if (this.props.selectedDevice.value !== undefined){
      this.getMaintenance();
    }
  }

  static getDerivedStateFromProps(nextProps, prevState){
    if(nextProps.selectedDevice!==prevState.selectedDevice){
      return { selectedDevice: nextProps.someValue};
   }
   else return null;
 }

 componentDidUpdate(prevProps, prevState) {
    if(prevProps.selectedDevice!==this.props.selectedDevice){
      this.getMaintenance();
    }
  }

  handleRowClick = (id) => {
    this.props.history.push('/maintenance/'+id);
  }


  getMaintenance = () => {
    fetch(config.get('apiUrl')+'admin/maintenances/'+this.props.selectedDevice.value,{
      headers: {
        Authorization: 'Bearer '+ this.state.authUser.token
      }
    })
      .then(res=>res.json())
      .then((data)=> {

        data.map(maintenance => {
          maintenance.clickEvent = e => this.handleRowClick(maintenance.uuid);
          maintenance.maintenanceDate = moment(maintenance.maintenanceDate).format('DD-MM-YYYY HH:mm:ss');
          return null;
        });

        Object.keys(data).forEach(function(key) {
          data[key] = flatten(data[key]);
        });

        this.setState({
          maintenance: data
        });
      });
  }

  render() {
    const data = {
      columns: [
        {
          label: 'Title',
          field: 'title',
          sort: 'asc',
          width: 150
        },
        {
          label: 'Description',
          field: 'description',
          sort: 'asc',
          width: 270
        },
        {
          label: 'Date',
          field: 'maintenanceDate',
          sort: 'asc',
          width: 200
        }
      ],
      rows: this.state.maintenance
      
    };

    return (
      <React.Fragment>
        <div className="container-fluid">
          <Row className="align-items-center">
            <Col sm={6}>
              <div className="page-title-box">
                <h4 className="font-size-18">Maintenance</h4>
                <ol className="breadcrumb mb-0">
                  <li className="breadcrumb-item">
                    <Link to="/#">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item active">Maintenance</li>
                </ol>
              </div>
            </Col>

          </Row>

          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-body">
                  <Link to="/newmaintenance" className="waves-effect">
                    <Button color="primary" className="mr-1">
                               Add maintenance record
                    </Button>
                  </Link>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-body">
                  <MDBDataTable 
                    className="rows-clickable"
                    responsive 
                    bordered
                    data={data} />
                </div>
              </div>
            </div>
          </div>

        </div>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state){
  return {
    sensors: state.Device.sensors,
    actuators: state.Device.actuators,
    configuration: state.Device.config,
    commandIn: state.Device.commandIn,
    selectedDevice: state.Device.selectedDevice
  }
}

export default withTranslation('common')(connect(
  mapStateToProps
)(Maintenance))

