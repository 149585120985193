import React, {useState, memo} from 'react';
import { useTranslation } from "react-i18next";
import Tabs from 'react-bootstrap/Tabs'
import Tab from 'react-bootstrap/Tab'
import { FlowForm } from './Parameters';


const Flow = memo(props => {
  const { t } = useTranslation('common')

    return (  <Tabs defaultActiveKey="0" id="flow">
              <Tab eventKey="0" title={t('flow.tabs.flow')}>
                <FlowForm/>
              </Tab>
            </Tabs>
    )
})

export default Flow
