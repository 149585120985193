import React from 'react';
import {withTranslation} from "react-i18next";
import moment from 'moment'

class Error extends React.Component {

    constructor(props){
        super(props)
        this.state = {
            menuItems: [
                'general','pressure','conductivity','valve5v','needle_valve','flow','level','online'
            ],
            activeItem: props.activeItem
        }
    }

    componentWillReceiveProps(nextProps){
        this.setState({
            activeItem: nextProps.activeItem
        })
    }


    render() {

      const { t } = this.props;

      return <div className="errorItem">
      <div className="emergency-title"><b>{t('errors.device_stopped')}</b></div>
      <div className="error-title">{t(`errors.e${this.props.code}`)}</div>
      <div className="error-body">
          {this.props.sensor !== 0 ? 
          <span>{t('errors.sensor')}: {this.props.sensor}<br/>{t('errors.value')}: {this.props.value}<br/>
          </span> : ' '}
          {t('errors.time')}: {moment(this.props.time).format("DD.MM.YYYY HH:mm:ss")}</div>
          </div>
    }
  }

  export default withTranslation('common')(Error)