import React, {useState, memo} from 'react';
import {Container, Row, Col, Popover, PopoverHeader, PopoverBody, Button} from 'reactstrap'
import Schematics from './schematics/index';
import Carousel from 'react-bootstrap/Carousel'
import OptionOne from './schematics/OptionOne';
import OptionTwo from './schematics/OptionTwo';
import OptionThree from './schematics/OptionThree';
import OptionFour from './schematics/OptionFour';
import OptionFive from './schematics/OptionFive';
import OptionSix from './schematics/OptionSix';
import OptionSeven from './schematics/OptionSeven';
import {withTranslation} from "react-i18next";
import RoundButton from '../../components/RoundButton';
import Error from '../../components/Error';
import { AvForm, AvField, AvGroup, AvFeedback, AvInput } from "availity-reactstrap-validation";
import config from 'react-global-configuration';
import { actuators } from '../../configuration/actuators';
import moment  from 'moment';

import { connect } from 'react-redux'
import  store  from '../../store/index'
import WebSocketConnection from '../../components/WebSocket';

class Device extends React.Component {

    constructor(props){
        super(props)

        this.state = {
            showPopover: false,
            keyboardField: "",
            keyboardLabel: "",
            keyboardValue: "",
            timeString: "",
            authUser: JSON.parse(sessionStorage.getItem('authUser'))
          }
        this.startTime()
        this.interval = setInterval(this.startTime,1000)
        if (this.props.selectedDevice !== ""){
            this.getConfiguration();
            this.getActuatorstates();
            this.getMode();
        }

    }

    componentWillUnmount() {
        clearInterval(this.interval);
      }

    static getDerivedStateFromProps(nextProps, prevState){
        if(nextProps.selectedDevice!==prevState.selectedDevice){
          return { selectedDevice: nextProps.someValue};
       }
       else return null;
     }

     componentDidUpdate(prevProps, prevState) {
        if(prevProps.selectedDevice!==this.props.selectedDevice){
          //Perform some operation here
          this.getConfiguration();
          this.getActuatorstates();
          this.getMode();
        }
      }

    getConfiguration = () => {
        fetch(config.get('apiUrl')+'admin/latestConfiguration/'+this.props.selectedDevice.value,{
            headers: {
                Authorization: 'Bearer '+ this.state.authUser.token
            }
        })
        .then(res=>res.json())
        .then((data)=> {
            console.log(JSON.parse(data.configuration))
            store.dispatch({type:"STORE_CONFIG",payload: JSON.parse(data.configuration)})
        })
    }

    getMode = () => {
        fetch(config.get('apiUrl')+'admin/latestMode/'+this.props.selectedDevice.value,{
            headers: {
                Authorization: 'Bearer '+ this.state.authUser.token
            }
        })
        .then(res=>res.json())
        .then((data)=> {
            let commandIn = this.props.commandIn;
            commandIn.c.m = data.iddevicemode;
            commandIn.c.s = data.iddevicestate;
            store.dispatch({type:"COMMANDIN",payload: commandIn})
        })
    }

    getActuatorstates = () => {
        fetch(config.get('apiUrl')+'admin/latestStates/'+this.props.selectedDevice.value,{
            headers: {
                Authorization: 'Bearer '+ this.state.authUser.token
            }
        })
        .then(res=>res.json())
        .then((data)=> {
            if (data.length > 0){
                data.forEach(element => {
                    switch (element.actuator.name) {
                        case 'p1' : 
                            actuators.p01.value = element.value ? "ON" : "OFF";
                            actuators.p01.status = element.value ? config.get('statusActive') : config.get('statusInactive');
                            actuators.p01.textStatus = element.value ? config.get('textActive') : config.get('textInactive');
                            break;
                        case 'xv2' : 
                            actuators.xv02.value = element.value ? "ON" : "OFF";
                            actuators.xv02.status = element.value ? config.get('statusActive') : config.get('statusInactive');
                            actuators.xv02.textStatus = element.value ? config.get('textActive') : config.get('textInactive');
                            break;
                        case 'xv1':
                            actuators.xv01.value = element.value ? "ON" : "OFF";
                            actuators.xv01.status = element.value ? config.get('statusActive') : config.get('statusInactive');
                            actuators.xv01.textStatus = element.value ? config.get('textActive') : config.get('textInactive');
                            break;
                        case 'xv3':
                            actuators.xv03.value = element.value ? "ON" : "OFF";
                            actuators.xv03.status = element.value ? config.get('statusActive') : config.get('statusInactive');
                            actuators.xv03.textStatus = element.value ? config.get('textActive') : config.get('textInactive');
                            break;
                        case 'rv1':
                            actuators.rv01.value = element.value;
                            actuators.rv01.status = element.value ? config.get('statusActive') : config.get('statusInactive');
                            actuators.rv01.textStatus = element.value ? config.get('textActive') : config.get('textInactive');
                            break;
                        case 'rv2':
                            actuators.rv02.value = element.value;
                            actuators.rv02.status = element.value ? config.get('statusActive') : config.get('statusInactive');
                            actuators.rv02.textStatus = element.value ? config.get('textActive') : config.get('textInactive');
                            break;
                        case 'rv3':
                            actuators.rv03.value = element.value;
                            actuators.rv03.status = element.value ? config.get('statusActive') : config.get('statusInactive');
                            actuators.rv03.textStatus = element.value ? config.get('textActive') : config.get('textInactive');
                            break;
                        case 'dp':
                            actuators.dp.value = element.value ? "ON" : "OFF";
                            actuators.dp.status = element.value ? config.get('statusActive') : config.get('statusInactive');
                            actuators.dp.textStatus = element.value ? config.get('textActive') : config.get('textInactive');
                            break;
                        default:
                            break;
                    }
                });
                store.dispatch({type:"ACTUATORS",payload: actuators});
            }
        })
    }

    sendCommand = (message) => {
        fetch(config.get('apiUrl')+'admin/command',{
            method: "POST",
            headers: {
                'Content-Type' : 'application/json',
                Authorization: 'Bearer '+ this.state.authUser.token
            },
            body: JSON.stringify({message: message, device:this.props.selectedDevice.deviceId})
        })
        .then(res=>res.json())
        .then((data)=> {
            console.log(data)
            
        })
      }

    saveMode = (mode) => {
        this.sendCommand({m:mode})
    }

    savePosition = (event, values) => {
        for (const [key, value] of Object.entries(values)){
            this.sendCommand({
                [key]: value
            })
        }
        this.setState({showPopover: false})
    }

    analyzeCoordinates = (e) => {
        console.log(e.clientX)
        console.log(e.clientY)

        const { t } = this.props;
        if (this.props.commandIn.c.m === 2 && this.props.commandIn.c.s === 4) {
            if (e.clientX > 435 && e.clientX < 473 && e.clientY > 252 && e.clientY < 276) { this.processOnOff("xv01","x1")}
            else if (e.clientX > 640 && e.clientX < 681 && e.clientY > 124 && e.clientY < 149) { this.processOnOff("dp","dp")}
            else if (e.clientX > 926 && e.clientX < 965 && e.clientY > 324 && e.clientY < 349) { this.processOnOff("xv02","x2")}
            else if (e.clientX > 654 && e.clientX < 690 && e.clientY > 552 && e.clientY < 576) { this.processOnOff("xv03","x3")}
            else if (e.clientX > 681 && e.clientX < 719 && e.clientY > 448 && e.clientY < 472) { this.processOnOff("p01","p1")}
            else if (e.clientX > 749 && e.clientX < 788 && e.clientY > 253 && e.clientY < 277) { this.showNumpad("r3","RV03 " + t('needle_valve.advanced.position'), this.props.actuators.rv03.value,"25,-580")}
            else if (e.clientX > 878 && e.clientX < 918 && e.clientY > 255 && e.clientY < 277) { this.showNumpad("r2","RV02 " + t('needle_valve.advanced.position'), this.props.actuators.rv02.value,"150,-580")}
            else if (e.clientX > 794 && e.clientX < 833 && e.clientY > 550 && e.clientY < 575) { this.showNumpad("r1","RV01 " + t('needle_valve.advanced.position'), this.props.actuators.rv01.value,"70,-270")}

        }

    }

    processOnOff = (actuator, key) => {
        this.sendCommand({[key]: this.props.actuators[[actuator]].value === "ON" ? 0 : 1})
    }

    showNumpad = (actuator, label, value, offset) => {
        console.log(value)
        this.setState({
            showPopover: true,
            keyboardField: actuator,
            popoverTitle: label,
            popoverOffset: offset,
            keyboardValue: value
        })
    }

    hideKeyboard = () => {
        this.setState({
          showKeyboard: false
        })
    }

    checkTime = (i) => {
        return (i < 10) ? "0" + i : i;
    }

    startTime = () => {
        this.setState({
            timeString: new Date().toLocaleTimeString('et-EE', {year: 'numeric', month: '2-digit', day: 'numeric', hour: '2-digit', minute: '2-digit', second: '2-digit'})
        })
    }


    

    enterKeyboardValue = (field, value) => {
        this.props.mqttClient.subscribe('commandOut',(err) => {
            if (!err){
                this.props.mqttClient.publish('commandOut',JSON.stringify({[field]:value}), (err) => {
                    if (!err){
                        this.props.mqttClient.unsubscribe('commandOut')
                        this.setState({
                            showKeyboard: false
                        })
                    }
                })
            }
            else {
                console.log(err)
            }
        })
      }

    render() {

        const { t } = this.props;

        if (this.props.selectedDevice === ""){
            return ''
        }

        return <Container className="page-container relative-container"> 
        <WebSocketConnection deviceId={this.props.selectedDevice.deviceId}/>
        <div className="mode-panel">
            <Container>
                <Row>
                    <Col><RoundButton title={t('mode.auto')} color={this.props.commandIn.c.m === 3 ? "button-active" : "button-inactive"} size="size-27 mode-button" onClick={() => this.saveMode("3")}/></Col>
                </Row>
                <Row>
                    <Col><RoundButton title={t('mode.on')} color={this.props.commandIn.c.m === 1 ? "button-active" : "button-inactive"} size="size-27 small-button float-left" onClick={() => this.saveMode("1")}/>
                    <RoundButton title={t('mode.off')} color={this.props.commandIn.c.m === 0 ? "button-active" : "button-inactive"}  size="size-27 small-button float-right" onClick={() => this.saveMode("0")}/></Col>
                </Row>
                <Row>
                    <Col><RoundButton title={t('mode.manual')} color={this.props.commandIn.c.m === 2 ? "button-active" : "button-inactive"} size="size-27 mode-button" onClick={() => this.saveMode("2")}/></Col>
                </Row>
            </Container>
        </div>
        <div className="emergency-panel">
        <Container>
                <Row>
                    <Col><RoundButton title={t('buttons.estop')} color="button-emergency" size="size-20 mode-button" onClick={() => this.saveMode("5")}/></Col>
                </Row>
                
            </Container>
        </div>
        <div className="logo"></div>
        
        {this.props.commandIn.c.e.length !== 0 ?  
        <div className="error-message">
        {this.props.commandIn.c.e.length === 1 ? 
        <Error code={this.props.commandIn.c.e[0].c} sensor={this.props.commandIn.c.e[0].s} value={this.props.commandIn.c.e[0].v} time={this.props.commandIn.c.e[0].t}/>
        : ''}
        {this.props.commandIn.c.e.length > 1 ? 
        <Carousel interval={null}>
            {this.props.commandIn.c.e.map((error, index) => 
            <Carousel.Item>
                <Error code={error.c} sensor={error.s} value={error.v} time={error.t}/>
            </Carousel.Item>)
            }
            
        </Carousel> : ''
        }
    </div>
        : '' }
        <Row className="schematics" onClick={(e) => {this.analyzeCoordinates(e)}}>
            <Col>
            <div className="web-status-panel">
            <div className="status-panel">
                {(this.props.commandIn.c.m === 3 || this.props.commandIn.c.m === 1 ) || this.props.commandIn.c.s !== 4? <div className="message-area">{t('status.state')}: <b>{t(`status.${this.props.commandIn.c.s}`)}</b></div> : ''}
                <div className="online-area">{Date.now() - this.props.lastUpdate < 2000 ? t('status.online') : t('status.offline')}</div>
                <div className="time">{this.state.timeString}</div>
            </div>
            {this.props.configuration.g.mtd === 1 ? 
            <div className="motor-temp-panel">
                <b>Motor</b>: {this.props.sensors.ect02.t.value}&deg;C
            </div> : ''
            }
            {this.props.configuration.g.ctd === 1 ? 
            <div className="controller-temp-panel">
                <b>Controller</b>: {this.props.sensors.ect03.t.value}&deg;C
            </div> : ''
            }
            {this.props.commandIn.c.s !== 4 && (this.props.commandIn.c.m === 0 || this.props.commandIn.c.m === 2) ? 
                <div className="changing-mode">
                    <b>{ t('messages.changing_mode')}</b>
                </div>
                :
            ''}
            <div className="units-panel">
                <b>Unit Legend</b>: PS: bar; ECT: &#956;S; FL: l/min
            </div>
            </div>
            {Date.now() - this.props.lastUpdate > 2000 ?
            <div className="web-offline-panel">
                <div className="offline-status-panel">
                    {t('status.last_update')}: {this.props.lastUpdate ? moment(this.props.lastUpdate).format("DD.MM.YYYY HH:mm:ss") : <span>N/A</span>}
                </div>
            </div> : ''
           }
           <Schematics id="schematics" input={this.props.sensors} actuators={this.props.actuators} config={this.props.configuration} mode={this.props.commandIn.c.m} toggleStatus={this.processOnOff}/>
           {this.props.configuration.g.o1 ? <OptionOne input={this.props.sensors} actuators={this.props.actuators} config={this.props.configuration.g} mode={this.props.commandIn.c.m} toggleStatus={this.processOnOff}/> : '' }
           {this.props.configuration.g.o2 ? <OptionTwo input={this.props.sensors} actuators={this.props.actuators} config={this.props.configuration} mode={this.props.commandIn.c.m}/> : '' }
           {this.props.configuration.g.o3 ? <OptionThree input={this.props.sensors} actuators={this.props.actuators} config={this.props.configuration.g} mode={this.props.commandIn.c.m} toggleStatus={this.processOnOff}/> : '' }
           {this.props.configuration.g.o4 ? <OptionFour input={this.props.sensors} actuators={this.props.actuators} config={this.props.configuration} mode={this.props.commandIn.c.m}/> : '' }
           {this.props.configuration.g.l3s ? <OptionFive input={this.props.sensors} actuators={this.props.actuators} config={this.props.configuration} mode={this.props.commandIn.c.m}/> : '' }
           {this.props.configuration.g.p5s ? <OptionSix input={this.props.sensors} actuators={this.props.actuators} config={this.props.configuration} mode={this.props.commandIn.c.m}/> : '' }
           {this.props.configuration.x.dp ? <OptionSeven input={this.props.sensors} actuators={this.props.actuators} mode={(this.props.commandIn.c.m === 2 && this.props.commandIn.c.s === 4) ? 2 : 0} config={this.props.configuration.g} toggleStatus={this.processOnOff}/> : '' }

            </Col>
        </Row>
        <Popover placement="top" hideArrow={true} isOpen={this.state.showPopover} target="schematics" offset={this.state.popoverOffset}>
        <PopoverHeader>{this.state.popoverTitle}</PopoverHeader>
        <PopoverBody>
        <AvForm onValidSubmit={this.savePosition}>
            <Container>
                <Row>
                    <Col>
                    <AvField
                            name={this.state.keyboardField}
                            label="Value"
                            grid={{sm:9}}
                            value={this.state.keyboardValue}
                            type="text"
                            errorMessage="Value is mandatory"
                            validate={{
                              required: { value: true }
                            }}
                          />
                    </Col>
                </Row>
                <Row>
                            <label
                            className="col-sm-3"
                            >
                            </label>
                            <Col sm={9}>
                            <Button color="secondary" className="mr-1" onClick={()=>this.setState({showPopover: false})}>Cancel</Button>    
                            <Button color="primary" className="mr-1">Save</Button>
                            </Col>
                        </Row>
            </Container>
                        </AvForm>
        </PopoverBody>
      </Popover>
        </Container>
    }
}

function mapStateToProps(state){
    return {
      sensors: state.Device.sensors,
      actuators: state.Device.actuators,
      configuration: state.Device.config,
      commandIn: state.Device.commandIn,
      selectedDevice: state.Device.selectedDevice,
      lastUpdate: state.Device.lastUpdate
    }
  }
  
  export default withTranslation('common')(connect(
    mapStateToProps
  )(Device))
