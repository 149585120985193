import React, { Component } from "react";
import { Row, Col } from "reactstrap";
import { Link } from "react-router-dom";



class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <React.Fragment>
        <div className="container-fluid">
          <Row className="align-items-center">
            <Col >
              <div className="page-title-box">
                <h4 className="font-size-18">Töölaud</h4>
                <ol className="breadcrumb mb-0">
                  <li className="breadcrumb-item active">
                    Tere tulemast Nanofixi haldusliidesesse.
                  </li>
                </ol>
              </div>
            </Col>

          </Row>

        </div>
      </React.Fragment>
    );
  }
}

export default Dashboard;
