import React, { useEffect} from 'react';
import { useTranslation } from "react-i18next";
import { updateConfiguration, updateParameters, updateParameter } from '../../../../helpers/utils'
import { useSelector } from 'react-redux';
import { useForm, Controller } from 'react-hook-form'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'


export const PressureSensorRanges = (props) => {
    const { t } = useTranslation('common')
    const editConfig = useSelector(state => state.Device.config)
    const sensors = useSelector(state => state.Device.sensors)
    const { handleSubmit, control, setValue, getValues } = useForm({mode:"onSubmit",reValidateMode:"onSubmit"})

    const pressureSensors = [{
        title: 'ps1',
        sensor: 'ps01',
        key: 'p1'
        },
        {title: 'ps2',
        sensor: 'ps02',
        key: 'p2'
        },
        {title: 'ps3',
        sensor: 'ps03',
        key: 'p3'
        },
        {title: 'ps4',
        sensor: 'ps04',
        key: 'p4'
        }]

    const onSubmit = () => {

    }

    useEffect(() => {
        [ 
            { name: 'p1-n', value: editConfig.p.p1.n },
            { name: 'p1-nl', value: editConfig.p.p1.nl },
            { name: 'p1-nh', value: editConfig.p.p1.nh },
            { name: 'p1-wl', value: editConfig.p.p1.wl },
            { name: 'p1-wh', value: editConfig.p.p1.wh },
            { name: 'p2-n', value: editConfig.p.p2.n },
            { name: 'p2-nl', value: editConfig.p.p2.nl },
            { name: 'p2-nh', value: editConfig.p.p2.nh },
            { name: 'p2-wl', value: editConfig.p.p2.wl },
            { name: 'p2-wh', value: editConfig.p.p2.wh },
            { name: 'p3-n', value: editConfig.p.p3.n },
            { name: 'p3-nl', value: editConfig.p.p3.nl },
            { name: 'p3-nh', value: editConfig.p.p3.nh },
            { name: 'p3-wl', value: editConfig.p.p3.wl },
            { name: 'p3-wh', value: editConfig.p.p3.wh },
            { name: 'p4-n', value: editConfig.p.p4.n },
            { name: 'p4-nl', value: editConfig.p.p4.nl },
            { name: 'p4-nh', value: editConfig.p.p4.nh },
            { name: 'p4-wl', value: editConfig.p.p4.wl },
            { name: 'p4-wh', value: editConfig.p.p4.wh },
         ].forEach(({ name, value }) => setValue(name, value))

      }, [editConfig]);
     
  
    return (
      <form onSubmit={handleSubmit(onSubmit)}>
          <Container className="sensor-table">
              <Row>
                  <Col md="3"></Col>
                  <Col md="4" className="column-title">{t('pressure.pressure_ranges.pressure')}<br/><span className="unit-text">({t('pressure.pressure_ranges.bar')})</span></Col>
                  <Col md="4" className="column-title">{t('pressure.pressure_ranges.tolerances')}<br/><span className="unit-text">({t('pressure.pressure_ranges.bar')})</span></Col>
              </Row>
              <Row>
                  <Col md="7"></Col>
                  <Col md="2" className="column-sub-title digit-small">{t('pressure.pressure_ranges.normal')}</Col>
                  <Col md="2" className="column-sub-title digit-small">{t('pressure.pressure_ranges.warning')}</Col>
              </Row>
              <Row>
                  <Col md="7"></Col>
                  <Col className="unit-text digit-small" md="1">-</Col>
                  <Col className="unit-text remove-padding" md="1">+</Col>
                  <Col className="unit-text digit-small" md="1">-</Col>
                  <Col className="unit-text remove-padding" md="1">+</Col>
              </Row>
          { pressureSensors.map((sensor, index) => 
              <Row key={index}>
                <Col md="3" className="parameter-text"><span><div className="sensor-title">{sensor.title.toUpperCase()}</div>  <div className="current-reading">{sensors[sensor.sensor].value}</div></span></Col>
                <Col md="4" className="text-center"><Controller 
                          render={({ onChange }) => (
                            <Form.Control 
                                as="input"
                                className="input-digit-big"
                                id={`${sensor.key}-n`}
                                onChange={(e) => updateParameter(editConfig, "p", `${sensor.key}-n`, e.target.value)}
                                value={getValues(`${sensor.key}-n`)}
                                />
                          )} 
                          name={`${sensor.key}-n`}
                          control={control}
                      />
                </Col>
                <Col md="2" className="digit-small"><Controller 
                          render={({ onChange }) => (
                            <Form.Control 
                                as="input"
                                className="input-digit-small margin-right-4px"
                                id={`${sensor.key}-nl`} 
                                onChange={(e) => updateParameter(editConfig, "p", `${sensor.key}-nl`, e.target.value)}

                                value={getValues(`${sensor.key}-nl`)}/>
                          )} 
                          name={`${sensor.key}-nl`}
                          control={control}
                      />
                      <Controller 
                          render={({ onChange }) => (
                            <Form.Control 
                                as="input" 
                                className="input-digit-small"
                                id={`${sensor.key}-nh`}
                                onChange={(e) => updateParameter(editConfig, "p", `${sensor.key}-nh`, e.target.value)}

                                value={getValues(`${sensor.key}-nh`)}/>
                          )} 
                          name={`${sensor.key}-nh`}
                          control={control}
                      />
                </Col>
                <Col md="2" className="digit-small"><Controller 
                          render={({ onChange }) => (
                            <Form.Control 
                                as="input"
                                className="input-digit-small margin-right-4px"
                                id={`${sensor.key}-wl`}
                                onChange={(e) => updateParameter(editConfig, "p", `${sensor.key}-wl`, e.target.value)}

                                value={getValues(`${sensor.key}-wl`)}/>
                          )} 
                          name={`${sensor.key}-wl`}
                          control={control}
                      />
                      <Controller 
                          render={({ onChange }) => (
                            <Form.Control 
                                as="input" 
                                className="input-digit-small"
                                id={`${sensor.key.key}-wh`}
                                onChange={(e) => updateParameter(editConfig, "p", `${sensor.key}-wh`, e.target.value)}

                                value={getValues(`${sensor.key}-wh`)}/>
                          )} 
                          name={`${sensor.key}-wh`}
                          control={control}
                      />
                </Col>
              </Row>
          )}
              
            </Container>
           
      </form>
      
    )
  }