import React from 'react';
import config from 'react-global-configuration';


export default class OptionSix extends React.Component {


    getBackgroundColor = (levels, value) => {
      if (value === "N/A") {return config.get('statusInactive')}
      if ((value > (levels.n - levels.nl)) && (value < (levels.n + levels.nh))){return config.get('statusGreen')}
      else if ((value > (levels.n - levels.nl - levels.wl)) && (value < (levels.n + levels.nh + levels.wh))){return config.get('statusYellow')}
      else {return config.get('statusRed')}
      
    }

  render () { 

    return (
<svg width="960" height="600" viewBox="0 0 960 600">
  <defs>
    <clipPath id="clip-option-6">
      <rect width="960" height="600"/>
    </clipPath>
  </defs>
  <g id="option-6" clip-path="url(#clip-option-6)">
    <rect width="960" height="600" fill="none"/>
    <g id="Reading-label-17" data-name="Reading-label" text-anchor="middle" transform="translate(895 395)">
      <g id="PS05BG" fill={this.props.input.ps05.status}>
        <path d="M 43 25.5 L 5 25.5 C 2.51869010925293 25.5 0.5 23.48130989074707 0.5 21 L 0.5 5 C 0.5 2.51869010925293 2.51869010925293 0.5 5 0.5 L 43 0.5 C 45.4813117980957 0.5 47.5 2.51869010925293 47.5 5 L 47.5 21 C 47.5 23.48130989074707 45.4813117980957 25.5 43 25.5 Z" stroke="none"/>
        <path d="M 5 1 C 2.794391632080078 1 1 2.794389724731445 1 5 L 1 21 C 1 23.20561027526855 2.794391632080078 25 5 25 L 43 25 C 45.20560836791992 25 47 23.20561027526855 47 21 L 47 5 C 47 2.794389724731445 45.20560836791992 1 43 1 L 5 1 M 5 0 L 43 0 C 45.76142120361328 0 48 2.238580703735352 48 5 L 48 21 C 48 23.76141929626465 45.76142120361328 26 43 26 L 5 26 C 2.238571166992188 26 0 23.76141929626465 0 21 L 0 5 C 0 2.238580703735352 2.238571166992188 0 5 0 Z" stroke="none" fill="#d8d2d2"/>
      </g>
      <text id="PS05" transform="translate(22 18)" fill="#0a0909" font-size="14" font-family="Roboto-Bold, Roboto" font-weight="700" letter-spacing="0.01em"><tspan x="0" y="0">{this.props.input.ps05.value}</tspan></text>
    </g>
    <g id="Group_11" data-name="Group 11" transform="translate(846.724 230)">
      <g id="sensor" transform="translate(52 195)">
        <g id="Ellipse_1" data-name="Ellipse 1" transform="translate(1.5 2)" fill="#fff" stroke="#242323" stroke-width="1">
          <circle cx="18.5" cy="18.5" r="18.5" stroke="none"/>
          <circle cx="18.5" cy="18.5" r="18" fill="none"/>
        </g>
        <line id="Line_1" data-name="Line 1" x2="39" transform="translate(0.5 20)" fill="none" stroke="#242323" stroke-width="1"/>
        <g id="Rectangle_1" data-name="Rectangle 1" fill="none" stroke="#242323" stroke-width="1">
          <rect width="40" height="40" stroke="none"/>
          <rect x="0.5" y="0.5" width="39" height="39" fill="none"/>
        </g>
      </g>
      <g id="Group_45" data-name="Group 45">
        <text id="PS" transform="translate(65 212)" fill="#242323" font-size="12" font-family="Roboto-Bold, Roboto" font-weight="700" letter-spacing="0.01em"><tspan x="0" y="0">PS</tspan></text>
        <text id="_5" data-name="5" transform="translate(72 228)" fill="#242323" font-size="12" font-family="Roboto-Bold, Roboto" font-weight="700" letter-spacing="0.01em"><tspan x="-3.442" y="0">5</tspan></text>
      </g>
    </g>
    <line id="Line_20" data-name="Line 20" y2="15" transform="translate(918 463)" fill="none" stroke="#242323" stroke-width="1"/>
  </g>
</svg>


);
}
}