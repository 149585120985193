import React from 'react';
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Datetime from 'react-datetime';
import "react-datetime/css/react-datetime.css";
import {withTranslation} from "react-i18next";
import RoundButton from './RoundButton';
import moment from 'moment'

class DateTime extends React.Component {

    constructor(props){
        super(props)
        this.state = {
            field: props.field, 
            label: props.label,
            value: props.value === 'N/A' ? '' : props.value
        }
    }

    onChange = input => {
        this.setState(
            {
                value: input
            })
    }

    onChangeInput = event => {
        let input = event.target.value;
        this.setState(
        {
            value: input
        },
        () => {
            this.keyboard.setInput(input);
        }
        )
      }

    cancelKeyboard = () => {
        this.props.cancelKeyboard()
    }

    enterKeyboardValue = () => {
        this.props.enterValue(this.state.field, this.state.value.format("DD.MM.YYYY HH:mm"))
    }

    onKeyPress = (button) => {      

        if (button === "{shift}" || button === "{lock}") this.handleShift();
        if (button === "{numbers}" || button === "{abc}") this.handleNumbers();
        if (button === "{symbols}") this.handleSymbols();
        if (button === "{letters}") this.handleLetters(); 
    }

    handleShift = () => {
        let currentLayout = this.keyboard.options.layoutName;
        let shiftToggle = currentLayout === "default" ? "shift" : "default";
      
        this.keyboard.setOptions({
          layoutName: shiftToggle
        });
    }
      
    handleNumbers = () => {
        let currentLayout = this.keyboard.options.layoutName;
        let numbersToggle = currentLayout !== "numbers" ? "numbers" : "default";
        
        this.keyboard.setOptions({
            layoutName: numbersToggle
        });
    }

    handleSymbols = () => {
        let currentLayout = this.keyboard.options.layoutName;
        let symbolsToggle = currentLayout !== "symbols" ? "symbols" : "numbers";
        
        this.keyboard.setOptions({
            layoutName: symbolsToggle
        });
    }

    handleLetters = () => {
        let currentLayout = this.keyboard.options.layoutName;
        
        this.keyboard.setOptions({
            layoutName: "default"
        });
    }

  
      render() {

        const { t } = this.props;

        const numpad = {
            default: ["1 2 3", "4 5 6", "7 8 9", "{backspace} 0 ."]
        }
        const keyboard = {
            default: [
                "q w e r t y u i o p",
                "a s d f g h j k l",
                "{shift} z x c v b n m",
                "{numbers} {space} {backspace}"
              ],
              shift: [
                "Q W E R T Y U I O P",
                "A S D F G H J K L",
                "{shift} Z X C V B N M {backspace}",
                "{numbers} {space} {ent}"
              ],
              numbers: [
                  "1 2 3 4 5 6 7 8 9 0", 
                  "- / : ; ( ) $ & @ \"",
                  "{symbols} . , ? ! '",
                  "{abc} {space} {backspace}"
               ],
             symbols: [
                "[ ] { } # % x * + =", 
                "_ \ | ~ < > € $ § `",
                "{numbers} . , ? ! '",
                "{letters} {space} {backspace}"
              ]            
        }

        return <div className="keyboard-background">
            <Container className="numeric-keyboard">
                <Row key={this.state.field}>
                    {this.state.field !== "" ? <Col className="parameter-text">{t('keyboard.field_name')}: {this.state.label}</Col> : ''}
                </Row>
                <Row>
                    <Col md="6">
                    <Datetime 
                                  id="date"
                                  value={moment(this.state.value,"DD.MM.YYYY HH:mm")}
                                  dateFormat="DD.MM.YYYY"
                                  timeFormat={false}
                                  open={true}
                                  onChange={(value) => {
                                      this.setState({
                                          value: value
                                      })
                                  }}/>
                    </Col>
                    <Col md="6">
                    <Datetime 
                                  id="time"
                                  value={moment(this.state.value,"DD.MM.YYYY HH:mm")}
                                  dateFormat={false}
                                  open={true}
                                  timeFormat="HH:mm"
                                  onChange={(value) => {
                                      this.setState({
                                          value: value
                                      })
                                  }}/>
                    </Col>
                </Row>
               
            <Row>
                <Col md="6"></Col>
                <Col md="6" className="date-buttons"><RoundButton title={t('keyboard.enter')} color="button-primary float-right" size="size-20 regular-button" onClick={this.enterKeyboardValue}/>
                  <RoundButton title={t('keyboard.cancel')} color="button-secondary float-right" size="size-20 regular-button" onClick={this.cancelKeyboard}/>
                </Col>
            </Row>
        </Container>
        </div>
    }
}

export default withTranslation('common')(DateTime)