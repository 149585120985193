import React, {useEffect, memo} from 'react';
import { useForm, Controller } from 'react-hook-form'
import { useTranslation } from "react-i18next";
import { useSelector } from 'react-redux';
import { updateParameter } from '../../../../helpers/utils'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'

export const GeneralDisplaySettingsForm = memo(props => {
    const { handleSubmit, control, setValue, getValues } = useForm({mode:"onSubmit",reValidateMode:"onSubmit"})
    const { t } = useTranslation('common')
    const editConfig = useSelector(state => state.Device.config)
    const onSubmit = values => {
  
    }

    useEffect(() => {
        [ 
            { name: 'mtd', value: editConfig.g.mtd },
            { name: 'ctd', value: editConfig.g.ctd },
            { name: 'dtd', value: editConfig.g.dtd },
            { name: 'tl', value: editConfig.g.tl },
          ].forEach(({ name, value }) => setValue(name, value))
        
      }, [editConfig]);
  
  
    return (
      <form onSubmit={handleSubmit(onSubmit)}>
          <Container>
              
              <Row>
                <Col md="9" className="parameter-text">{t('general.display_settings.motor_temp')}</Col>
                <Col md="3">
                    <Controller 
                          render={({ onChange }) => (
                              <Form.Check 
                                  id="mtd"
                                  type="switch"
                                  checked={getValues("mtd") ? true: false}
                                  onChange={(value) => {
                                      updateParameter(editConfig, "g", "mtd", value.target.checked ? 1 : 0)
                                  }}/>
                          )} 
                          name="mtd"
                          control={control}
                      />
                  </Col>
              </Row>
              <Row>
                <Col md="9" className="parameter-text">{t('general.display_settings.cont_temp')}</Col>
                <Col md="3">
                    <Controller 
                          render={({ onChange }) => (
                              <Form.Check 
                                  id="ctd"
                                  type="switch"
                                  checked={getValues("ctd") ? true: false}
                                  onChange={(value) => {
                                      updateParameter(editConfig, "g", "ctd", value.target.checked ? 1 : 0)
                                  }}/>
                          )} 
                          name="ctd"
                          control={control}
                      />
                  </Col>
              </Row>
              <Row>
                <Col md="9" className="parameter-text">{t('general.display_settings.date_disp')}</Col>
                <Col md="3">
                    <Controller 
                          render={({ onChange }) => (
                              <Form.Check 
                                  id="dtd"
                                  type="switch"
                                  checked={getValues("dtd") ? true: false}
                                  onChange={(value) => {
                                      updateParameter(editConfig, "g", "dtd", value.target.checked ? 1 : 0)
                                  }}/>
                          )} 
                          name="dtd"
                          control={control}
                      />
                  </Col>
              </Row>
              <Row>
                <Col md="9" className="parameter-text">{t('general.display_settings.touch_lock')}</Col>
                <Col md="3">
                    <Controller 
                          render={({ onChange }) => (
                              <Form.Check 
                                  id="tl"
                                  type="switch"
                                  checked={getValues("tl") ? true: false}
                                  onChange={(value) => {
                                      updateParameter(editConfig, "g", "tl", value.target.checked ? 1 : 0)
                                  }}/>
                          )} 
                          name="tl"
                          control={control}
                      />
                  </Col>
              </Row>
              
            </Container>
  
  
      </form>
    )
  })