import React, {useState, memo} from 'react';
import { useTranslation } from "react-i18next";
import Tabs from 'react-bootstrap/Tabs'
import Tab from 'react-bootstrap/Tab'
import { DefaultPosition } from './DefaultPosition';
import { Calibration } from './Calibration';
import { Advanced } from './Advanced'



const NeedleValve = memo(props => {
  const { t } = useTranslation('common')
  const [key, setKey] = useState(props.tabIndex)

  const processTabSelect = (k) => {
    setKey(k)
    props.setTabIndex(k)
  }

    return ( <Tabs defaultActiveKey="0" activeKey={key} id="general" onSelect={(k) => processTabSelect(k)}>
              <Tab eventKey="0" title={t('needle_valve.tabs.default_position')}>
                <DefaultPosition showKeyboard={props.showKeyboard} />
              </Tab>
              {/* <Tab className="d-none" eventKey="1" title={t('needle_valve.tabs.calibration')}>
                <Calibration  t={t} mqttClient={this.props.mqttClient} showKeyboard={this.keyboardCallback} keyboardValue={this.state.keyboardValue} keyboardField={this.state.keyboardField} config={this.props.editConfig.r} generalConfig={this.props.config.g} updateConfiguration={this.updateConfig} forceUpdate={this.state.update}/>
              </Tab> */}
              <Tab eventKey="2" title={t('needle_valve.tabs.advanced')}>
                <Advanced  showKeyboard={props.showKeyboard}/>
              </Tab>
            </Tabs>
        
    )
})

  
  export default NeedleValve
