import React, {useState, memo} from 'react';
import { useTranslation } from "react-i18next";
import Tabs from 'react-bootstrap/Tabs'
import Tab from 'react-bootstrap/Tab'
import { PressureSensorRanges } from './SensorRanges';
import { PressureSensorI2CAddresses } from './I2CAddresses';
import { PressureAdvanced} from './Advanced'

const Pressure = memo(props => {
  const { t } = useTranslation('common')
  const [key, setKey] = useState(props.tabIndex)

  const processTabSelect = (k) => {
    setKey(k)
    props.setTabIndex(k)
  }

    return ( 
            <Tabs defaultActiveKey="0" activeKey={key} id="general" onSelect={(k) => processTabSelect(k)}>
              <Tab eventKey="0" title={t('pressure.tabs.sensor_ranges')}>
                <PressureSensorRanges/>
              </Tab>
              <Tab eventKey="1" title={t('pressure.tabs.sensor_i2c_addresses')}>
                <PressureSensorI2CAddresses />
              </Tab>
              <Tab eventKey="2" title={t('pressure.tabs.advanced')}>
                <PressureAdvanced/>
              </Tab>
            </Tabs>
      
    )
})

export default Pressure
