import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";
import MetisMenu from "metismenujs";
import Select from 'react-select';
import config from 'react-global-configuration';

import { connect } from 'react-redux'
import  store  from '../../store/index'
import SimpleBar from "simplebar-react";

const SidebarContent = props => {
  
  const handleDeviceChange = (selectedDevice) => {
    props.updateDevice(selectedDevice)
  }

  return (
    <div id="sidebar-menu">
      <ul className="metismenu list-unstyled" id="side-menu">
        <li className="menu-title">Main</li>

        <li>
          <Link to="/dashboard" className="waves-effect">
            <i className="mdi mdi-home"></i>
            <span>Dashboard</span>
          </Link>
        </li>

        <li>
          <Link to="/devices" className=" waves-effect">
            <i className="mdi mdi-google-controller"></i>
            <span>Devices</span>
          </Link>
        </li>

        <li>
          <Link to="/users" className=" waves-effect">
            <i className="mdi mdi-account"></i>
            <span>Users</span>
          </Link>
        </li>
        <li className="select-menu">
        <Select
                        value={props.selectedDevice}
                        isClearable={false}
                        onChange={handleDeviceChange}
                        options={props.devices}
                      />
        </li>
        <li className="menu-title"></li>
        <li>
          <Link to="/controlpanel" className=" waves-effect">
            <i className="mdi mdi-camera-control"></i>
            <span>Control panel</span>
          </Link>
        </li>
        <li>
          <Link to="/#" className="has-arrow waves-effect">
            <i className="mdi mdi-settings"></i>
            <span>Settings</span>
          </Link>
          <ul className="sub-menu" aria-expanded="false">
            <li>
              <Link to="/settings/general">General</Link>
            </li>
            <li>
              <Link to="/settings/pressure">Pressure</Link>
            </li>
            <li>
              <Link to="/settings/conductivity">Conductivity</Link>
            </li>
            <li>
              <Link to="/settings/valve">Valve, 5V</Link>
            </li>
            <li>
              <Link to="/settings/needlevalve">Needle Valve</Link>
            </li>
            <li>
              <Link to="/settings/flow">Flow</Link>
            </li>
          </ul>
        </li>

        <li>
          <Link to="/#" className="has-arrow waves-effect">
            <i className="mdi mdi-gauge"></i>
            <span>Readings</span>
          </Link>
          <ul className="sub-menu" aria-expanded="false">
            <li>
              <Link to="/readings/1">Pressure</Link>
            </li>
            <li>
              <Link to="/readings/2">Conductivity</Link>
            </li>
            <li>
              <Link to="/readings/3">Level</Link>
            </li>
            <li>
              <Link to="/readings/4">Flow</Link>
            </li>
            {/* <li>
              <Link to="temperaturereadings">Temperature</Link>
            </li> */}
          </ul>
        </li>
        <li>
          <Link to="/errors" className=" waves-effect">
            <i className="mdi mdi-alert"></i>
            <span>Errors</span>
          </Link>
        </li>
        <li>
          <Link to="/maintenance" className=" waves-effect">
            <i className="mdi mdi-room-service"></i>
            <span>Maintenance</span>
          </Link>
        </li>

      </ul>
    </div>
  );
};

class Sidebar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedDevice: this.props.selectedDevice
    };
  }

  componentDidMount() {
    this.initMenu();
    this.getDevices();
  }

  componentDidUpdate(prevProps) {
    if (this.props.type !== prevProps.type) {
      this.initMenu();
    }
  }


  setSelectedDevice = (device) => {
    store.dispatch({type:"SELECTED_DEVICE",payload: device})
    console.log(device)
    this.getConfiguration(device.value);
    this.getDeviceSensors(device.value);

  }

  getConfiguration = (device) => {
    fetch(config.get('apiUrl')+'admin/latestConfiguration/'+device,{
        headers: {
            Authorization: 'Bearer '+ JSON.parse(sessionStorage.getItem('authUser')).token
        }
    })
    .then(res=>res.json())
    .then((data)=> {
        console.log(JSON.parse(data.configuration))
        store.dispatch({type:"STORE_CONFIG",payload: JSON.parse(data.configuration)})
    })
}

  getDeviceSensors = (device) => {
    fetch(config.get('apiUrl')+'admin/device/'+device+'/sensors',{
      headers: {
        Authorization: 'Bearer '+ JSON.parse(sessionStorage.getItem('authUser')).token
      }
    })
      .then(res=>res.json())
      .then((data)=> {
        console.log(data)
        store.dispatch({type:"DEVICE_SENSORS",payload: data})

      });
  }

  getDevices = () => {
    fetch(config.get('apiUrl')+'admin/devices',{
      headers: {
        Authorization: 'Bearer '+ JSON.parse(sessionStorage.getItem('authUser')).token
      }
    })
      .then(res=>res.json())
      .then((data)=> {
        let availableDevices = [];
        Object.keys(data).forEach(key => {
          availableDevices.push({
            value: data[key].id,
            label: data[key].name,
            deviceId: data[key].deviceId
          }
          );
        });
        console.log(availableDevices)
        store.dispatch({type:"DEVICES",payload: availableDevices})
        if (this.props.selectedDevice === ""){
          store.dispatch({type:"SELECTED_DEVICE",payload: availableDevices[0]})
          this.getDeviceSensors(availableDevices[0].value)
          this.getConfiguration(availableDevices[0].value)
        }
        

      });
  }

  initMenu() {
    if (this.props.type !== "condensed" || this.props.isMobile) {
      new MetisMenu("#side-menu");

      var matchingMenuItem = null;
      var ul = document.getElementById("side-menu");
      var items = ul.getElementsByTagName("a");
      for (var i = 0; i < items.length; ++i) {
        if (this.props.location.pathname === items[i].pathname) {
          matchingMenuItem = items[i];
          break;
        }
      }
      if (matchingMenuItem) {
        this.activateParentDropdown(matchingMenuItem);
      }
    }
  }

  activateParentDropdown = item => {
    item.classList.add("mm-active");
    const parent = item.parentElement;

    if (parent) {
      parent.classList.add("mm-active"); // li
      const parent2 = parent.parentElement;

      if (parent2) {
        parent2.classList.add("mm-show");

        const parent3 = parent2.parentElement;

        if (parent3) {
          parent3.classList.add("mm-active"); // li
          parent3.childNodes[0].classList.add("mm-active"); //a
          const parent4 = parent3.parentElement;
          if (parent4) {
            parent4.classList.add("mm-active");
          }
        }
      }
      return false;
    }
    return false;
  };

  render() {
    return (
      <React.Fragment>
        {this.props.type !== "condensed" ? (
          <SimpleBar style={{ maxHeight: "100%" }}>
            <SidebarContent devices={this.props.devices} selectedDevice={this.props.selectedDevice} updateDevice={this.setSelectedDevice} />
          </SimpleBar>
        ) : (
          <SidebarContent />
        )}
      </React.Fragment>
    );
  }
}
function mapStateToProps(state){
  return {
    sensors: state.Device.sensors,
    actuators: state.Device.actuators,
    errors: state.Device.errors,
    config: state.Device.config,
    editConfig: state.Device.config,
    devices: state.Device.devices,
    selectedDevice: state.Device.selectedDevice
  }
}

export default withRouter((connect(
  mapStateToProps
)(Sidebar)))
