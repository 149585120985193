import React, {useState, memo} from 'react';
import Container from 'react-bootstrap/Container'
import Col from 'react-bootstrap/Col'
import Tabs from 'react-bootstrap/Tabs'
import Tab from 'react-bootstrap/Tab'
import { connect } from 'react-redux'
import SettingsFooter from '../../../../components/Footer';
import  store  from '../../../../store/index'
import {withTranslation} from "react-i18next";
import { Connection } from './Connection';
import { Wifi } from './Wifi';



class Online extends React.Component {

    constructor(props){
        super(props)

        this.state = {
          showKeyboard: false,
          keyboardField: "",
          keyboardLabel: "",
          keyboardValue: "",
          forceUpdate: false
        }

    }

    keyboardCallback = (field, label, value) => {
       this.setState({
         showKeyboard: true,
         keyboardField: field,
         keyboardLabel: label, 
         keyboardValue: value
       })
    }

    updateConfig = (element, configuration) => {
      var newConfig = this.props.editConfig
      newConfig[element] = configuration
      store.dispatch({type:"EDIT_CONFIG",payload: newConfig})
      this.setState({forceUpdate: !this.state.forceUpdate})
    }

    hideKeyboard = () => {
      this.setState({
        showKeyboard: false
      })
    }

    enterKeyboardValue = (field, value) => {
      console.log("entervalue")
      console.log(field)
      console.log(value)
      this.setState({
        keyboardField: field,
        keyboardValue: value,
        showKeyboard: false
      })
    }

    render() {

        const { t } = this.props;

        return <Container className="page-container"> 
            <Col className="content">
            <Tabs defaultActiveKey="connection" id="connection">
              <Tab eventKey="connection" title={t('online.tabs.connection')}>
                <Connection t={t} showKeyboard={this.keyboardCallback} keyboardValue={this.state.keyboardValue} keyboardField={this.state.keyboardField} config={this.props.editConfig.o} updateConfiguration={this.updateConfig} forceUpdate={this.state.update}/>
              </Tab>
              <Tab eventKey="wifi" title={t('online.tabs.wifi')}>
                <Wifi t={t} showKeyboard={this.keyboardCallback} keyboardValue={this.state.keyboardValue} keyboardField={this.state.keyboardField} config={this.props.editConfig.o} updateConfiguration={this.updateConfig} forceUpdate={this.state.update}/>
              </Tab>
            </Tabs>
            <SettingsFooter />
            </Col>
        </Container>
        
    }
}

function mapStateToProps(state){
    return {
      sensors: state.Device.sensors,
      actuators: state.Device.actuators,
      errors: state.Device.errors,
      config: state.Device.config,
      editConfig: state.Device.config
    }
  }
  
  export default withTranslation('common')(connect(
    mapStateToProps
  )(Online))
