import React, { useEffect, memo} from 'react';
import { useTranslation } from "react-i18next";
import { useSelector } from 'react-redux';
import { updateConfiguration, updateParameters, updateParameter } from '../../../../helpers/utils'
import { useForm, Controller } from 'react-hook-form'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'

export const PressureAdvanced = memo(props => {
    const { handleSubmit, control, setValue, getValues } = useForm({mode:"onSubmit",reValidateMode:"onSubmit"})
    const { t } = useTranslation('common')
    const editConfig = useSelector(state => state.Device.config)
    const onSubmit = values => {
  
    }

  
    useEffect(() => {
        [ 
            { name: 'ar', value: editConfig.p.ar },
            { name: 'md', value: editConfig.p.md },
            { name: 'p5-s', value: editConfig.p.p5.s },
            { name: 'p5-f', value: editConfig.p.p5.f },
          ].forEach(({ name, value }) => setValue(name, value))
        
      }, [editConfig]);
  
  
    return (
      <form onSubmit={handleSubmit(onSubmit)}>
          <Container>
              <Row className="d-none">
                <Col md="7" className="parameter-text">{t('pressure.advanced.auto_reset')}</Col>
                <Col md="2">
                    <Controller 
                          render={({ onChange }) => (
                            <Form.Control 
                                as="input"
                                className="input-digit-big"
                                id="ar"
                                onChange={(e) => updateParameter(editConfig, "p", "ar", e.target.value)}
                                value={getValues("ar")}
                                />
                          )} 
                          name="ar"
                          control={control}
                      />
                  </Col>
                  <Col md="3" className="parameter-description">{t('pressure.advanced.auto_reset_unit')}</Col>
              </Row>
              <Row>
                <Col md="7" className="parameter-text">{t('pressure.advanced.measurement_delay')}</Col>
                <Col md="2">
                <Controller 
                          render={({ onChange }) => (
                            <Form.Control 
                                as="input"
                                className="input-digit-big"
                                id="md" 
                                onChange={(e) => updateParameter(editConfig, "p", "md", e.target.value)}

                                value={getValues("md")}
                                />
                          )} 
                          name="md"
                          control={control}
                      />
                  </Col>
                  <Col md="3" className="parameter-description">{t('pressure.advanced.mdelay_unit')}</Col>
              </Row>
              <Row>
                <Col md="7" className="parameter-text">{t('pressure.advanced.p5_start')}</Col>
                <Col md="2">
                <Controller 
                          render={({ onChange }) => (
                            <Form.Control 
                                as="input"
                                className="input-digit-big"
                                id="p5-s" 
                                onChange={(e) => updateParameter(editConfig, "p", "p5-s", e.target.value)}

                                value={getValues("p5-s")}
                                />
                          )} 
                          name="p5-s"
                          control={control}
                      />
                  </Col>
                  <Col md="3" className="parameter-description">{t('units.bar')}</Col>
              </Row>
              <Row>
                <Col md="7" className="parameter-text">{t('pressure.advanced.p5_stop')}</Col>
                <Col md="2">
                <Controller 
                          render={({ onChange }) => (
                            <Form.Control 
                                as="input"
                                className="input-digit-big"
                                id="p5-f" 
                                onChange={(e) => updateParameter(editConfig, "p", "p5-f", e.target.value)}
                                value={getValues("p5-f")}
                                />
                          )} 
                          name="p5-f"
                          control={control}
                      />
                  </Col>
                  <Col md="3" className="parameter-description">{t('units.bar')}</Col>
              </Row>
              
            </Container>
  
  
      </form>
    )
  })